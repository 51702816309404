import React from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  value: PropTypes.bool.isRequired,
};

function SettingsSwitch({ handleChange, userInfo, value }) {
  const { getTokenSilently, logout } = useAuth0();
  const { id } = userInfo;

  return (
    <Grid alignItems="center" component="label" container spacing={1}>
      <Grid item>Off</Grid>
      <Grid item>
        <Switch
          checked={value}
          color="error"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          onChange={() =>
            from(getTokenSilently()).subscribe({
              next: (token) =>
                handleChange({
                  checked: !value,
                  id,
                  token,
                }),
              error: () => logout({ returnTo: window.location.origin }),
            })
          }
        />
      </Grid>
      <Grid item>On</Grid>
    </Grid>
  );
}

SettingsSwitch.propTypes = propTypes;

export default SettingsSwitch;

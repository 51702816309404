import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MessagingService from 'components/MessagingService';
import NumberLookupInput from 'components/NumberLookupInput';
import BurnerInfoTable from 'components/pages/number-lookup/BurnerInfoTable';
import ProgressStepper from 'components/pages/number-lookup/ProgressStepper';
import TwilioDataTable from 'components/pages/number-lookup/TwilioDataTable';
import View from 'components/View';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';

const propTypes = {
  activeStep: PropTypes.number.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  previouslyAssignedToBurner: PropTypes.string.isRequired,
  searchNumbersRequest: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  twilioData: PropTypes.shape({
    carrierName: PropTypes.string,
    carrierType: PropTypes.string,
  }),
};

function NumberLookup({
  activeStep,
  handleCloseSnackbar,
  isFetching,
  message,
  searchNumbersRequest,
  previouslyAssignedToBurner,
  steps,
  twilioData,
}) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('numberLookup')) ? (
    <View>
      <NumberLookupInput
        handleSubmit={searchNumbersRequest}
        isFetching={isFetching}
      />
      {activeStep !== 0 && (
        <ProgressStepper activeStep={activeStep} steps={steps} />
      )}
      {activeStep > 1 && (
        <BurnerInfoTable
          previouslyAssignedToBurner={previouslyAssignedToBurner}
        />
      )}
      {!isEmpty(twilioData) && <TwilioDataTable twilioData={twilioData} />}
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

NumberLookup.propTypes = propTypes;

export default NumberLookup;

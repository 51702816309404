import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { countries, phoneOrIdSchema } from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.string,
};

function ViewBurnerInput({ handleSubmit, isFetching, params = null }) {
  const initialValues = { country: 'US', phoneNumberOrId: '' };
  const { getTokenSilently, logout } = useAuth0();

  useEffect(() => {
    if (!params) return;

    const token$ = from(getTokenSilently()).subscribe({
      next: (token) =>
        handleSubmit({
          phoneNumberOrId: params,
          token,
        }),
      error: () => logout({ returnTo: window.location.origin }),
    });

    return () => token$.unsubscribe();
  }, [getTokenSilently, handleSubmit, logout, params]);

  return (
    <Grid item xs={12}>
      <Typography
        component="h1"
        data-testid="title-test"
        gutterBottom
        variant="h4"
      >
        View Burner Information
      </Typography>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                ...values,
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => resetForm(initialValues),
          });
        }}
        validationSchema={phoneOrIdSchema}
      >
        {() => (
          <Form className="number-input">
            <Field name="phoneNumberOrId">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  error={errors.phoneNumberOrId && touched.phoneNumberOrId}
                  helperText={
                    errors.phoneNumberOrId &&
                    touched.phoneNumberOrId &&
                    errors.phoneNumberOrId
                  }
                  id="phoneNumberOrId"
                  InputProps={
                    errors.phoneNumberOrId &&
                    touched.phoneNumberOrId && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ErrorOutline className="error" />
                        </InputAdornment>
                      ),
                    }
                  }
                  label="Phone Number or ID*"
                  margin="normal"
                  type="text"
                  variant="standard"
                />
              )}
            </Field>
            <Field name="country">
              {({ field }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  label="Country"
                  margin="normal"
                  select
                  variant="standard"
                >
                  {countries &&
                    countries.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Field>
            <Button
              className="button"
              color="primary"
              disabled={isFetching}
              size="medium"
              type="submit"
              variant="outlined"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

ViewBurnerInput.propTypes = propTypes;

export default ViewBurnerInput;

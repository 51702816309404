import { connect } from 'react-redux';
import {
  clearRateLimitsRequest,
  handleCloseSnackbar,
} from 'ducks/clear-rate-limits';
import ClearRateLimits from 'components/pages/ClearRateLimits';

const mapStateToProps = (state) => {
  const { isFetching, message } = state.clearRateLimits;

  return {
    isFetching,
    message,
  };
};

const mapDispatchToProps = {
  clearRateLimitsRequest,
  handleCloseSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClearRateLimits);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import View from 'components/View';

const propTypes = {
  children: PropTypes.node.isRequired,
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <View>
          <Grid item xs={12}>
            <Typography component="h1" gutterBottom variant="h4">
              Something Went Wrong
            </Typography>
          </Grid>
        </View>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = propTypes;

export default ErrorBoundary;

import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { enrichAccountsToDelete } from 'utils/accounts-to-delete';
import { makeRequest } from 'utils/general';

/*
Actions
*/
export const ACCOUNTS_TO_DELETE_FAILURE =
  'dashboard/accountsToDelete/ACCOUNTS_TO_DELETE_FAILURE';
export const ACCOUNTS_TO_DELETE_REQUEST =
  'dashboard/accountsToDelete/ACCOUNTS_TO_DELETE_REQUEST';
export const ACCOUNTS_TO_DELETE_SUCCESS =
  'dashboard/accountsToDelete/ACCOUNTS_TO_DELETE_SUCCESS';
export const DELETE_ACCOUNT_FAILURE =
  'dashboard/accountsToDelete/DELETE_ACCOUNT_FAILURE';
export const DELETE_ACCOUNT_REQUEST =
  'dashboard/accountsToDelete/DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS =
  'dashboard/accountsToDelete/DELETE_ACCOUNT_SUCCESS';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/accountsToDelete/HANDLE_CLOSE_SNACKBAR';
export const SET_ACCOUNT_TO_DELETE =
  'dashboard/accountsToDelete/SET_ACCOUNT_TO_DELETE';
export const SET_DELETE_ACCOUNT_DIALOG_IS_OPEN =
  'dashboard/accountsToDelete/SET_DELETE_ACCOUNT_DIALOG_IS_OPEN';

/*
Action Creators
*/
export function accountsToDeleteFailure(payload) {
  return { type: ACCOUNTS_TO_DELETE_FAILURE, payload };
}

export function accountsToDeleteRequest(payload) {
  return { type: ACCOUNTS_TO_DELETE_REQUEST, payload };
}

export function accountsToDeleteSuccess(payload) {
  return { type: ACCOUNTS_TO_DELETE_SUCCESS, payload };
}

export function deleteAccountFailure(payload) {
  return { type: DELETE_ACCOUNT_FAILURE, payload };
}

export function deleteAccountRequest(payload) {
  return { type: DELETE_ACCOUNT_REQUEST, payload };
}

export function deleteAccountSuccess(payload) {
  return { type: DELETE_ACCOUNT_SUCCESS, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

export function setAccountToDelete(payload) {
  return { type: SET_ACCOUNT_TO_DELETE, payload };
}

export function setDeleteAccountDialogIsOpen(payload) {
  return { type: SET_DELETE_ACCOUNT_DIALOG_IS_OPEN, payload };
}

/*
Reducer
*/
const initialState = {
  accountsToDelete: [],
  accountToDelete: '',
  deleteAccountDialogIsOpen: false,
  isFetching: false,
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case ACCOUNTS_TO_DELETE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case ACCOUNTS_TO_DELETE_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case ACCOUNTS_TO_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        accountsToDelete: payload.map((account) => ({
          dateForDeletion: account.dateForDeletion,
          phoneNumber: account.phoneNumber,
          userId: account.userId,
        })),
      };
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload },
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: initialState.message,
      };
    case SET_DELETE_ACCOUNT_DIALOG_IS_OPEN:
      return {
        ...state,
        deleteAccountDialogIsOpen: payload,
      };
    case SET_ACCOUNT_TO_DELETE:
      return {
        ...state,
        accountToDelete: payload,
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const fetchAccountsToDeleteEpic = (action$) => {
  const fetchAccountsToDelete = (action) => {
    const { token } = action.payload;
    return makeRequest(
      `${process.env.REACT_APP_BASE_URL}/admin/user-deletions`,
      token
    );
  };

  return action$.pipe(
    ofType(ACCOUNTS_TO_DELETE_REQUEST),
    mergeMap((action) =>
      fetchAccountsToDelete(action).pipe(
        map((response) => accountsToDeleteSuccess(response.response)),
        catchError((error) =>
          of(
            accountsToDeleteFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Could not fetch user deletions.'
            )
          )
        )
      )
    )
  );
};

export const deleteAccountEpic = (action$) => {
  const deleteAccount = (action) => {
    const { userId, token } = action.payload;

    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${userId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(DELETE_ACCOUNT_REQUEST),
    mergeMap((action) =>
      deleteAccount(action).pipe(
        tap(() => deleteAccountSuccess('User deleted successfully')),
        map(() => accountsToDeleteRequest({ ...action.payload })),
        catchError((error) =>
          of(
            deleteAccountFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error deleting user.'
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getAccountsToDelete = createSelector(
  (state) => state.accountsToDelete.accountsToDelete,
  (accounts) => enrichAccountsToDelete(accounts)
);

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import AddCredits from 'components/pages/view-user-info/account-controls/AddCredits';
import AppControls from 'components/pages/view-user-info/account-controls/AppControls';
import DeleteUser from 'components/pages/view-user-info/account-controls/DeleteUser';
import SetCountryCode from 'components/pages/view-user-info/account-controls/SetCountryCode';
import VerifyCode from 'components/pages/view-user-info/account-controls/VerifyCode';

const propTypes = {
  adjustCreditsRequest: PropTypes.func.isRequired,
  changeCountryRequest: PropTypes.func.isRequired,
  deleteAuthRequest: PropTypes.func.isRequired,
  deleteUserDialogIsOpen: PropTypes.bool.isRequired,
  deleteUserDialogOpen: PropTypes.func.isRequired,
  deleteUserRequest: PropTypes.func.isRequired,
  deletionConfirmationRequest: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  pushDialogIsOpen: PropTypes.bool.isRequired,
  pushNotificationDialogOpen: PropTypes.func.isRequired,
  sendPushRequest: PropTypes.func.isRequired,
  sendVerifyRequest: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    dndEnabled: PropTypes.bool,
  }).isRequired,
  toggleDoNotDisturbRequest: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    countryCode: PropTypes.string,
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
  verifyCode: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

function AccountControlsSection({
  adjustCreditsRequest,
  changeCountryRequest,
  deleteAuthRequest,
  deleteUserDialogIsOpen,
  deleteUserDialogOpen,
  deleteUserRequest,
  deletionConfirmationRequest,
  isFetching,
  pushDialogIsOpen,
  pushNotificationDialogOpen,
  sendPushRequest,
  sendVerifyRequest,
  settings,
  toggleDoNotDisturbRequest,
  userInfo,
  verifyCode,
}) {
  return (
    <Grid container>
      <VerifyCode
        isFetching={isFetching}
        sendVerifyRequest={sendVerifyRequest}
        userInfo={userInfo}
        verifyCode={verifyCode}
      />
      <AddCredits
        handleSubmit={adjustCreditsRequest}
        isFetching={isFetching}
        userInfo={userInfo}
      />
      <SetCountryCode
        handleSubmit={changeCountryRequest}
        isFetching={isFetching}
        userInfo={userInfo}
      />
      <DeleteUser
        deleteUserDialogIsOpen={deleteUserDialogIsOpen}
        deleteUserRequest={deleteUserRequest}
        deletionConfirmationRequest={deletionConfirmationRequest}
        handleOpen={deleteUserDialogOpen}
        isFetching={isFetching}
        userInfo={userInfo}
      />
      <AppControls
        handleClick={deleteAuthRequest}
        handleOpen={pushNotificationDialogOpen}
        isFetching={isFetching}
        pushDialogIsOpen={pushDialogIsOpen}
        sendPushRequest={sendPushRequest}
        settings={settings}
        toggleDoNotDisturbRequest={toggleDoNotDisturbRequest}
        userInfo={userInfo}
      />
    </Grid>
  );
}

AccountControlsSection.propTypes = propTypes;

export default AccountControlsSection;

import { connect } from 'react-redux';
import {
  fetchDataRequest,
  getIsBlacklisted,
  getUserInfo,
  handleCloseSnackbar,
  replaceNumberRequest,
} from 'ducks/replace-user-number';
import ReplaceNumber from 'components/pages/ReplaceNumber';

const mapStateToProps = (state) => {
  const { isFetching, message } = state.replaceUserNumber;

  return {
    isBlacklisted: getIsBlacklisted(state),
    isFetching,
    message,
    userInfo: getUserInfo(state),
  };
};

const mapDispatchToProps = {
  fetchDataRequest,
  handleCloseSnackbar,
  replaceNumberRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceNumber);

import { connect } from 'react-redux';
import { searchUserRequest, handleCloseSnackbar } from 'ducks/view-user-info';
import ViewUser from 'components/pages/ViewUser';

const mapStateToProps = (state) => {
  const { isFetching, message, userInfo } = state.viewUser;

  return {
    isFetching,
    message,
    userInfo,
  };
};

const mapDispatchToProps = {
  searchUserRequest,
  handleCloseSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { logContextIdentifierSchema } from 'utils/log-context';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  contextIdParam: PropTypes.string,
};

function LogContextSearch({
  submitButtonText = 'Submit',
  handleSubmit,
  isFetching,
  contextIdParam = null,
}) {
  const { getTokenSilently, logout } = useAuth0();
  const initialValues = {
    contextId: '',
  };

  useEffect(() => {
    if (!contextIdParam) return;

    const token$ = from(getTokenSilently()).subscribe({
      next: (token) =>
        handleSubmit({
          contextId: contextIdParam,
          token,
        }),
      error: () => logout({ returnTo: window.location.origin }),
    });

    return () => token$.unsubscribe();
  }, [getTokenSilently, handleSubmit, logout, contextIdParam]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        from(getTokenSilently()).subscribe({
          next: (token) =>
            handleSubmit({
              ...values,
              token,
            }),
          error: () => logout({ returnTo: window.location.origin }),
          complete: () => resetForm(initialValues),
        });
      }}
      validationSchema={logContextIdentifierSchema}
    >
      {({ values }) => (
        <Form className="number-input">
          <Field name="contextId">
            {({ field, form: { errors, touched } }) => (
              <TextField
                {...field}
                className="input"
                disabled={isFetching}
                error={errors.contextId && touched.contextId}
                helperText={
                  errors.contextId && touched.contextId && errors.contextId
                }
                id="contextId"
                InputProps={
                  errors.contextId &&
                  touched.contextId && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <ErrorOutline className="error" />
                      </InputAdornment>
                    ),
                  }
                }
                label="Context ID"
                margin="normal"
                type="text"
                variant="standard"
              />
            )}
          </Field>
          <Button
            className="button"
            color="primary"
            disabled={isFetching || values.contextId.length < 3}
            size="medium"
            type="submit"
            variant="outlined"
          >
            {submitButtonText}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

LogContextSearch.propTypes = propTypes;

export default LogContextSearch;

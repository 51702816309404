import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MUIDataTable from 'mui-datatables';
import { columns, options } from 'utils/number-cache-analytics';

const propTypes = {
  numberCacheData: PropTypes.arrayOf(
    PropTypes.shape({
      area_code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      available: PropTypes.number,
      available_clean: PropTypes.number,
      burner: PropTypes.number,
      consistently_dirty: PropTypes.number,
      created_date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      detached: PropTypes.number,
      detached_clean: PropTypes.number,
      dirty: PropTypes.number,
      holding: PropTypes.number,
      new: PropTypes.number,
      pending: PropTypes.number,
      total: PropTypes.number,
    })
  ).isRequired,
};

function NumberCacheAnalyticsTable({ numberCacheData }) {
  return (
    !isEmpty(numberCacheData) && (
      <MUIDataTable
        columns={columns}
        data={numberCacheData}
        options={options}
        title={'Area Codes'}
      />
    )
  );
}

NumberCacheAnalyticsTable.propTypes = propTypes;

export default NumberCacheAnalyticsTable;

import isEmpty from 'lodash/isEmpty';
import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { forkJoin, iif, of, throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { formatE164IfValidPhoneNumber, isEmail } from 'utils/general';
import {
  enrichBlacklistInfo,
  enrichBurners,
  enrichNotes,
  enrichSubscriptionEvents,
  enrichSubChangeOrderId,
  enrichSubscriptions,
  enrichTeamChatHistory,
  enrichUserDeletion,
  enrichUserInfo,
  transformBurnerIdForMessaging,
  transformPreviouslyOwnedPhoneNumbers,
  transformUserTransactions,
  transformVerifyCode,
} from 'utils/view-user-info';

/*
Actions
*/
export const ADD_BURNER_FAILURE = 'dashboard/viewUser/ADD_BURNER_FAILURE';
export const ADD_BURNER_REQUEST = 'dashboard/viewUser/ADD_BURNER_REQUEST';
export const ADJUST_CREDITS_FAILURE =
  'dashboard/viewUser/ADJUST_CREDITS_FAILURE';
export const ADJUST_CREDITS_REQUEST =
  'dashboard/viewUser/ADJUST_CREDITS_REQUEST';
export const ADJUST_CREDITS_SUCCESS =
  'dashboard/viewUser/ADJUST_CREDITS_SUCCESS';
export const CHANGE_COUNTRY_FAILURE =
  'dashboard/viewUser/CHANGE_COUNTRY_FAILURE';
export const CHANGE_COUNTRY_REQUEST =
  'dashboard/viewUser/CHANGE_COUNTRY_REQUEST';
export const CHANGE_COUNTRY_SUCCESS =
  'dashboard/viewUser/CHANGE_COUNTRY_SUCCESS';
export const CREATE_NOTE_FAILURE = 'dashboard/viewUser/CREATE_NOTE_FAILURE';
export const CREATE_NOTE_REQUEST = 'dashboard/viewUser/CREATE_NOTE_REQUEST';
export const DELETE_AUTH_FAILURE = 'dashboard/viewUser/DELETE_AUTH_FAILURE';
export const DELETE_AUTH_REQUEST = 'dashboard/viewUser/DELETE_AUTH_REQUEST';
export const DELETE_AUTH_SUCCESS = 'dashboard/viewUser/DELETE_AUTH_SUCCESS';
export const DELETE_SUBSCRIPTION_DIALOG_OPEN =
  'dashboard/viewUser/DELETE_SUBSCRIPTION_DIALOG_OPEN';
export const DELETE_SUBSCRIPTION_FAILURE =
  'dashboard/viewUser/DELETE_SUBSCRIPTION_FAILURE';
export const DELETE_SUBSCRIPTION_REQUEST =
  'dashboard/viewUser/DELETE_SUBSCRIPTION_REQUEST';
export const DELETE_USER_DIALOG_OPEN =
  'dashboard/viewUser/DELETE_USER_DIALOG_OPEN';
export const DELETE_USER_FAILURE = 'dashboard/viewUser/DELETE_USER_FAILURE';
export const DELETE_USER_REQUEST = 'dashboard/viewUser/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'dashboard/viewUser/DELETE_USER_SUCCESS';
export const DELETION_CONFIRMATION_FAILURE =
  'dashboard/viewUser/DELETION_CONFIRMATION_FAILURE';
export const DELETION_CONFIRMATION_REQUEST =
  'dashboard/viewUser/DELETION_CONFIRMATION_REQUEST';
export const DELETION_CONFIRMATION_SUCCESS =
  'dashboard/viewUser/DELETION_CONFIRMATION_SUCCESS';
export const EDIT_SUBSCRIPTION_DIALOG_OPEN =
  'dashboard/viewUser/EDIT_SUBSCRIPTION_DIALOG_OPEN';
export const FETCH_TEAM_CHAT_FAILURE =
  'dashboard/viewUser/FETCH_TEAM_CHAT_FAILURE';
export const FETCH_TEAM_CHAT_REQUEST =
  'dashboard/viewUser/FETCH_TEAM_CHAT_REQUEST';
export const FETCH_TEAM_CHAT_SUCCESS =
  'dashboard/viewUser/FETCH_TEAM_CHAT_SUCCESS';
export const FETCH_USER_FAILURE = 'dashboard/viewUser/FETCH_USER_FAILURE';
export const FETCH_USER_REQUEST = 'dashboard/viewUser/FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'dashboard/viewUser/FETCH_USER_SUCCESS';
export const FETCH_VERIFY_CODE_FAILURE =
  'dashboard/viewUser/FETCH_VERIFY_CODE_FAILURE';
export const FETCH_VERIFY_CODE_REQUEST =
  'dashboard/viewUser/FETCH_VERIFY_CODE_REQUEST';
export const FETCH_VERIFY_CODE_SUCCESS =
  'dashboard/viewUser/FETCH_VERIFY_CODE_SUCCESS';
export const FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_SUCCESS =
  'dashboard/viewUser/FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_SUCCESS';
export const FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_FAILURE =
  'dashboard/viewUser/FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_FAILURE';
export const FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_REQUEST =
  'dashboard/viewUser/FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_REQUEST';
export const HANDLE_CLOSE_SNACKBAR = 'dashboard/viewUser/HANDLE_CLOSE_SNACKBAR';
export const PUSH_NOTIFICATION_DIALOG_OPEN =
  'dashboard/viewUser/PUSH_NOTIFICATION_DIALOG_OPEN';
export const REMOVE_BURNER_FAILURE = 'dashboard/viewUser/REMOVE_BURNER_FAILURE';
export const REMOVE_BURNER_REQUEST = 'dashboard/viewUser/REMOVE_BURNER_REQUEST';
export const REMOVE_USER_DELETION_FAILURE =
  'dashboard/viewUser/REMOVE_USER_DELETION_FAILURE';
export const REMOVE_USER_DELETION_DIALOG_OPEN =
  'dashboard/viewUser/REMOVE_USER_DELETION_DIALOG_OPEN';
export const REMOVE_USER_DELETION_REQUEST =
  'dashboard/viewUser/REMOVE_USER_DELETION_REQUEST';
export const REMOVE_USER_DELETION_SUCCESS =
  'dashboard/viewUser/REMOVE_USER_DELETION_SUCCESS';
export const SEARCH_USER_FAILURE = 'dashboard/viewUser/SEARCH_USER_FAILURE';
export const SEARCH_USER_REQUEST = 'dashboard/viewUser/SEARCH_USER_REQUEST';
export const SEND_PUSH_FAILURE = 'dashboard/viewUser/SEND_PUSH_FAILURE';
export const SEND_PUSH_REQUEST = 'dashboard/viewUser/SEND_PUSH_REQUEST';
export const SEND_PUSH_SUCCESS = 'dashboard/viewUser/SEND_PUSH_SUCCESS';
export const SEND_TEAM_CHAT_FAILURE =
  'dashboard/viewUser/SEND_TEAM_CHAT_FAILURE';
export const SEND_TEAM_CHAT_REQUEST =
  'dashboard/viewUser/SEND_TEAM_CHAT_REQUEST';
export const SEND_VERIFY_FAILURE = 'dashboard/viewUser/SEND_VERIFY_FAILURE';
export const SEND_VERIFY_REQUEST = 'dashboard/viewUser/SEND_VERIFY_REQUEST';
export const SET_BURNERS_ASSIGNED_FAILURE =
  'dashboard/viewUser/SET_BURNERS_ASSIGNED_FAILURE';
export const SET_BURNERS_ASSIGNED_REQUEST =
  'dashboard/viewUser/SET_BURNERS_ASSIGNED_REQUEST';
export const SET_BURNERS_ASSIGNED_SUCCESS =
  'dashboard/viewUser/SET_BURNERS_ASSIGNED_SUCCESS';
export const SET_SUBSCRIPTION = 'dashboard/viewUser/SET_SUBSCRIPTION';
export const SET_SUBSCRIPTION_TO_DELETE =
  'dashboard/viewUser/SET_SUBSCRIPTION_TO_DELETE';
export const SUBSCRIPTION_EVENTS_DIALOG_OPEN =
  'dashboard/viewUser/SUBSCRIPTION_EVENTS_DIALOG_OPEN';
export const TOGGLE_DO_NOT_DISTURB_FAILURE =
  'dashboard/viewUser/TOGGLE_DO_NOT_DISTURB_FAILURE';
export const TOGGLE_DO_NOT_DISTURB_REQUEST =
  'dashboard/viewUser/TOGGLE_DO_NOT_DISTURB_REQUEST';
export const TOGGLE_DO_NOT_DISTURB_SUCCESS =
  'dashboard/viewUser/TOGGLE_DO_NOT_DISTURB_SUCCESS';
export const TOGGLE_SAMPLE_FAILURE = 'dashboard/viewUser/TOGGLE_SAMPLE_FAILURE';
export const TOGGLE_SAMPLE_REQUEST = 'dashboard/viewUser/TOGGLE_SAMPLE_REQUEST';
export const TOGGLE_SAMPLE_SUCCESS = 'dashboard/viewUser/TOGGLE_SAMPLE_SUCCESS';
export const UPDATE_TEAM_CHAT_FAILURE =
  'dashboard/viewUser/UPDATE_TEAM_CHAT_FAILURE';
export const UPDATE_TEAM_CHAT_REQUEST =
  'dashboard/viewUser/UPDATE_TEAM_CHAT_REQUEST';
export const UPDATE_TEAM_CHAT_SUCCESS =
  'dashboard/viewUser/UPDATE_TEAM_CHAT_SUCCESS';
export const UPDATE_NOTES_FAILURE = 'dashboard/viewUser/UPDATE_NOTES_FAILURE';
export const UPDATE_NOTES_REQUEST = 'dashboard/viewUser/UPDATE_NOTES_REQUEST';
export const UPDATE_NOTES_SUCCESS = 'dashboard/viewUser/UPDATE_NOTES_SUCCESS';
export const UPDATE_SUBSCRIPTIONS_FAILURE =
  'dashboard/viewUser/UPDATE_SUBSCRIPTIONS_FAILURE';
export const UPDATE_SUBSCRIPTIONS_REQUEST =
  'dashboard/viewUser/UPDATE_SUBSCRIPTIONS_REQUEST';
export const UPDATE_SUBSCRIPTIONS_SUCCESS =
  'dashboard/viewUser/UPDATE_SUBSCRIPTIONS_SUCCESS';
export const VALIDATE_SUBSCRIPTION_FAILURE =
  'dashboard/viewUser/VALIDATE_SUBSCRIPTION_FAILURE';
export const VALIDATE_SUBSCRIPTION_REQUEST =
  'dashboard/viewUser/VALIDATE_SUBSCRIPTION_REQUEST';
export const VALIDATE_SUBSCRIPTION_SUCCESS =
  'dashboard/viewUser/VALIDATE_SUBSCRIPTION_SUCCESS';

/*
Action Creators
*/
export function addBurnerFailure(payload) {
  return { type: ADD_BURNER_FAILURE, payload };
}

export function addBurnerRequest(payload) {
  return { type: ADD_BURNER_REQUEST, payload };
}

export function adjustCreditsFailure(payload) {
  return { type: ADJUST_CREDITS_FAILURE, payload };
}

export function adjustCreditsRequest(payload) {
  return { type: ADJUST_CREDITS_REQUEST, payload };
}

export function adjustCreditsSuccess(payload) {
  return { type: ADJUST_CREDITS_SUCCESS, payload };
}

export function changeCountryFailure(payload) {
  return { type: CHANGE_COUNTRY_FAILURE, payload };
}

export function changeCountryRequest(payload) {
  return { type: CHANGE_COUNTRY_REQUEST, payload };
}

export function changeCountrySuccess(payload) {
  return { type: CHANGE_COUNTRY_SUCCESS, payload };
}

export function createNoteFailure(payload) {
  return { type: CREATE_NOTE_FAILURE, payload };
}

export function createNoteRequest(payload) {
  return { type: CREATE_NOTE_REQUEST, payload };
}

export function deleteAuthFailure(payload) {
  return { type: DELETE_AUTH_FAILURE, payload };
}

export function deleteAuthRequest(payload) {
  return { type: DELETE_AUTH_REQUEST, payload };
}

export function deleteAuthSuccess(payload) {
  return { type: DELETE_AUTH_SUCCESS, payload };
}

export function deleteUserDialogOpen(payload) {
  return { type: DELETE_USER_DIALOG_OPEN, payload };
}

export function deleteUserFailure(payload) {
  return { type: DELETE_USER_FAILURE, payload };
}

export function deleteUserRequest(payload) {
  return { type: DELETE_USER_REQUEST, payload };
}

export function deleteUserSuccess(payload) {
  return { type: DELETE_USER_SUCCESS, payload };
}

export function deleteSubscriptionDialogOpen(payload) {
  return { type: DELETE_SUBSCRIPTION_DIALOG_OPEN, payload };
}

export function deleteSubscriptionFailure(payload) {
  return { type: DELETE_SUBSCRIPTION_FAILURE, payload };
}

export function deleteSubscriptionRequest(payload) {
  return { type: DELETE_SUBSCRIPTION_REQUEST, payload };
}

export function deletionConfirmationFailure(payload) {
  return { type: DELETION_CONFIRMATION_FAILURE, payload };
}

export function deletionConfirmationRequest(payload) {
  return { type: DELETION_CONFIRMATION_REQUEST, payload };
}

export function deletionConfirmationSuccess(payload) {
  return { type: DELETION_CONFIRMATION_SUCCESS, payload };
}

export function editSubscriptionDialogOpen(payload) {
  return { type: EDIT_SUBSCRIPTION_DIALOG_OPEN, payload };
}

export function fetchTeamChatFailure(payload) {
  return { type: FETCH_TEAM_CHAT_FAILURE, payload };
}

export function fetchTeamChatRequest(payload) {
  return { type: FETCH_TEAM_CHAT_REQUEST, payload };
}

export function fetchTeamChatSuccess(payload) {
  return { type: FETCH_TEAM_CHAT_SUCCESS, payload };
}

export function removeUserDeletionFailure(payload) {
  return { type: REMOVE_USER_DELETION_FAILURE, payload };
}

export function removeUserDeletionRequest(payload) {
  return { type: REMOVE_USER_DELETION_REQUEST, payload };
}

export function removeUserDeletionSuccess(payload) {
  return { type: REMOVE_USER_DELETION_SUCCESS, payload };
}

export function removeUserDeletionDialogOpen(payload) {
  return { type: REMOVE_USER_DELETION_DIALOG_OPEN, payload };
}

export function fetchUserFailure(payload) {
  return { type: FETCH_USER_FAILURE, payload };
}

export function fetchUserRequest(payload) {
  return { type: FETCH_USER_REQUEST, payload };
}

export function fetchUserSuccess(payload) {
  return { type: FETCH_USER_SUCCESS, payload };
}

export function fetchVerifyCodeFailure(payload) {
  return { type: FETCH_VERIFY_CODE_FAILURE, payload };
}

export function fetchVerifyCodeRequest(payload) {
  return { type: FETCH_VERIFY_CODE_REQUEST, payload };
}

export function fetchVerifyCodeSuccess(payload) {
  return { type: FETCH_VERIFY_CODE_SUCCESS, payload };
}

export function fetchLatestOrderIdSuccess(payload) {
  return { type: FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_SUCCESS, payload };
}

export function fetchLatestOrderIdFailure(payload) {
  return { type: FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_FAILURE, payload };
}

export function fetchLatestOrderIdRequest(payload) {
  return { type: FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_REQUEST, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

export function pushNotificationDialogOpen(payload) {
  return { type: PUSH_NOTIFICATION_DIALOG_OPEN, payload };
}

export function removeBurnerFailure(payload) {
  return { type: REMOVE_BURNER_FAILURE, payload };
}

export function removeBurnerRequest(payload) {
  return { type: REMOVE_BURNER_REQUEST, payload };
}

export function searchUserFailure(payload) {
  return { type: SEARCH_USER_FAILURE, payload };
}

export function searchUserRequest(payload) {
  return { type: SEARCH_USER_REQUEST, payload };
}

export function sendPushFailure(payload) {
  return { type: SEND_PUSH_FAILURE, payload };
}

export function sendPushRequest(payload) {
  return { type: SEND_PUSH_REQUEST, payload };
}

export function sendPushSuccess(payload) {
  return { type: SEND_PUSH_SUCCESS, payload };
}

export function sendTeamChatFailure(payload) {
  return { type: SEND_TEAM_CHAT_FAILURE, payload };
}

export function sendTeamChatRequest(payload) {
  return { type: SEND_TEAM_CHAT_REQUEST, payload };
}

export function sendVerifyFailure(payload) {
  return { type: SEND_VERIFY_FAILURE, payload };
}

export function sendVerifyRequest(payload) {
  return { type: SEND_VERIFY_REQUEST, payload };
}

export function setBurnersAssignedFailure(payload) {
  return { type: SET_BURNERS_ASSIGNED_FAILURE, payload };
}

export function setBurnersAssignedRequest(payload) {
  return { type: SET_BURNERS_ASSIGNED_REQUEST, payload };
}

export function setBurnersAssignedSuccess(payload) {
  return { type: SET_BURNERS_ASSIGNED_SUCCESS, payload };
}

export function setSubscription(payload) {
  return { type: SET_SUBSCRIPTION, payload };
}

export function setSubscriptionToDelete(payload) {
  return { type: SET_SUBSCRIPTION_TO_DELETE, payload };
}

export function subscriptionEventsDialogOpen(payload) {
  return { type: SUBSCRIPTION_EVENTS_DIALOG_OPEN, payload };
}

export function toggleDoNotDisturbFailure(payload) {
  return { type: TOGGLE_DO_NOT_DISTURB_FAILURE, payload };
}

export function toggleDoNotDisturbRequest(payload) {
  return { type: TOGGLE_DO_NOT_DISTURB_REQUEST, payload };
}

export function toggleDoNotDisturbSuccess(payload) {
  return { type: TOGGLE_DO_NOT_DISTURB_SUCCESS, payload };
}

export function toggleSampleFailure(payload) {
  return { type: TOGGLE_SAMPLE_FAILURE, payload };
}

export function toggleSampleRequest(payload) {
  return { type: TOGGLE_SAMPLE_REQUEST, payload };
}

export function toggleSampleSuccess(payload) {
  return { type: TOGGLE_SAMPLE_SUCCESS, payload };
}

export function updateNotesFailure(payload) {
  return { type: UPDATE_NOTES_FAILURE, payload };
}

export function updateNotesRequest(payload) {
  return { type: UPDATE_NOTES_REQUEST, payload };
}

export function updateNotesSuccess(payload) {
  return { type: UPDATE_NOTES_SUCCESS, payload };
}

export function updateSubscriptionsFailure(payload) {
  return { type: UPDATE_SUBSCRIPTIONS_FAILURE, payload };
}

export function updateSubscriptionsRequest(payload) {
  return { type: UPDATE_SUBSCRIPTIONS_REQUEST, payload };
}

export function updateSubscriptionsSuccess(payload) {
  return { type: UPDATE_SUBSCRIPTIONS_SUCCESS, payload };
}

export function updateTeamChatFailure(payload) {
  return { type: UPDATE_TEAM_CHAT_FAILURE, payload };
}

export function updateTeamChatRequest(payload) {
  return { type: UPDATE_TEAM_CHAT_REQUEST, payload };
}

export function updateTeamChatSuccess(payload) {
  return { type: UPDATE_TEAM_CHAT_SUCCESS, payload };
}

export function validateSubscriptionFailure(payload) {
  return { type: VALIDATE_SUBSCRIPTION_FAILURE, payload };
}

export function validateSubscriptionRequest(payload) {
  return { type: VALIDATE_SUBSCRIPTION_REQUEST, payload };
}

export function validateSubscriptionSuccess(payload) {
  return { type: VALIDATE_SUBSCRIPTION_SUCCESS, payload };
}

/*
Reducer
*/
const initialState = {
  blockedContacts: [],
  burners: [],
  deleteUserDialogIsOpen: false,
  deleteSubscriptionDialogIsOpen: false,
  editSubscriptionDialogIsOpen: false,
  events: [],
  blacklistInfo: {},
  isFetching: false,
  lookupHistory: [],
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
  notes: [],
  pushDialogIsOpen: false,
  removeUserDeletionDialogIsOpen: false,
  settings: {},
  subscriptionEvents: {},
  latestOrderIds: {},
  subscriptionEventsDialogIsOpen: false,
  subscriptionToEdit: {},
  subscriptionToDelete: {},
  subscriptions: [],
  teamChatHistory: [],
  teamChatHistoryHasBeenFetched: false,
  transactions: [],
  userDeletion: {},
  userInfo: {},
  verifyCode: {},
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case ADD_BURNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case ADD_BURNER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADJUST_CREDITS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case ADJUST_CREDITS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ADJUST_CREDITS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        userInfo: {
          appVersion: payload.userInfo.appVersion,
          carrierName: payload.userInfo.carrierName,
          countryCode: payload.userInfo.countryCode,
          createdSample: payload.userInfo.createdSample,
          credits: payload.userInfo.credits,
          dateCreated: payload.userInfo.dateCreated,
          email: payload.userInfo.email,
          firebaseId: payload.userInfo.firebaseId,
          id: payload.userInfo.id,
          lastUpdatedDate: payload.userInfo.lastUpdatedDate,
          identityProviders: payload.userInfo.identityProviders,
          phoneNumber: payload.userInfo.phoneNumber,
          platformName: payload.userInfo.platform.name,
          rtdbShard: payload.userInfo.rtdbShard,
        },
      };
    case CHANGE_COUNTRY_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case CHANGE_COUNTRY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CHANGE_COUNTRY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        userInfo: {
          appVersion: payload.userInfo.appVersion,
          carrierName: payload.userInfo.carrierName,
          countryCode: payload.userInfo.countryCode,
          createdSample: payload.userInfo.createdSample,
          credits: payload.userInfo.credits,
          dateCreated: payload.userInfo.dateCreated,
          email: payload.userInfo.email,
          firebaseId: payload.userInfo.firebaseId,
          id: payload.userInfo.id,
          lastUpdatedDate: payload.userInfo.lastUpdatedDate,
          identityProviders: payload.userInfo.identityProviders,
          phoneNumber: payload.userInfo.phoneNumber,
          platformName: payload.userInfo.platform.name,
          rtdbShard: payload.userInfo.rtdbShard,
        },
      };
    case CREATE_NOTE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case CREATE_NOTE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_AUTH_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case DELETE_AUTH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_AUTH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload },
      };
    case DELETE_USER_DIALOG_OPEN:
      return {
        ...state,
        deleteUserDialogIsOpen: payload,
      };
    case DELETE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case DELETE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...initialState,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload },
      };
    case DELETION_CONFIRMATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case DELETION_CONFIRMATION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case DELETION_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload },
      };
    case DELETE_SUBSCRIPTION_DIALOG_OPEN:
      return {
        ...state,
        deleteSubscriptionDialogIsOpen: payload,
      };
    case EDIT_SUBSCRIPTION_DIALOG_OPEN:
      return {
        ...state,
        editSubscriptionDialogIsOpen: payload,
      };
    case FETCH_TEAM_CHAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case FETCH_TEAM_CHAT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_TEAM_CHAT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        teamChatHistory: payload.map((message) => ({
          burnerId: message.burnerId,
          dateCreated: message.dateCreated,
          direction: message.direction,
          id: message.id,
          message: message.message,
        })),
        teamChatHistoryHasBeenFetched: true,
      };
    case REMOVE_USER_DELETION_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case REMOVE_USER_DELETION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case REMOVE_USER_DELETION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userDeletion: initialState.userDeletion,
      };
    case REMOVE_USER_DELETION_DIALOG_OPEN:
      return {
        ...state,
        removeUserDeletionDialogIsOpen: payload,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        blacklistInfo: payload.blacklistInfo.response,
        blockedContacts: payload.blockedContacts.response.map(
          (contact) => contact.phoneNumber
        ),
        burners: payload.burners.response.map((burner) => ({
          dates: {
            dateCreated: burner.dates.dateCreated,
            expirationDate: burner.dates.expirationDate,
          },
          id: burner.id,
          name: burner.name,
          numberType: burner.numberType,
          phoneNumberId: burner.phoneNumberId,
          usage: {
            remainingMinutes: burner.usage.remainingMinutes,
            remainingTexts: burner.usage.remainingTexts,
          },
        })),
        events: payload.events.response.map((event) => ({
          credits: event.credits,
          dateCreated: event.dateCreated,
          eventType: event.eventType.id,
        })),
        isFetching: false,
        lookupHistory: payload.lookupHistory.response.map((burner) => ({
          dateCreated: burner.dateCreated,
          dateReleased: burner.dateReleased,
          phoneNumber: burner.phoneNumber,
        })),
        notes: payload.notes.response.map((note) => ({
          dateCreated: note.dateCreated,
          id: note.id,
          note: note.note,
        })),
        settings: { dndEnabled: payload.settings.response.dndEnabled },
        subscriptionEvents: {
          count: payload.subscriptionEvents.response.count,
          rows: payload.subscriptionEvents.response.rows.map((row) => ({
            burnerNumber: row.burnerNumber,
            id: row.id,
            dateCreated: row.dateCreated,
            price: row.price,
            sku: row.sku,
            storeId: row.storeId,
            subscriptionEventType: row.subscriptionEventType.name,
            subscriptionId: row.subscriptionId,
            userId: row.userId,
          })),
        },
        subscriptions: payload.subscriptions.response.map((sub) => ({
          burnerIds: sub.burnerIds,
          burnersAssignedInPeriod: sub.burnersAssignedInPeriod,
          cancellationDate: sub.cancellationDate,
          creationDate: sub.creationDate,
          expirationDate: sub.expirationDate,
          id: sub.id,
          renewalDate: sub.renewalDate,
          sku: sub.sku,
          store: sub.store,
        })),
        transactions: payload.transactions.response.map((transaction) => ({
          dateCreated: transaction.dateCreated,
          transactionId: transaction.transactionId,
        })),
        userDeletion: isEmpty(payload.userDeletion)
          ? payload.userDeletion
          : {
              dateForDeletion: payload.userDeletion.response.dateForDeletion,
            },
        userInfo: {
          appVersion: payload.userInfo.appVersion,
          carrierName: payload.userInfo.carrierName,
          countryCode: payload.userInfo.countryCode,
          createdSample: payload.userInfo.createdSample,
          credits: payload.userInfo.credits,
          dateCreated: payload.userInfo.dateCreated,
          email: payload.userInfo.email,
          firebaseId: payload.userInfo.firebaseId,
          id: payload.userInfo.id,
          lastUpdatedDate: payload.userInfo.lastUpdatedDate,
          identityProviders: payload.userInfo.identityProviders,
          phoneNumber: payload.userInfo.phoneNumber,
          platformName: payload.userInfo.platform.name,
          rtdbShard: payload.userInfo.rtdbShard,
        },
        verifyCode: isEmpty(payload.verifyCode)
          ? payload.verifyCode
          : {
              code: payload.verifyCode.response.code,
            },
      };
    case FETCH_VERIFY_CODE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case FETCH_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        verifyCode: isEmpty(payload.verifyCode)
          ? payload.verifyCode
          : {
              code: payload.verifyCode.code,
            },
      };
    case FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        latestOrderIds: { ...state.latestOrderIds, ...payload },
      };
    case FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: initialState.message,
      };
    case PUSH_NOTIFICATION_DIALOG_OPEN:
      return {
        ...state,
        pushDialogIsOpen: payload,
      };
    case REMOVE_BURNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case REMOVE_BURNER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case SEARCH_USER_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case SEND_PUSH_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case SEND_PUSH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SEND_PUSH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload },
      };
    case SEND_TEAM_CHAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case SEND_TEAM_CHAT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SEND_VERIFY_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case SEND_VERIFY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SET_BURNERS_ASSIGNED_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case SET_BURNERS_ASSIGNED_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SET_BURNERS_ASSIGNED_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        subscriptions: state.subscriptions.map((sub) => {
          return sub.id === payload.subscription.id
            ? {
                burnerIds: payload.subscription.burnerIds,
                burnersAssignedInPeriod:
                  payload.subscription.burnersAssignedInPeriod,
                cancellationDate: payload.subscription.cancellationDate,
                creationDate: payload.subscription.creationDate,
                expirationDate: payload.subscription.expirationDate,
                id: payload.subscription.id,
                renewalDate: payload.subscription.renewalDate,
                sku: payload.subscription.sku,
                store: payload.subscription.store,
              }
            : sub;
        }),
        subscriptionToEdit: {
          burnerIds: payload.subscription.burnerIds,
          burnersAssignedInPeriod: payload.subscription.burnersAssignedInPeriod,
          cancellationDate: payload.subscription.cancellationDate,
          creationDate: payload.subscription.creationDate,
          expirationDate: payload.subscription.expirationDate,
          id: payload.subscription.id,
          renewalDate: payload.subscription.renewalDate,
          sku: payload.subscription.sku,
          store: payload.subscription.store,
        },
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscriptionToEdit: payload,
      };
    case SET_SUBSCRIPTION_TO_DELETE:
      return {
        ...state,
        subscriptionToDelete: {
          id: payload.id,
          sku: payload.sku,
        },
      };
    case SUBSCRIPTION_EVENTS_DIALOG_OPEN:
      return {
        ...state,
        subscriptionEventsDialogIsOpen: payload,
      };
    case TOGGLE_DO_NOT_DISTURB_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case TOGGLE_DO_NOT_DISTURB_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case TOGGLE_DO_NOT_DISTURB_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        settings: { dndEnabled: payload.settings.dndEnabled },
      };
    case TOGGLE_SAMPLE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case TOGGLE_SAMPLE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case TOGGLE_SAMPLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userInfo: {
          appVersion: payload.appVersion,
          carrierName: payload.carrierName,
          countryCode: payload.countryCode,
          createdSample: payload.createdSample,
          credits: payload.credits,
          dateCreated: payload.dateCreated,
          email: payload.email,
          firebaseId: payload.firebaseId,
          id: payload.id,
          identityProviders: payload.identityProviders,
          lastUpdatedDate: payload.lastUpdatedDate,
          phoneNumber: payload.phoneNumber,
          platformName: payload.platform.name,
          rtdbShard: payload.rtdbShard,
        },
      };
    case UPDATE_NOTES_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case UPDATE_NOTES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        notes: payload.notes.map((note) => ({
          dateCreated: note.dateCreated,
          id: note.id,
          note: note.note,
        })),
      };
    case UPDATE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case UPDATE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        subscriptions: payload.subscriptions.map((sub) => ({
          burnerIds: sub.burnerIds,
          burnersAssignedInPeriod: sub.burnersAssignedInPeriod,
          cancellationDate: sub.cancellationDate,
          creationDate: sub.creationDate,
          expirationDate: sub.expirationDate,
          id: sub.id,
          renewalDate: sub.renewalDate,
          sku: sub.sku,
          store: sub.store,
        })),
      };
    case UPDATE_TEAM_CHAT_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case UPDATE_TEAM_CHAT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        teamChatHistory: payload.teamChatHistory.map((message) => ({
          burnerId: message.burnerId,
          dateCreated: message.dateCreated,
          direction: message.direction,
          id: message.id,
          message: message.message,
        })),
      };
    case VALIDATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        refreshSubHasBeenClicked: true,
      };
    case VALIDATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        message: { isError: true, isOpen: true, text: payload },
      };
    case VALIDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        message: { isError: false, isOpen: true, text: payload },
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const searchForUserEpic = (action$) => {
  const searchForUser = (action) => {
    const { country, userIdentifier, token } = action.payload;
    const formattedNumber = formatE164IfValidPhoneNumber(
      userIdentifier,
      country
    );
    const email = isEmail(userIdentifier);
    const getUserUrl = formattedNumber
      ? `${
          process.env.REACT_APP_BASE_URL
        }/admin/users?phoneNumber=${encodeURIComponent(formattedNumber)}`
      : email
      ? `${
          process.env.REACT_APP_BASE_URL
        }/admin/users?email=${encodeURIComponent(userIdentifier)}`
      : `${process.env.REACT_APP_BASE_URL}/admin/users/${userIdentifier}`;

    return ajax({
      url: getUserUrl,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(SEARCH_USER_REQUEST),
    mergeMap((action) =>
      searchForUser(action).pipe(
        map((response) =>
          fetchUserRequest({
            userInfo: response.response,
            token: action.payload.token,
          })
        ),
        catchError((error) =>
          of(
            searchUserFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Something went wrong.'
            )
          )
        )
      )
    )
  );
};

export const fetchUserInfoEpic = (action$) => {
  const getUsersBlacklistInfo = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/blacklist`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUsersSettingsInfo = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/settings`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUsersVerifyCodeInfo = (action) => {
    const { userInfo, token } = action.payload;
    const { phoneNumber } = userInfo;
    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/verifyCode?phoneNumber=${encodeURIComponent(phoneNumber)}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUsersSubscriptionsInfo = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/v2/users/${id}/subscriptions`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  // TODO need to see if I can download all events at once
  const getUsersSubscriptionEventsInfo = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/subscriptions/events?page=1&pageSize=100`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUsersBurners = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/burners`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUsersLookupHistory = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/burners/number/history/user/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUsersEvents = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/events/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUsersTransactions = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/transactions`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUsersBlockedContacts = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/contacts?blocked=true&page=1&pageSize=50`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUsersNotes = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/notes`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getUserDeletion = (action) => {
    const { userInfo, token } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/deletion`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_USER_REQUEST),
    mergeMap((action) =>
      forkJoin({
        blacklistInfo: getUsersBlacklistInfo(action).pipe(
          catchError((error) =>
            iif(
              () => error.status && error.status === 404,
              of({}),
              throwError(error)
            )
          )
        ),
        settings: getUsersSettingsInfo(action).pipe(
          catchError((error) => throwError(error))
        ),
        verifyCode: getUsersVerifyCodeInfo(action).pipe(
          catchError((error) =>
            iif(
              () => error.status && error.status === 404,
              of({}),
              throwError(error)
            )
          )
        ),
        subscriptions: getUsersSubscriptionsInfo(action).pipe(
          catchError((error) => throwError(error))
        ),
        subscriptionEvents: getUsersSubscriptionEventsInfo(action).pipe(
          catchError((error) => throwError(error))
        ),
        burners: getUsersBurners(action).pipe(
          catchError((error) => throwError(error))
        ),
        lookupHistory: getUsersLookupHistory(action).pipe(
          catchError((error) => throwError(error))
        ),
        events: getUsersEvents(action).pipe(
          catchError((error) => throwError(error))
        ),
        transactions: getUsersTransactions(action).pipe(
          catchError((error) => throwError(error))
        ),
        blockedContacts: getUsersBlockedContacts(action).pipe(
          catchError((error) => throwError(error))
        ),
        notes: getUsersNotes(action).pipe(
          catchError((error) => throwError(error))
        ),
        userDeletion: getUserDeletion(action).pipe(
          catchError((error) =>
            iif(
              () => error.status && error.status === 404,
              of({}),
              throwError(error)
            )
          )
        ),
      }).pipe(
        map((response) =>
          fetchUserSuccess({ ...response, userInfo: action.payload.userInfo })
        ),
        catchError((error) =>
          of(
            fetchUserFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Something went wrong. Please try again.'
            )
          )
        )
      )
    )
  );
};

export const toggleCreatedSampleEpic = (action$) => {
  const toggleCreatedSample = (action) => {
    const { token, id } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/toggleCreatedSample`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {},
    });
  };

  return action$.pipe(
    ofType(TOGGLE_SAMPLE_REQUEST),
    mergeMap((action) =>
      toggleCreatedSample(action).pipe(
        map((response) => toggleSampleSuccess(response.response)),
        catchError((error) =>
          of(
            toggleSampleFailure(
              error.response && error.response.error
                ? error.response.error
                : "Can't change created sample value"
            )
          )
        )
      )
    )
  );
};

export const sendVerifyCodeEpic = (action$) => {
  const sendVerifyCode = (action) => {
    const { phoneNumber, token } = action.payload;
    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/verifyCode/${encodeURIComponent(phoneNumber)}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {},
    });
  };

  return action$.pipe(
    ofType(SEND_VERIFY_REQUEST),
    mergeMap((action) =>
      sendVerifyCode(action).pipe(
        map(() => fetchVerifyCodeRequest({ ...action.payload })),
        catchError((error) =>
          of(
            sendVerifyFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Unable to resend verify code.'
            )
          )
        )
      )
    )
  );
};

export const fetchVerifyCodeEpic = (action$) => {
  const fetchVerifyCode = (action) => {
    const { phoneNumber, token } = action.payload;
    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/verifyCode?phoneNumber=${encodeURIComponent(phoneNumber)}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_VERIFY_CODE_REQUEST),
    mergeMap((action) =>
      fetchVerifyCode(action).pipe(
        map((response) =>
          fetchVerifyCodeSuccess({
            message: 'Successfully sent verify code!',
            verifyCode: response.response,
          })
        ),
        catchError((error) =>
          of(
            fetchVerifyCodeFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Unable to update user info. Please search the user again to update verify code'
            )
          )
        )
      )
    )
  );
};

export const fetchOrderIdEpic = (action$) => {
  const fetchLatestOrderId = (action) => {
    const { subId, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/subscriptions/${subId}/latest-google-order-id`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_SUBSCRIPTION_EVENT_WITH_ORDER_ID_REQUEST),
    mergeMap((action) =>
      fetchLatestOrderId(action).pipe(
        map((response) => {
          const payloadObj = {};
          payloadObj[action.payload.subId] = response.response;
          return fetchLatestOrderIdSuccess(payloadObj);
        }),
        catchError((error) =>
          of(
            fetchLatestOrderIdFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Unable to fetch subscription event order ID'
            )
          )
        )
      )
    )
  );
};

export const removeUserDeletionEpic = (action$) => {
  const removeUserDeletion = (action) => {
    const { id, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/deletion-queue`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(REMOVE_USER_DELETION_REQUEST),
    mergeMap((action) =>
      removeUserDeletion(action).pipe(
        map((response) =>
          removeUserDeletionSuccess({
            message: 'Successfully removed user from deletion queue!',
            userDeletion: response.response,
          })
        ),
        catchError((error) =>
          of(
            removeUserDeletionFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Unable to remove user from deletion queue'
            )
          )
        )
      )
    )
  );
};

export const adjustUserCreditsEpic = (action$) => {
  const adjustUserCredits = (action) => {
    const { credits, token, userId } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/credits`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        credits,
        userId,
      },
    });
  };

  return action$.pipe(
    ofType(ADJUST_CREDITS_REQUEST),
    mergeMap((action) =>
      adjustUserCredits(action).pipe(
        map((response) =>
          adjustCreditsSuccess({
            message: `User now has ${response.response.credits} credits`,
            userInfo: response.response,
          })
        ),
        catchError((error) =>
          of(
            adjustCreditsFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error changing credits value'
            )
          )
        )
      )
    )
  );
};

export const changeUserCountryCodeEpic = (action$) => {
  const changeCountryCode = (action) => {
    const { countryCode, token, id } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/countryCode`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        countryCode,
      },
    });
  };

  return action$.pipe(
    ofType(CHANGE_COUNTRY_REQUEST),
    mergeMap((action) =>
      changeCountryCode(action).pipe(
        map((response) =>
          changeCountrySuccess({
            message: `User country code is now ${response.response.countryCode}`,
            userInfo: response.response,
          })
        ),
        catchError((error) =>
          of(
            changeCountryFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error replacing country code'
            )
          )
        )
      )
    )
  );
};

export const deleteUserConfirmationEpic = (action$) => {
  const sendDeletionConfirmation = (action) => {
    const { token, userId } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/message/deletion`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        userId,
      },
    });
  };

  return action$.pipe(
    ofType(DELETION_CONFIRMATION_REQUEST),
    mergeMap((action) =>
      sendDeletionConfirmation(action).pipe(
        map(() =>
          deletionConfirmationSuccess(
            `Confirmation text message sent to ${action.payload.phoneNumber}`
          )
        ),
        catchError((error) =>
          of(
            deletionConfirmationFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error sending confirmation text'
            )
          )
        )
      )
    )
  );
};

export const deleteUserEpic = (action$) => {
  const deleteUser = (action) => {
    const { token, id } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(DELETE_USER_REQUEST),
    mergeMap((action) =>
      deleteUser(action).pipe(
        map(() => deleteUserSuccess('User deleted')),
        catchError((error) =>
          of(
            deleteUserFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error deleting user'
            )
          )
        )
      )
    )
  );
};

export const toggleDoNotDisturbEpic = (action$) => {
  const toggleDoNotDisturb = (action) => {
    const { checked, id, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/settings`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        dndEnabled: checked,
      },
    });
  };

  return action$.pipe(
    ofType(TOGGLE_DO_NOT_DISTURB_REQUEST),
    mergeMap((action) =>
      toggleDoNotDisturb(action).pipe(
        map((response) =>
          toggleDoNotDisturbSuccess({
            message: 'Settings successfully updated.',
            settings: response.response,
          })
        ),
        catchError((error) =>
          of(
            toggleDoNotDisturbFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Could not update settings'
            )
          )
        )
      )
    )
  );
};

export const sendPushNotificationEpic = (action$) => {
  const sendPushNotification = (action) => {
    const { id, body, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/pushNotifications`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body,
    });
  };

  return action$.pipe(
    ofType(SEND_PUSH_REQUEST),
    mergeMap((action) =>
      sendPushNotification(action).pipe(
        map(() => sendPushSuccess('Test push notification successfully sent.')),
        catchError((error) =>
          of(
            sendPushFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error sending test push notification.'
            )
          )
        )
      )
    )
  );
};

export const deleteUserAuthTokenEpic = (action$) => {
  const deleteUserAuthToken = (action) => {
    const { id, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/auth-token`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(DELETE_AUTH_REQUEST),
    mergeMap((action) =>
      deleteUserAuthToken(action).pipe(
        map(() => deleteAuthSuccess('Auth token successfully removed!')),
        catchError((error) =>
          of(
            deleteAuthFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Unable to remove auth token'
            )
          )
        )
      )
    )
  );
};

export const setBurnersAssignedEpic = (action$) => {
  const setBurnersAssigned = (action) => {
    const { count, subscriptionId, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/subscriptions/burnerCount?count=${count}&subscriptionId=${subscriptionId}`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(SET_BURNERS_ASSIGNED_REQUEST),
    mergeMap((action) =>
      setBurnersAssigned(action).pipe(
        map((response) =>
          setBurnersAssignedSuccess({
            message: 'Subscription updated successfully.',
            subscription: response.response,
          })
        ),
        catchError((error) =>
          of(
            setBurnersAssignedFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error updating the subscription.'
            )
          )
        )
      )
    )
  );
};

export const addBurnerToSubscriptionEpic = (action$) => {
  const addBurnerFromSubscription = (action) => {
    const { burnerId, subscriptionId, token, userId } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${userId}/subscriptions/${subscriptionId}/burners/${burnerId}`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(ADD_BURNER_REQUEST),
    mergeMap((action) =>
      addBurnerFromSubscription(action).pipe(
        map(() =>
          updateSubscriptionsRequest({
            ...action.payload,
          })
        ),
        catchError((error) =>
          of(
            addBurnerFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error updating the subscription.'
            )
          )
        )
      )
    )
  );
};

export const removeBurnerFromSubscriptionEpic = (action$) => {
  const removeBurnerFromSubscription = (action) => {
    const { burnerId, subscriptionId, token, userId } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/burners/${burnerId}/subscription?subscriptionId=${subscriptionId}&userId=${userId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(REMOVE_BURNER_REQUEST),
    mergeMap((action) =>
      removeBurnerFromSubscription(action).pipe(
        map(() =>
          updateSubscriptionsRequest({
            ...action.payload,
          })
        ),
        catchError((error) =>
          of(
            removeBurnerFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error updating the subscription.'
            )
          )
        )
      )
    )
  );
};

export const updateUsersSubscriptionEpic = (action$) => {
  const updateUsersSubscriptions = (action) => {
    const { userId, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${userId}/subscriptions`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(UPDATE_SUBSCRIPTIONS_REQUEST),
    mergeMap((action) =>
      updateUsersSubscriptions(action).pipe(
        map((response) =>
          updateSubscriptionsSuccess({
            message: 'Subscription updated successfully.',
            subscriptions: response.response,
          })
        ),
        catchError((error) =>
          of(
            updateSubscriptionsFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error updating the subscription.'
            )
          )
        )
      )
    )
  );
};

export const deleteSubscriptionEpic = (action$) => {
  const deleteSubscription = (action) => {
    const { subscriptionId, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/subscriptions/${subscriptionId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(DELETE_SUBSCRIPTION_REQUEST),
    mergeMap((action) =>
      deleteSubscription(action).pipe(
        map(() =>
          updateSubscriptionsRequest({
            ...action.payload,
          })
        ),
        catchError((error) =>
          of(
            deleteSubscriptionFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Unable to delete subscription.'
            )
          )
        )
      )
    )
  );
};

export const createUserNotesEpic = (action$) => {
  const createUserNote = (action) => {
    const { id, reason, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/notes`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        reason,
      },
    });
  };

  return action$.pipe(
    ofType(CREATE_NOTE_REQUEST),
    mergeMap((action) =>
      createUserNote(action).pipe(
        map(() =>
          updateNotesRequest({
            ...action.payload,
          })
        ),
        catchError((error) =>
          of(
            createNoteFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error creating note.'
            )
          )
        )
      )
    )
  );
};

export const updateUsersNotesEpic = (action$) => {
  const updateUsersNotes = (action) => {
    const { id, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/notes`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };
  return action$.pipe(
    ofType(UPDATE_NOTES_REQUEST),
    mergeMap((action) =>
      updateUsersNotes(action).pipe(
        map((response) =>
          updateNotesSuccess({
            message: 'Note was successfully created.',
            notes: response.response,
          })
        ),
        catchError((error) =>
          of(
            updateNotesFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error updating the subscription.'
            )
          )
        )
      )
    )
  );
};

export const fetchTeamChatHistoryEpic = (action$) => {
  const fetchTeamChatHistory = (action) => {
    const { id, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/messages?contactStatus=system_number`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };
  return action$.pipe(
    ofType(FETCH_TEAM_CHAT_REQUEST),
    mergeMap((action) =>
      fetchTeamChatHistory(action).pipe(
        map((response) => fetchTeamChatSuccess(response.response)),
        catchError((error) =>
          of(
            fetchTeamChatFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error retrieving messages.'
            )
          )
        )
      )
    )
  );
};

export const sendTeamChatMessageEpic = (action$) => {
  const sendTeamChatMessage = (action) => {
    const { body, burnerId, id, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/messages/inbound`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: { burnerId, body },
    });
  };

  return action$.pipe(
    ofType(SEND_TEAM_CHAT_REQUEST),
    mergeMap((action) =>
      sendTeamChatMessage(action).pipe(
        map(() =>
          updateTeamChatRequest({
            ...action.payload,
          })
        ),
        catchError((error) =>
          of(
            sendTeamChatFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Message failed.'
            )
          )
        )
      )
    )
  );
};

export const updateTeamChatHistoryEpic = (action$) => {
  const updateTeamChatHistory = (action) => {
    const { id, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/messages?contactStatus=system_number`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };
  return action$.pipe(
    ofType(UPDATE_TEAM_CHAT_REQUEST),
    mergeMap((action) =>
      updateTeamChatHistory(action).pipe(
        map((response) =>
          updateTeamChatSuccess({
            message: 'Message was successfully sent.',
            teamChatHistory: response.response,
          })
        ),
        catchError((error) =>
          of(
            updateTeamChatFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error updating the subscription.'
            )
          )
        )
      )
    )
  );
};

export const validateSubscriptionEpic = (action$) => {
  const validateSubscription = (action) => {
    const { id, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/v2/subscriptions/${id}/validate`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {},
    });
  };
  return action$.pipe(
    ofType(VALIDATE_SUBSCRIPTION_REQUEST),
    mergeMap((action) =>
      validateSubscription(action).pipe(
        map((response) =>
          validateSubscriptionSuccess(
            'Success! In a minute, please check subscription data again.'
          )
        ),
        catchError((error) =>
          of(
            validateSubscriptionFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error refreshing subscription data.'
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getBurners = createSelector(
  (state) => state.viewUser.burners,
  (burners) => enrichBurners(burners)
);

export const getBlacklistInfo = createSelector(
  (state) => state.viewUser.blacklistInfo,
  (blacklistInfo) => enrichBlacklistInfo(blacklistInfo)
);

export const getUserDeletion = createSelector(
  (state) => state.viewUser.userDeletion,
  (userDeletion) => enrichUserDeletion(userDeletion)
);

export const getNotes = createSelector(
  (state) => state.viewUser.notes,
  (notes) => enrichNotes(notes)
);

export const getPreviouslyOwnedPhoneNumbers = createSelector(
  (state) => state.viewUser.burners,
  (state) => state.viewUser.lookupHistory,
  (active, historical) =>
    transformPreviouslyOwnedPhoneNumbers(active, historical)
);

export const getSubscriptionEvents = createSelector(
  (state) => state.viewUser.subscriptionEvents,
  (events) => enrichSubscriptionEvents(events)
);

export const getLatestOrderId = createSelector(
  (state) => state.viewUser.latestOrderIds,
  (event) => enrichSubChangeOrderId(event)
);

export const getSubscriptions = createSelector(
  (state) => state.viewUser.subscriptions,
  (subscriptions) => enrichSubscriptions(subscriptions)
);

export const getTeamChatHistory = createSelector(
  (state) => state.viewUser.teamChatHistory,
  (messages) => enrichTeamChatHistory(messages)
);

export const getTeamChatId = createSelector(
  (state) => state.viewUser.teamChatHistory,
  (state) => state.viewUser.burners,
  (messages, burners) => transformBurnerIdForMessaging(messages, burners)
);

export const getUserInfo = createSelector(
  (state) => state.viewUser.userInfo,
  (userInfo) => enrichUserInfo(userInfo)
);

export const getUserTransactions = createSelector(
  (state) => state.viewUser.events,
  (state) => state.viewUser.transactions,
  (events, transactions) => transformUserTransactions(events, transactions)
);

export const getVerifyCode = createSelector(
  (state) => state.viewUser.verifyCode,
  (verifyCode) => transformVerifyCode(verifyCode)
);

import { connect } from 'react-redux';
import {
  adjustCreditsRequest,
  changeCountryRequest,
  deleteAuthRequest,
  deleteUserDialogOpen,
  deleteUserRequest,
  deletionConfirmationRequest,
  getUserInfo,
  getVerifyCode,
  pushNotificationDialogOpen,
  sendPushRequest,
  sendVerifyRequest,
  toggleDoNotDisturbRequest,
} from 'ducks/view-user-info';
import AccountControlsSection from 'components/pages/view-user-info/account-controls/AccountControlsSection';

const mapStateToProps = (state) => {
  const {
    deleteUserDialogIsOpen,
    isFetching,
    pushDialogIsOpen,
    settings,
  } = state.viewUser;

  return {
    deleteUserDialogIsOpen,
    isFetching,
    pushDialogIsOpen,
    settings,
    userInfo: getUserInfo(state),
    verifyCode: getVerifyCode(state),
  };
};

const mapDispatchToProps = {
  adjustCreditsRequest,
  changeCountryRequest,
  deleteAuthRequest,
  deleteUserDialogOpen,
  deleteUserRequest,
  deletionConfirmationRequest,
  pushNotificationDialogOpen,
  sendPushRequest,
  sendVerifyRequest,
  toggleDoNotDisturbRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountControlsSection);

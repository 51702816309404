import { connect } from 'react-redux';
import {
  transferDialogOpen,
  transferOwnershipRequest,
} from 'ducks/view-burner';
import TransferOwnership from 'components/pages/view-burner/TransferOwnership';

const mapStateToProps = (state) => {
  const { burnerInfo, isFetching, transferDialogIsOpen } = state.viewBurner;

  return {
    burnerInfo,
    isFetching,
    isOpen: transferDialogIsOpen,
  };
};

const mapDispatchToProps = {
  handleOpen: transferDialogOpen,
  handleSubmit: transferOwnershipRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferOwnership);

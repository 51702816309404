import isEmpty from 'lodash/isEmpty';

export const transformLegalData = (legalData) => {
  if (isEmpty(legalData)) return legalData;

  let csvContent = 'data:text/csv;charset=utf-8,';

  legalData.data.map((dataArray) => {
    const dataString = dataArray.map((dataPoint) => `"${dataPoint}"`).join(',');
    return (csvContent += dataString + '\r\n');
  });

  return {
    ...legalData,
    data: csvContent,
  };
};

export const createCsvDownload = ({ data, filename }) => {
  const link = document.createElement('a');

  if (typeof link.download === 'string') {
    const encodedData = encodeURI(data).replaceAll('#', '%23');
    link.href = encodedData;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(data);
  }
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { countries } from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { userIdentifierSchema } from 'utils/view-user-info';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userIdentifierParam: PropTypes.string,
};

function UserSearch({
  submitButtonText = 'Submit',
  handleSubmit,
  isFetching,
  userIdentifierParam = null,
}) {
  const { getTokenSilently, logout } = useAuth0();
  const initialValues = {
    country: 'US',
    userIdentifier: '',
  };

  useEffect(() => {
    if (!userIdentifierParam) return;

    const token$ = from(getTokenSilently()).subscribe({
      next: (token) =>
        handleSubmit({
          userIdentifier: userIdentifierParam,
          token,
        }),
      error: () => logout({ returnTo: window.location.origin }),
    });

    return () => token$.unsubscribe();
  }, [getTokenSilently, handleSubmit, logout, userIdentifierParam]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        from(getTokenSilently()).subscribe({
          next: (token) =>
            handleSubmit({
              ...values,
              token,
            }),
          error: () => logout({ returnTo: window.location.origin }),
          complete: () => resetForm(initialValues),
        });
      }}
      validationSchema={userIdentifierSchema}
    >
      {({ values }) => (
        <Form className="number-input">
          <Field name="userIdentifier">
            {({ field, form: { errors, touched } }) => (
              <TextField
                {...field}
                className="input"
                disabled={isFetching}
                error={errors.userIdentifier && touched.userIdentifier}
                helperText={
                  errors.userIdentifier &&
                  touched.userIdentifier &&
                  errors.userIdentifier
                }
                id="userIdentifier"
                InputProps={
                  errors.userIdentifier &&
                  touched.userIdentifier && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <ErrorOutline className="error" />
                      </InputAdornment>
                    ),
                  }
                }
                label="User ID, phone, or email*"
                margin="normal"
                type="text"
                variant="standard"
              />
            )}
          </Field>
          <Field name="country">
            {({ field }) => (
              <TextField
                {...field}
                className="input"
                disabled={isFetching}
                label="Country"
                margin="normal"
                variant="standard"
                select
              >
                {countries &&
                  countries.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          </Field>
          <Button
            className="button"
            color="primary"
            disabled={isFetching || values.userIdentifier.length < 3}
            size="medium"
            type="submit"
            variant="outlined"
          >
            {submitButtonText}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

UserSearch.propTypes = propTypes;

export default UserSearch;

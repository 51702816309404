import { connect } from 'react-redux';
import {
  getNumberHistory,
  handleCloseSnackbar,
  searchBurnersRequest,
} from 'ducks/view-burner';
import ViewBurner from 'components/pages/ViewBurner';

const mapStateToProps = (state) => {
  const { burnerInfo, isFetching, message } = state.viewBurner;

  return {
    burnerInfo,
    isFetching,
    message,
    ...getNumberHistory(state),
  };
};

const mapDispatchToProps = {
  handleCloseSnackbar,
  searchBurnersRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewBurner);

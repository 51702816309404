import { base64Encode, formatDate } from 'utils/general';

export const columns = [
  {
    name: 'area_code',
    label: 'Area Code',
    options: {
      filter: true,
      sort: true,
      hint:
        'Values in each row are a subset of non-deleted phone numbers in this area code',
    },
  },
  {
    name: 'total',
    label: 'Total',
    options: {
      filter: true,
      sort: true,
      hint: 'Count of all numbers',
    },
  },
  {
    name: 'burner',
    label: 'Burner',
    options: {
      filter: true,
      sort: true,
      hint: 'Numbers attached to a Burner',
    },
  },
  {
    name: 'available',
    label: 'Available',
    options: {
      filter: true,
      sort: true,
      hint: 'Numbers NOT attached to a Burner',
    },
  },
  {
    name: 'available_clean',
    label: 'Available Clean',
    options: {
      filter: true,
      sort: true,
      hint:
        'Numbers NOT attached to a Burner with NO inbound texts/calls in the last 24 hours',
    },
  },
  {
    name: 'holding',
    label: 'Holding',
    options: {
      filter: true,
      sort: true,
      hint: 'Numbers detached from a Burner in the last 7 days',
    },
  },
  {
    name: 'new',
    label: 'New',
    options: {
      filter: true,
      sort: true,
      hint: 'Numbers purchased in the last 24 hours',
    },
  },
  {
    name: 'detached',
    label: 'Detached',
    options: {
      filter: true,
      sort: true,
      hint: 'Numbers detached from a Burner in the last 48 hours',
    },
  },
  {
    name: 'detached_clean',
    label: 'Detached Clean',
    options: {
      filter: true,
      sort: true,
      hint:
        'Numbers detached from a Burner in the last 48 hours with NO inbound texts/calls in the last 24 hours',
    },
  },
  {
    name: 'dirty',
    label: 'Dirty',
    options: {
      filter: true,
      sort: true,
      hint:
        'Numbers NOT attached to a Burner that have at least one inbound text/call in the last 24 hours',
    },
  },
  {
    name: 'consistently_dirty',
    label: 'Consistently Dirty',
    options: {
      filter: true,
      sort: true,
      hint:
        'Numbers NOT attached to a Burner that have not gone 24 hours without an inbound text/call for 14 days',
    },
  },
  {
    name: 'pending',
    label: 'Pending',
    options: {
      filter: true,
      sort: true,
      hint: 'Numbers stuck in Pending Burner status',
    },
  },
];

export const options = {
  download: false,
  elevation: 0,
  filter: false,
  filterType: 'textField',
  print: false,
  selectableRows: 'none',
};

export const enrichNumberCacheData = (numCacheData) => {
  const summaryData = {
    area_code: 'Total Sums',
    available: numCacheData.reduce((acc, cur) => acc + cur.available, 0),
    available_clean: numCacheData.reduce(
      (acc, cur) => acc + cur.available_clean,
      0
    ),
    burner: numCacheData.reduce((acc, cur) => acc + cur.burner, 0),
    consistently_dirty: numCacheData.reduce(
      (acc, cur) => acc + cur.consistently_dirty,
      0
    ),
    created_date: numCacheData[0]
      ? formatDate(numCacheData[0].created_date)
      : '',
    detached: numCacheData.reduce((acc, cur) => acc + cur.detached, 0),
    detached_clean: numCacheData.reduce(
      (acc, cur) => acc + cur.detached_clean,
      0
    ),
    dirty: numCacheData.reduce((acc, cur) => acc + cur.dirty, 0),
    holding: numCacheData.reduce((acc, cur) => acc + cur.holding, 0),
    new: numCacheData.reduce((acc, cur) => acc + cur.new, 0),
    pending: numCacheData.reduce((acc, cur) => acc + cur.pending, 0),
    total: numCacheData.reduce((acc, cur) => acc + cur.total, 0),
  };

  return [summaryData, ...numCacheData];
};

export const getNumberCacheAnalyticsToken = () => {
  return base64Encode(
    `${process.env.REACT_APP_NCA_USERNAME}:${process.env.REACT_APP_NCA_PASSWORD}`
  );
};

import { connect } from 'react-redux';
import {
  getUserDeletion,
  getUserInfo,
  removeUserDeletionDialogOpen,
  removeUserDeletionRequest,
} from 'ducks/view-user-info';
import UserDeletionSection from 'components/pages/view-user-info/user-deletion/UserDeletionSection';

const mapStateToProps = (state) => {
  const { removeUserDeletionDialogIsOpen, isFetching } = state.viewUser;

  return {
    removeUserDeletionDialogIsOpen,
    isFetching,
    userDeletion: getUserDeletion(state),
    userInfo: getUserInfo(state),
  };
};

const mapDispatchToProps = {
  removeUserDeletionRequest,
  handleOpen: removeUserDeletionDialogOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDeletionSection);

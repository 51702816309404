import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const propTypes = {
  data: PropTypes.shape({
    createdAt: PropTypes.string,
    externalId: PropTypes.string,
    features: PropTypes.string,
    id: PropTypes.string,
    providerName: PropTypes.string,
    providerSpecificDetails: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

function PhoneNumberDetails({ data }) {
  const {
    createdAt,
    externalId,
    features,
    id,
    providerName,
    providerSpecificDetails,
    status,
  } = data;

  return (
    !isEmpty(data) && (
      <Grid item xs={12}>
        <Typography gutterBottom variant="caption">
          Phone Number Telephony Details
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">ID</TableCell>
              <TableCell>{id}</TableCell>
              <TableCell variant="head">Provider ID</TableCell>
              <TableCell>{externalId}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell variant="head">Provider Name</TableCell>
              <TableCell>{providerName}</TableCell>
              <TableCell variant="head">Status</TableCell>
              <TableCell>{status}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell variant="head">Created</TableCell>
              <TableCell>{createdAt}</TableCell>
              <TableCell variant="head">Features</TableCell>
              <TableCell>{features}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Provider-Specific Details</TableCell>
              <TableCell>{providerSpecificDetails}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    )
  );
}

PhoneNumberDetails.propTypes = propTypes;

export default PhoneNumberDetails;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { from } from 'rxjs';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import ConfirmDialog from 'components/ConfirmDialog';

const propTypes = {
  accountsToDelete: PropTypes.arrayOf(
    PropTypes.shape({
      dateForDeletion: PropTypes.string,
      phoneNumber: PropTypes.string,
      userId: PropTypes.string,
    })
  ).isRequired,
  accountToDelete: PropTypes.string,
  deleteAccount: PropTypes.func.isRequired,
  deleteAccountDialogIsOpen: PropTypes.bool.isRequired,
  deleteAccountFailure: PropTypes.func.isRequired,
  fetchAccountsFailure: PropTypes.func.isRequired,
  fetchAccountsToDelete: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  setAccountToDelete: PropTypes.func.isRequired,
  setDeleteAccountDialogIsOpen: PropTypes.func.isRequired,
};

function AccountsToDeleteTable({
  accountsToDelete,
  accountToDelete = '',
  deleteAccount,
  deleteAccountFailure,
  deleteAccountDialogIsOpen,
  fetchAccountsToDelete,
  fetchAccountsFailure,
  isFetching,
  setAccountToDelete,
  setDeleteAccountDialogIsOpen,
}) {
  const { getTokenSilently, logout } = useAuth0();

  const handleOpenDialog = (userId) => {
    setDeleteAccountDialogIsOpen(true);
    setAccountToDelete(userId);
  };

  useEffect(() => {
    const token$ = from(getTokenSilently()).subscribe({
      next: (token) => fetchAccountsToDelete({ token }),
      error: () => logout({ returnTo: window.location.origin }),
    });

    return () => token$.unsubscribe();
  }, [fetchAccountsFailure, fetchAccountsToDelete, getTokenSilently, logout]);

  return (
    <Grid item xs={12}>
      <Typography data-testid="title-test" gutterBottom variant="h4">
        Accounts to Delete
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User Id</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Date Scheduled For Deletion</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>

        {!isEmpty(accountsToDelete) && (
          <TableBody>
            {accountsToDelete.map((account) => (
              <TableRow key={account.userId}>
                <TableCell>{account.userId}</TableCell>
                <TableCell>{account.phoneNumber}</TableCell>
                <TableCell>{account.dateForDeletion}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    className="button"
                    disabled={isFetching}
                    onClick={() => {
                      handleOpenDialog(account.userId);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <ConfirmDialog
        content="Are you sure you want to delete this user?"
        dialogIsOpen={deleteAccountDialogIsOpen}
        handleClick={() =>
          from(getTokenSilently()).subscribe({
            next: (token) => deleteAccount({ token, userId: accountToDelete }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => setDeleteAccountDialogIsOpen(false),
          })
        }
        handleClose={() => setDeleteAccountDialogIsOpen(false)}
        title="Delete Account"
      />
    </Grid>
  );
}

AccountsToDeleteTable.propTypes = propTypes;

export default AccountsToDeleteTable;

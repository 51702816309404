import React from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  teamChatHistory: PropTypes.arrayOf(
    PropTypes.shape({
      burnerId: PropTypes.string,
      dateCreated: PropTypes.string,
      direction: PropTypes.number,
      id: PropTypes.string,
      message: PropTypes.string,
    })
  ).isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

function BurnerTeamChatHistory({
  handleSubmit,
  isFetching,
  teamChatHistory,
  userInfo,
}) {
  const { getTokenSilently, logout } = useAuth0();
  const { id } = userInfo;
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Button
        className={classes.button}
        color="primary"
        disabled={isFetching}
        onClick={() =>
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                id,
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
          })
        }
        size="medium"
        type="submit"
        variant="outlined"
      >
        Get Burner Team Chat History
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6}>Burner Team Chat History</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1}>Timestamp</TableCell>
            <TableCell colSpan={1}>Sent/Received</TableCell>
            <TableCell colSpan={1}>Message</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {teamChatHistory &&
            teamChatHistory.map((message) => (
              <TableRow key={message.id}>
                <TableCell>{message.dateCreated}</TableCell>
                <TableCell>
                  {message.direction === 1 ? 'Received' : 'Sent'}
                </TableCell>
                <TableCell>{message.message}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

BurnerTeamChatHistory.propTypes = propTypes;

export default BurnerTeamChatHistory;

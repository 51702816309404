import React from 'react';
import PropTypes from 'prop-types';
import GetPortOutPin from 'components/pages/port-out-pin/GetPortOutPin';
import SetPortOutPin from 'components/pages/port-out-pin/SetPortOutPin';
import MessagingService from 'components/MessagingService';
import View from 'components/View';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';

const propTypes = {
  getPinRequest: PropTypes.func.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    pin: PropTypes.String,
  }),
  setPinRequest: PropTypes.func.isRequired,
};

function PortOutPin({
  data,
  getPinRequest,
  handleCloseSnackbar,
  isFetching,
  message,
  setPinRequest,
}) {
  const { hasPermission } = useAuth0();
  const { pin } = data;

  return hasPermission(routeScope('portOutPin')) ? (
    <View>
      <GetPortOutPin
        handleSubmit={getPinRequest}
        isFetching={isFetching}
        pin={pin}
      />
      <SetPortOutPin handleSubmit={setPinRequest} isFetching={isFetching} />
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

PortOutPin.propTypes = propTypes;

export default PortOutPin;

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BurnerTeamChatHistory from 'components/pages/view-user-info/burner-team-chat/BurnerTeamChatHistory';
import Messages from 'components/pages/view-user-info/burner-team-chat/Messages';

const propTypes = {
  teamChatId: PropTypes.string.isRequired,
  fetchTeamChatRequest: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  sendTeamChatRequest: PropTypes.func.isRequired,
  teamChatHistory: PropTypes.arrayOf(
    PropTypes.shape({
      burnerId: PropTypes.string,
      dateCreated: PropTypes.string,
      direction: PropTypes.number,
      id: PropTypes.string,
      message: PropTypes.string,
    })
  ).isRequired,
  teamChatHistoryHasBeenFetched: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function BurnerTeamChatSection({
  teamChatId,
  fetchTeamChatRequest,
  isFetching,
  sendTeamChatRequest,
  teamChatHistory,
  teamChatHistoryHasBeenFetched,
  userInfo,
}) {
  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Burner Team Chat
      </Typography>
      <Typography className="text" variant="body2">
        Send Burner Team Message - You Must Get Burner Team Chat History First!
      </Typography>
      <Messages
        handleSubmit={sendTeamChatRequest}
        isFetching={isFetching}
        teamChatHistoryHasBeenFetched={teamChatHistoryHasBeenFetched}
        teamChatId={teamChatId}
        userInfo={userInfo}
      />
      <BurnerTeamChatHistory
        handleSubmit={fetchTeamChatRequest}
        isFetching={isFetching}
        teamChatHistory={teamChatHistory}
        userInfo={userInfo}
      />
    </Grid>
  );
}

BurnerTeamChatSection.propTypes = propTypes;

export default BurnerTeamChatSection;

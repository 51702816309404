import React from 'react';
import { Router } from '@reach/router';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import AccountsToDeleteContainer from 'containers/AccountsTeDeleteContainer';
import BlacklistUserContainer from 'containers/BlacklistUserContainer';
import ClearRateLimitsContainer from 'containers/ClearRateLimitsContainer';
import HostedMediaContainer from 'containers/HostedMediaContainer';
import LogContextContainer from 'containers/LogContextContainer';
import NumberCacheAnalyticsContainer from 'containers/NumberCacheAnalyticsContainer';
import NumberLookupPageContainer from 'containers/NumberLookupPageContainer';
import PortOutPinContainer from 'containers/PortOutPinContainer';
import ProvisionNumberContainer from 'containers/ProvisionNumberContainer';
import ReplaceNumberContainer from 'containers/ReplaceNumberContainer';
import TelephonyExtrasContainer from 'containers/TelephonyExtrasContainer';
import ViewBurnerContainer from 'containers/ViewBurnerContainer';
import ViewLegalContainer from 'containers/ViewLegalContainer';
import ViewUserContainer from 'containers/ViewUserContainer';
import ViewExperimentsContainer from 'containers/ViewExperimentsContainer';
import ErrorBoundary from 'components/ErrorBoundary';
import Home from 'components/pages/Home';
import Layout from 'components/Layout';
import Loading from 'components/Loading';
import PrivateRoute from 'components/PrivateRoute';
import 'App.css';

function App() {
  const { loading } = useAuth0();

  return loading ? (
    <Layout>
      <Loading />
    </Layout>
  ) : (
    <Layout>
      <ErrorBoundary>
        <Router>
          <Home path="/" />
          <PrivateRoute
            component={NumberLookupPageContainer}
            path="/dashboard/number-lookup"
          />
          <PrivateRoute
            component={ViewUserContainer}
            path="/dashboard/view-user"
          />
          <PrivateRoute
            component={ViewUserContainer}
            path="/dashboard/view-user/:userIdentifierParam"
          />
          <PrivateRoute
            component={ViewBurnerContainer}
            path="/dashboard/view-burner"
          />
          <PrivateRoute
            component={ViewBurnerContainer}
            path="/dashboard/view-burner/:burnerIdParam"
          />
          <PrivateRoute
            component={ReplaceNumberContainer}
            path="/dashboard/replace-number"
          />
          <PrivateRoute
            component={ProvisionNumberContainer}
            path="/dashboard/provision-number"
          />
          <PrivateRoute
            component={ClearRateLimitsContainer}
            path="/dashboard/clear-rate-limits"
          />
          <PrivateRoute
            component={BlacklistUserContainer}
            path="/dashboard/blacklist-user"
          />
          <PrivateRoute
            component={NumberCacheAnalyticsContainer}
            path="/dashboard/number-cache-analytics"
          />
          <PrivateRoute
            component={LogContextContainer}
            path="/dashboard/log-context"
          />
          <PrivateRoute
            component={HostedMediaContainer}
            path="/dashboard/hosted-media"
          />
          <PrivateRoute
            component={PortOutPinContainer}
            path="/dashboard/port-out-pin"
          />
          <PrivateRoute
            component={ViewLegalContainer}
            path="/dashboard/view-legal"
          />
          <PrivateRoute
            component={ViewLegalContainer}
            path="/dashboard/view-legal/:countryParam/:phoneNumberParam"
          />
          <PrivateRoute
            component={AccountsToDeleteContainer}
            path="/dashboard/accounts-to-delete"
          />
          <PrivateRoute
            component={TelephonyExtrasContainer}
            path="/dashboard/telephony-extras"
          />
          <PrivateRoute
            component={ViewExperimentsContainer}
            path="/dashboard/view-experiments"
          />
        </Router>
      </ErrorBoundary>
    </Layout>
  );
}

export default App;

import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { iif, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { formatE164IfValidPhoneNumber, isEmail } from 'utils/general';
import { enrichBlacklistInfo, enrichUserInfo } from 'utils/blacklist-user';

/*
Actions
*/
export const BLACKLIST_USER_FAILURE =
  'dashboard/blacklistUser/BLACKLIST_USER_FAILURE';
export const BLACKLIST_USER_REQUEST =
  'dashboard/blacklistUser/BLACKLIST_USER_REQUEST';
export const GET_USER_INFO_FAILURE =
  'dashboard/blacklistUser/GET_USER_INFO_FAILURE';
export const GET_USER_INFO_REQUEST =
  'dashboard/blacklistUser/GET_USER_INFO_REQUEST';
export const GET_USER_BLACKLIST_INFO_FAILURE =
  'dashboard/blacklistUser/GET_USER_BLACKLIST_INFO_FAILURE';
export const GET_USER_BLACKLIST_INFO_REQUEST =
  'dashboard/blacklistUser/GET_USER_BLACKLIST_INFO_REQUEST';
export const GET_USER_BLACKLIST_INFO_SUCCESS =
  'dashboard/blacklistUser/GET_USER_BLACKLIST_INFO_SUCCESS';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/blacklistUser/HANDLE_CLOSE_SNACKBAR';
export const SET_BLACKLIST_USER_DIALOG_IS_OPEN =
  'dashboard/blacklistUser/SET_BLACKLIST_USER_DIALOG_IS_OPEN';
export const UPDATE_BLACKLIST_FAILURE =
  'dashboard/blacklistUser/UPDATE_BLACKLIST_FAILURE';
export const UPDATE_BLACKLIST_REQUEST =
  'dashboard/blacklistUser/UPDATE_BLACKLIST_REQUEST';
export const UPDATE_BLACKLIST_SUCCESS =
  'dashboard/blacklistUser/UPDATE_BLACKLIST_SUCCESS';

/*
Action Creators
*/
export function blacklistUserFailure(payload) {
  return { type: BLACKLIST_USER_FAILURE, payload };
}

export function blacklistUserRequest(payload) {
  return { type: BLACKLIST_USER_REQUEST, payload };
}

export function getUserBlacklistInfoFailure(payload) {
  return { type: GET_USER_BLACKLIST_INFO_FAILURE, payload };
}

export function getUserBlacklistInfoRequest(payload) {
  return { type: GET_USER_BLACKLIST_INFO_REQUEST, payload };
}

export function getUserBlacklistInfoSuccess(payload) {
  return { type: GET_USER_BLACKLIST_INFO_SUCCESS, payload };
}

export function getUserInfoFailure(payload) {
  return { type: GET_USER_INFO_FAILURE, payload };
}

export function getUserInfoRequest(payload) {
  return { type: GET_USER_INFO_REQUEST, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

export function setBlacklistUserDialogIsOpen(payload) {
  return { type: SET_BLACKLIST_USER_DIALOG_IS_OPEN, payload };
}

export function updateBlacklistFailure(payload) {
  return { type: UPDATE_BLACKLIST_FAILURE, payload };
}

export function updateBlacklistRequest(payload) {
  return { type: UPDATE_BLACKLIST_REQUEST, payload };
}

export function updateBlacklistSuccess(payload) {
  return { type: UPDATE_BLACKLIST_SUCCESS, payload };
}

/*
Reducer
*/
const initialState = {
  blacklistInfo: {},
  blacklistUserDialogIsOpen: false,
  isFetching: false,
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
  userInfo: {},
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case BLACKLIST_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case BLACKLIST_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USER_BLACKLIST_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case GET_USER_BLACKLIST_INFO_SUCCESS:
      return {
        ...state,
        blacklistInfo: payload.blacklistInfo,
        isFetching: false,
        userInfo: {
          countryCode: payload.userInfo.countryCode,
          createdSample: payload.userInfo.createdSample,
          credits: payload.userInfo.credits,
          dateCreated: payload.userInfo.dateCreated,
          email: payload.userInfo.email,
          firebaseId: payload.userInfo.firebaseId,
          id: payload.userInfo.id,
          lastUpdatedDate: payload.userInfo.lastUpdatedDate,
          identityProviders: payload.userInfo.identityProviders,
          phoneNumber: payload.userInfo.phoneNumber,
          platformName: payload.userInfo.platform.name,
          totalNumberBurners: payload.userInfo.totalNumberBurners,
          rtdbShard: payload.userInfo.rtdbShard,
        },
      };
    case GET_USER_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case GET_USER_INFO_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: { isError: false, isOpen: false, text: '' },
      };
    case SET_BLACKLIST_USER_DIALOG_IS_OPEN:
      return {
        ...state,
        blacklistUserDialogIsOpen: payload,
      };
    case UPDATE_BLACKLIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case UPDATE_BLACKLIST_SUCCESS:
      return {
        ...state,
        blacklistInfo: payload.blacklistInfo,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        userInfo: {
          countryCode: payload.userInfo.countryCode,
          createdSample: payload.userInfo.createdSample,
          credits: payload.userInfo.credits,
          dateCreated: payload.userInfo.dateCreated,
          email: payload.userInfo.email,
          firebaseId: payload.userInfo.firebaseId,
          id: payload.userInfo.id,
          lastUpdatedDate: payload.userInfo.lastUpdatedDate,
          identityProviders: payload.userInfo.identityProviders,
          phoneNumber: payload.userInfo.phoneNumber,
          platformName: payload.userInfo.platform.name,
          totalNumberBurners: payload.userInfo.totalNumberBurners,
          rtdbShard: payload.userInfo.rtdbShard,
        },
      };
    default:
      return state;
  }
}

/*
Epics
*/
const getUserBlacklistInfo = (action) => {
  const { token, userInfo } = action.payload;
  const { id } = userInfo;
  return ajax({
    url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/blacklist`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};
export const getUserInfoEpic = (action$) => {
  const getUserInfo = (action) => {
    const { country, userIdentifier, token } = action.payload;
    const formattedNumber = formatE164IfValidPhoneNumber(
      userIdentifier,
      country
    );

    const getUserUrl = formattedNumber
      ? `${
          process.env.REACT_APP_BASE_URL
        }/admin/users?phoneNumber=${encodeURIComponent(formattedNumber)}`
      : isEmail(userIdentifier)
      ? `${
          process.env.REACT_APP_BASE_URL
        }/admin/users?email=${encodeURIComponent(userIdentifier)}`
      : `${process.env.REACT_APP_BASE_URL}/admin/users/${userIdentifier}`;

    return ajax({
      url: getUserUrl,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(GET_USER_INFO_REQUEST),
    mergeMap((action) =>
      getUserInfo(action).pipe(
        map((response) =>
          getUserBlacklistInfoRequest({
            ...action.payload,
            userInfo: response.response,
          })
        ),
        catchError((error) =>
          of(
            getUserInfoFailure(
              error.response && error.response.error
                ? error.response.error
                : "Can't find user."
            )
          )
        )
      )
    )
  );
};

export const getUserBlacklistInfoEpic = (action$) => {
  return action$.pipe(
    ofType(GET_USER_BLACKLIST_INFO_REQUEST),
    mergeMap((action) =>
      getUserBlacklistInfo(action).pipe(
        map((response) =>
          getUserBlacklistInfoSuccess({
            blacklistInfo: response.response,
            userInfo: action.payload.userInfo,
          })
        ),
        catchError((error) =>
          // If server returns a 404, user is not blacklisted so return success
          iif(
            () => error.status && error.status === 404,
            of(
              getUserBlacklistInfoSuccess({
                blacklistInfo: {},
                userInfo: action.payload.userInfo,
              })
            ),
            of(
              getUserBlacklistInfoFailure(
                error.response && error.response.error
                  ? error.response.error
                  : "Can't check if user is blacklisted"
              )
            )
          )
        )
      )
    )
  );
};

export const blacklistUserEpic = (action$) => {
  const blacklistUser = (action) => {
    const { body, id, isLegalRequest, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/blacklist`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        legal: isLegalRequest,
        reason: body,
      },
    });
  };

  return action$.pipe(
    ofType(BLACKLIST_USER_REQUEST),
    mergeMap((action) =>
      blacklistUser(action).pipe(
        map((response) =>
          updateBlacklistRequest({
            ...action.payload,
            userInfo: response.response,
          })
        ),
        catchError((error) =>
          of(
            blacklistUserFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Something went wrong'
            )
          )
        )
      )
    )
  );
};

export const updateBlacklistInfoEpic = (action$) => {
  return action$.pipe(
    ofType(UPDATE_BLACKLIST_REQUEST),
    mergeMap((action) =>
      getUserBlacklistInfo(action).pipe(
        map((response) =>
          updateBlacklistSuccess({
            blacklistInfo: response.response,
            message: 'Blacklist status successfully updated',
            userInfo: action.payload.userInfo,
          })
        ),
        catchError((error) =>
          // If server returns a 404, user is not blacklisted so return success
          iif(
            () => error.status && error.status === 404,
            of(
              updateBlacklistSuccess({
                blacklistInfo: {},
                message: 'Blacklist status successfully updated',
                userInfo: action.payload.userInfo,
              })
            ),
            of(
              updateBlacklistFailure(
                error.response && error.response.error
                  ? error.response.error
                  : "Can't fetch blacklisted status"
              )
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getBlacklistInfo = createSelector(
  (state) => state.blacklistUser.blacklistInfo,
  (blacklistInfo) => enrichBlacklistInfo(blacklistInfo)
);

export const getUserInfo = createSelector(
  (state) => state.blacklistUser.userInfo,
  (userInfo) => enrichUserInfo(userInfo)
);

import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const propTypes = {
  history: PropTypes.object.isRequired,
};

function NumberHistory({ history }) {
  const { previouslyOwned } = history;
  return (
    <Grid>
      <Typography gutterBottom variant="h5">
        Number History
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Previously Owned</TableCell>
            <TableCell>{previouslyOwned}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}

NumberHistory.propTypes = propTypes;

export default NumberHistory;

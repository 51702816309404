import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ActiveBurners from 'components/pages/view-user-info/burner-details/ActiveBurners';
import PreviouslyOwnedPhoneNumbers from 'components/pages/view-user-info/burner-details/PreviouslyOwnedPhoneNumbers';

const propTypes = {
  burners: PropTypes.arrayOf(
    PropTypes.shape({
      dates: PropTypes.shape({
        dateCreated: PropTypes.string,
        expirationDate: PropTypes.string,
      }),
      id: PropTypes.string,
      name: PropTypes.string,
      phoneNumberId: PropTypes.string,
      remainingMinutes: PropTypes.number,
      remainingTexts: PropTypes.number,
    })
  ).isRequired,
  previouslyOwnedPhoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      dateCreated: PropTypes.string,
      dateReleased: PropTypes.string,
      phoneNumber: PropTypes.string,
    })
  ),
};

function BurnerDetailsSection({ burners, previouslyOwnedPhoneNumbers }) {
  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Burner Details
      </Typography>
      <ActiveBurners burners={burners} />
      <PreviouslyOwnedPhoneNumbers
        previouslyOwnedPhoneNumbers={previouslyOwnedPhoneNumbers}
      />
    </Grid>
  );
}

BurnerDetailsSection.propTypes = propTypes;

export default BurnerDetailsSection;

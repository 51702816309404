import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { forkJoin, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { WE_HAVE_NEVER_OWNED_THIS_NUMBER } from 'utils/constants';

/*
Actions
*/
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/telephonyExtras/HANDLE_CLOSE_SNACKBAR';
export const LOOKUP_TELEPHONY_PHONE_NUMBER_FAILURE =
  'dashboard/telephonyExtras/LOOKUP_TELEPHONY_PHONE_NUMBER_FAILURE';
export const LOOKUP_TELEPHONY_PHONE_NUMBER_REQUEST =
  'dashboard/telephonyExtras/LOOKUP_TELEPHONY_PHONE_NUMBER_REQUEST';
export const LOOKUP_TELEPHONY_PHONE_NUMBER_SUCCESS =
  'dashboard/telephonyExtras/LOOKUP_TELEPHONY_PHONE_NUMBER_SUCCESS';
export const SEND_TELEPHONY_MESSAGE_FAILURE =
  'dashboard/telephonyExtras/SEND_TELEPHONY_MESSAGE_FAILURE';
export const SEND_TELEPHONY_MESSAGE_REQUEST =
  'dashboard/telephonyExtras/SEND_TELEPHONY_MESSAGE_REQUEST';
export const SEND_TELEPHONY_MESSAGE_SUCCESS =
  'dashboard/telephonyExtras/SEND_TELEPHONY_MESSAGE_SUCCESS';

/*
Action Creators
*/
export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

export function lookupTelephonyPhoneNumberFailure(payload) {
  return { type: LOOKUP_TELEPHONY_PHONE_NUMBER_FAILURE, payload };
}

export function lookupTelephonyPhoneNumberRequest(payload) {
  return { type: LOOKUP_TELEPHONY_PHONE_NUMBER_REQUEST, payload };
}

export function lookupTelephonyPhoneNumberSuccess(payload) {
  return { type: LOOKUP_TELEPHONY_PHONE_NUMBER_SUCCESS, payload };
}

export function sendTelephonyMessageFailure(payload) {
  return { type: SEND_TELEPHONY_MESSAGE_FAILURE, payload };
}

export function sendTelephonyMessageRequest(payload) {
  return { type: SEND_TELEPHONY_MESSAGE_REQUEST, payload };
}

export function sendTelephonyMessageSuccess(payload) {
  return { type: SEND_TELEPHONY_MESSAGE_SUCCESS, payload };
}

/*
Reducer
*/
const initialState = {
  data: {},
  isFetching: false,
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: initialState.message,
      };
    case LOOKUP_TELEPHONY_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case LOOKUP_TELEPHONY_PHONE_NUMBER_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case LOOKUP_TELEPHONY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        data: {
          createdAt: payload.phoneNumber.response.createdAt,
          externalId: payload.phoneNumber.response.externalId,
          features: payload.phoneNumber.response.features.toString(),
          id: payload.phoneNumber.response.id,
          providerName: payload.phoneNumber.response.providerName,
          providerSpecificDetails: JSON.stringify(
            payload.phoneNumber.response.providerSpecificDetails
          ),
          status: payload.phoneNumber.response.status,
        },
        isFetching: false,
      };
    case SEND_TELEPHONY_MESSAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case SEND_TELEPHONY_MESSAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SEND_TELEPHONY_MESSAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload },
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const lookupTelephonyPhoneNumberEpic = (action$) => {
  const lookupTelephonyPhoneNumber = (action) => {
    const { phoneNumber, token } = action.payload;
    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/telephony/phone-numbers/${encodeURIComponent(
        phoneNumber
      )}/provider-info`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(LOOKUP_TELEPHONY_PHONE_NUMBER_REQUEST),
    mergeMap((action) =>
      forkJoin({
        phoneNumber: lookupTelephonyPhoneNumber(action),
      }).pipe(
        map((response) => lookupTelephonyPhoneNumberSuccess(response)),
        catchError((error) =>
          of(
            lookupTelephonyPhoneNumberFailure(
              error.response && error.response.message
                ? error.response.message
                : WE_HAVE_NEVER_OWNED_THIS_NUMBER
            )
          )
        )
      )
    )
  );
};

export const sendTelephonyMessageEpic = (action$) => {
  const sendTelephonyMessageToPhoneNumber = (action) => {
    const { message, phoneNumber, recipient, token } = action.payload;
    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/telephony/phone-numbers/${encodeURIComponent(
        phoneNumber
      )}/send-message`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        to: recipient,
        message: message,
        imageUrl:
          'https://s3-us-west-2.amazonaws.com/burner-public/logo/logo-burner.png',
      },
    });
  };

  return action$.pipe(
    ofType(SEND_TELEPHONY_MESSAGE_REQUEST),
    mergeMap((action) =>
      sendTelephonyMessageToPhoneNumber(action).pipe(
        map(() => sendTelephonyMessageSuccess('Message successfully sent!')),
        catchError((error) =>
          of(
            sendTelephonyMessageFailure(
              error.response && error.response.message
                ? error.response.message
                : 'Message could not be delivered!'
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getTelephonyPhoneNumberData = createSelector(
  (state) => state.telephonyExtras.data,
  (phoneData) => phoneData
);

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MessagingService from 'components/MessagingService';
import View from 'components/View';
import UserSearch from 'components/pages/UserSearch';
import AccountControlsContainer from 'containers/AccountControlsContainer';
import BlockedNumbersContainer from 'containers/BlockedNumbersContainer';
import BurnerDetailsContainer from 'containers/BurnerDetailsContainer';
import BurnerTeamChatContainer from 'containers/BurnerTeamChatContainer';
import DetailsContainer from 'containers/DetailsContainer';
import InternalNotesContainer from 'containers/InternalNotesContainer';
import SubscriptionDetailsContainer from 'containers/SubscriptionDetailsContainer';
import TransactionsContainer from 'containers/TransactionsContainer';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';
import UserDeletionContainer from 'containers/UserDeletionContainer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const propTypes = {
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  searchUserRequest: PropTypes.func.isRequired,
  userIdentifierParam: PropTypes.string,
  userInfo: PropTypes.object.isRequired,
};

function ViewUser({
  handleCloseSnackbar,
  isFetching,
  message,
  searchUserRequest,
  userIdentifierParam = null,
  userInfo,
}) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('viewUser')) ? (
    <View>
      <Grid item xs={12}>
        <Typography
          component="h1"
          data-testid="title-test"
          gutterBottom
          variant="h4"
        >
          View User Information
        </Typography>
        <Divider />
        <UserSearch
          handleSubmit={searchUserRequest}
          isFetching={isFetching}
          userIdentifierParam={userIdentifierParam}
        />
      </Grid>
      {!isEmpty(userInfo) && (
        <>
          <UserDeletionContainer />
          <DetailsContainer />
          <AccountControlsContainer />
          <SubscriptionDetailsContainer />
          <BurnerDetailsContainer />
          <TransactionsContainer />
          <BlockedNumbersContainer />
          <InternalNotesContainer />
          <BurnerTeamChatContainer />
        </>
      )}

      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

ViewUser.propTypes = propTypes;

export default ViewUser;

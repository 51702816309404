import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import SaveIcon from '@mui/icons-material/Save';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  subscriptionToEdit: PropTypes.shape({
    burnerIds: PropTypes.arrayOf(PropTypes.string),
    burnersAssignedInPeriod: PropTypes.number,
    id: PropTypes.string,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

function AdjustBurnersAssigned({
  handleOpen,
  handleSubmit,
  isFetching,
  subscriptionToEdit,
}) {
  const { burnerIds, burnersAssignedInPeriod, id } = subscriptionToEdit;
  const { getTokenSilently, logout } = useAuth0();
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6">
        Adjust current Burners assigned already in period.
      </Typography>
      <Formik
        initialValues={{ burnersAssigned: burnersAssignedInPeriod }}
        onSubmit={(values) => {
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                count: values.burnersAssigned,
                subscriptionId: id,
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => handleOpen(false),
          });
        }}
        validate={(values) => {
          const errors = {};
          if (!values.burnersAssigned) {
            errors.burnersAssigned = '* Required';
          } else if (values.burnersAssigned < burnerIds.length) {
            errors.burnersAssigned =
              '* Cannot set a value lower than the number of currently assigned subs.';
          } else if (!/^[0-9]+$/i.test(values.burnersAssigned)) {
            errors.burnersAssigned = '* Value must be an integer.';
          }
          return errors;
        }}
      >
        {() => (
          <Form>
            <Box alignItems="baseline" display="flex" flexWrap="wrap" mb={1}>
              <Field name="burnersAssigned">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    {...field}
                    disabled={isFetching}
                    error={errors.burnersAssigned && touched.burnersAssigned}
                    helperText={
                      errors.burnersAssigned &&
                      touched.burnersAssigned &&
                      errors.burnersAssigned
                    }
                    id="burnersAssigned"
                    InputProps={
                      errors.burnersAssigned &&
                      touched.burnersAssigned && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <ErrorOutline className="error" />
                          </InputAdornment>
                        ),
                      }
                    }
                    label="Burners Assigned"
                    margin="dense"
                    type="number"
                    variant="standard"
                  />
                )}
              </Field>
              <Button
                color="primary"
                disabled={isFetching}
                size="medium"
                className="button"
                type="submit"
              >
                <SaveIcon
                  className={clsx(classes.leftIcon, classes.iconSmall)}
                />
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

AdjustBurnersAssigned.propTypes = propTypes;

export default AdjustBurnersAssigned;

import { connect } from 'react-redux';
import { handleCloseSnackbar } from 'ducks/accounts-to-delete';
import AccountsToDelete from 'components/pages/AccountsToDelete';

const mapStateToProps = (state) => {
  const { message } = state.accountsToDelete;

  return {
    message,
  };
};

const mapDispatchToProps = {
  handleCloseSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsToDelete);

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { navigate } from '@reach/router';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Auth0Provider } from 'utils/react-auth0-wrapper';
import theme from 'utils/theme';
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import createStore from 'store/createStore';

const store = createStore();

const onRedirectCallback = (appState) => {
  navigate(
    appState && appState.targetUrl ? appState.targetUrl : '/dashboard/view-user'
  );
};

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Auth0Provider
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      client_id={process.env.REACT_APP_AUTH0_CLIENTID}
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      onRedirectCallback={onRedirectCallback}
      redirect_uri={window.location.origin}
    >
      {/*This is what allows our css in Layout.useStyles
      to not get overriden by MUI styles. `injectFirst`
       means that the MUI styles come first, and therefore
       our custom stuff can come later and override them.*/}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
      ;
    </Auth0Provider>
  </Provider>
);

serviceWorker.unregister();

import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { transformLegalData } from 'utils/view-legal';

/*
Actions
*/
export const FETCH_LEGAL_FAILURE = 'dashboard/viewLegal/FETCH_LEGAL_FAILURE';
export const FETCH_LEGAL_REQUEST = 'dashboard/viewLegal/FETCH_LEGAL_REQUEST';
export const FETCH_LEGAL_SUCCESS = 'dashboard/viewLegal/FETCH_LEGAL_SUCCESS';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/viewLegal/HANDLE_CLOSE_SNACKBAR';

/*
Action Creators
*/
export function fetchLegalFailure(payload) {
  return { type: FETCH_LEGAL_FAILURE, payload };
}

export function fetchLegalRequest(payload) {
  return { type: FETCH_LEGAL_REQUEST, payload };
}

export function fetchLegalSuccess(payload) {
  return { type: FETCH_LEGAL_SUCCESS, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

/*
Reducer
*/
const initialState = {
  isFetching: false,
  legalData: {},
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case FETCH_LEGAL_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case FETCH_LEGAL_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case FETCH_LEGAL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        legalData: payload,
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: initialState.message,
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const fetchLegalDataEpic = (action$) => {
  const fetchLegalData = (action) => {
    const {
      country,
      phoneNumberOrEmail,
      startDate,
      endDate,
      token,
    } = action.payload;

    const startParam = startDate ? `&startDateMillis=${startDate}` : '';
    const endParam = endDate ? `&endDateMillis=${endDate}` : '';

    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/legal?phoneNumberOrEmail=${encodeURIComponent(
        phoneNumberOrEmail
      )}&country=${country}${startParam}${endParam}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_LEGAL_REQUEST),
    mergeMap((action) =>
      fetchLegalData(action).pipe(
        map((response) =>
          fetchLegalSuccess({
            ...response.response,
            phoneNumberOrEmail: action.payload.phoneNumberOrEmail,
            startDate: action.payload.startDate,
            endDate: action.payload.endDate,
          })
        ),
        catchError((error) =>
          of(
            fetchLegalFailure(
              error.status && (error.status === 400 || error.status === 404)
                ? 'Could not find any burner, phone, or user'
                : 'Something went wrong!'
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getLegalData = createSelector(
  (state) => state.viewLegal.legalData,
  (legalData) => transformLegalData(legalData)
);

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import toNumber from 'lodash/toNumber';
import { from } from 'rxjs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { numberSchema } from 'utils/view-user-info';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function AddCredits({ handleSubmit, isFetching, userInfo }) {
  const initialValues = { credits: '' };
  const { getTokenSilently, logout } = useAuth0();
  const { id } = userInfo;

  return (
    <Grid item sm={12} md={4} className="item">
      <Typography className="heading" gutterBottom variant="h5">
        Add Credits
      </Typography>
      <Divider />
      <Typography className="text" variant="body2">
        The credits value will be added to the user&apos;s current credits
        count. To subtract credits, use a negative value.
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                credits: toNumber(values.credits),
                token,
                userId: id,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => resetForm(initialValues),
          });
        }}
        validationSchema={numberSchema}
      >
        {() => (
          <Form>
            <Box alignItems="baseline" display="flex" flexWrap="wrap" mb={1}>
              <Field name="credits">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    {...field}
                    className="input"
                    disabled={isFetching}
                    error={errors.credits && touched.credits}
                    helperText={
                      errors.credits && touched.credits && errors.credits
                    }
                    id="credits"
                    InputProps={
                      errors.credits &&
                      touched.credits && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <ErrorOutline className="error" />
                          </InputAdornment>
                        ),
                      }
                    }
                    label="Credits To Add"
                    margin="dense"
                    type="number"
                    variant="standard"
                  />
                )}
              </Field>
              <Button
                className="button"
                disabled={isFetching}
                size="medium"
                color="primary"
                type="submit"
                variant="outlined"
              >
                Add Credits
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

AddCredits.propTypes = propTypes;

export default AddCredits;

import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { forkJoin, of, throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { formatE164IfValidPhoneNumber, isEmail } from 'utils/general';
import {
  enrichBurnerInfo,
  enrichPhoneNumberInfo,
  getEnabledFeatures,
  transformBurnerHasSubscription,
  transformNumberHistory,
} from 'utils/view-burner';

/*
Actions
*/
export const BURN_NUMBER_FAILURE = 'dashboard/viewBurner/BURN_NUMBER_FAILURE';
export const BURN_NUMBER_REQUEST = 'dashboard/viewBurner/BURN_NUMBER_REQUEST';
export const BURN_NUMBER_SUCCESS = 'dashboard/viewBurner/BURN_NUMBER_SUCCESS';
export const DELETE_BURNER_DIALOG_OPEN =
  'dashboard/viewBurner/DELETE_BURNER_DIALOG_OPEN';
export const FETCH_BURNER_FAILURE = 'dashboard/viewBurner/FETCH_BURNER_FAILURE';
export const FETCH_BURNER_REQUEST = 'dashboard/viewBurner/FETCH_BURNER_REQUEST';
export const FETCH_BURNER_SUCCESS = 'dashboard/viewBurner/FETCH_BURNER_SUCCESS';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/viewBurner/HANDLE_CLOSE_SNACKBAR';
export const HANDLE_NOTIFICATIONS_CHANGE =
  'dashboard/viewBurner/HANDLE_NOTIFICATIONS_CHANGE';
export const HANDLE_RINGER_CHANGE = 'dashboard/viewBurner/HANDLE_RINGER_CHANGE';
export const HANDLE_SIP_CHANGE = 'dashboard/viewBurner/HANDLE_SIP_CHANGE';
export const REMOVE_BURNER_DIALOG_OPEN =
  'dashboard/viewBurner/REMOVE_BURNER_DIALOG_OPEN';
export const REMOVE_BURNER_FAILURE =
  'dashboard/viewBurner/REMOVE_BURNER_FAILURE';
export const REMOVE_BURNER_REQUEST =
  'dashboard/viewBurner/REMOVE_BURNER_REQUEST';
export const SEARCH_BURNERS_FAILURE =
  'dashboard/viewBurner/SEARCH_BURNERS_FAILURE';
export const SEARCH_BURNERS_REQUEST =
  'dashboard/viewBurner/SEARCH_BURNERS_REQUEST';
export const SEARCH_NUMBER_HISTORY_SUCCESS =
  'dashboard/viewBurner/SEARCH_NUMBER_HISTORY_SUCCESS';
export const SEARCH_NUMBER_HISTORY_FAILURE =
  'dashboard/viewBurner/SEARCH_NUMBER_HISTORY_FAILURE';
export const TRANSFER_DIALOG_OPEN = 'dashboard/viewBurner/TRANSFER_DIALOG_OPEN';
export const TRANSFER_OWNERSHIP_FAILURE =
  'dashboard/viewBurner/TRANSFER_OWNERSHIP_FAILURE';
export const TRANSFER_OWNERSHIP_REQUEST =
  'dashboard/viewBurner/TRANSFER_OWNERSHIP_REQUEST';
export const UPDATE_BURNER_FAILURE =
  'dashboard/viewBurner/UPDATE_BURNER_FAILURE';
export const UPDATE_BURNER_REQUEST =
  'dashboard/viewBurner/UPDATE_BURNER_REQUEST';
export const UPDATE_BURNER_SUCCESS =
  'dashboard/viewBurner/UPDATE_BURNER_SUCCESS';
export const UPDATE_SETTINGS_FAILURE =
  'dashboard/viewBurner/UPDATE_SETTINGS_FAILURE';
export const UPDATE_SETTINGS_REQUEST =
  'dashboard/viewBurner/UPDATE_SETTINGS_REQUEST';
export const UPDATE_SUBSCRIPTION_FAILURE =
  'dashboard/viewBurner/UPDATE_SUBSCRIPTION_FAILURE';
export const UPDATE_SUBSCRIPTION_REQUEST =
  'dashboard/viewBurner/UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS =
  'dashboard/viewBurner/UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_BURNER_FEATURE_SET_REQUEST =
  'dashboard/viewBurner/UPDATE_BURNER_FEATURE_SET_REQUEST';

/*
Action Creators
*/
export function burnNumberFailure(payload) {
  return { type: BURN_NUMBER_FAILURE, payload };
}

export function burnNumberRequest(payload) {
  return { type: BURN_NUMBER_REQUEST, payload };
}

export function burnNumberSuccess(payload) {
  return { type: BURN_NUMBER_SUCCESS, payload };
}

export function deleteBurnerDialogOpen(payload) {
  return { type: DELETE_BURNER_DIALOG_OPEN, payload };
}

export function fetchBurnerFailure(payload) {
  return { type: FETCH_BURNER_FAILURE, payload };
}

export function fetchBurnerRequest(payload) {
  return { type: FETCH_BURNER_REQUEST, payload };
}

export function fetchBurnerSuccess(payload) {
  return { type: FETCH_BURNER_SUCCESS, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

export function handleNotificationsChange(payload) {
  return { type: HANDLE_NOTIFICATIONS_CHANGE, payload };
}

export function handleRingerChange(payload) {
  return { type: HANDLE_RINGER_CHANGE, payload };
}

export function handleSipChange(payload) {
  return { type: HANDLE_SIP_CHANGE, payload };
}

export function removeBurnerDialogOpen(payload) {
  return { type: REMOVE_BURNER_DIALOG_OPEN, payload };
}

export function removeBurnerFailure(payload) {
  return { type: REMOVE_BURNER_FAILURE, payload };
}

export function removeBurnerRequest(payload) {
  return { type: REMOVE_BURNER_REQUEST, payload };
}

export function searchBurnersFailure(payload) {
  return { type: SEARCH_BURNERS_FAILURE, payload };
}

export function searchBurnersRequest(payload) {
  return { type: SEARCH_BURNERS_REQUEST, payload };
}

export function searchNumberHistoryStatusSuccess(payload) {
  return { type: SEARCH_NUMBER_HISTORY_SUCCESS, payload };
}

export function searchNumberHistoryStatusFailure() {
  return { type: SEARCH_NUMBER_HISTORY_FAILURE };
}

export function transferDialogOpen(payload) {
  return { type: TRANSFER_DIALOG_OPEN, payload };
}

export function transferOwnershipFailure(payload) {
  return { type: TRANSFER_OWNERSHIP_FAILURE, payload };
}

export function transferOwnershipRequest(payload) {
  return { type: TRANSFER_OWNERSHIP_REQUEST, payload };
}

export function updateBurnerFailure(payload) {
  return { type: UPDATE_BURNER_FAILURE, payload };
}

export function updateBurnerRequest(payload) {
  return { type: UPDATE_BURNER_REQUEST, payload };
}

export function updateBurnerSuccess(payload) {
  return { type: UPDATE_BURNER_SUCCESS, payload };
}

export function updateSettingsFailure(payload) {
  return { type: UPDATE_SETTINGS_FAILURE, payload };
}

export function updateSettingsRequest(payload) {
  return { type: UPDATE_SETTINGS_REQUEST, payload };
}

export function updateSubscriptionFailure(payload) {
  return { type: UPDATE_SUBSCRIPTION_FAILURE, payload };
}

export function updateSubscriptionRequest(payload) {
  return { type: UPDATE_SUBSCRIPTION_REQUEST, payload };
}

export function updateSubscriptionSuccess(payload) {
  return { type: UPDATE_SUBSCRIPTION_SUCCESS, payload };
}

export function updateBurnerFeatureSetRequest(payload) {
  return { type: UPDATE_BURNER_FEATURE_SET_REQUEST, payload };
}

/*
Reducer
*/
const initialState = {
  burnerInfo: {},
  deleteBurnerDialogIsOpen: false,
  phoneNumberInfo: {},
  isFetching: false,
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
  numberHistory: [],
  removeBurnerDialogIsOpen: false,
  subscription: {},
  transferDialogIsOpen: false,
  userInfo: {},
  burnerFeatureSets: [],
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case BURN_NUMBER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case BURN_NUMBER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case BURN_NUMBER_SUCCESS:
      return {
        ...initialState,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload },
      };
    case DELETE_BURNER_DIALOG_OPEN:
      return {
        ...state,
        deleteBurnerDialogIsOpen: payload,
      };
    case TRANSFER_DIALOG_OPEN:
      return {
        ...state,
        transferDialogIsOpen: payload,
      };
    case FETCH_BURNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case FETCH_BURNER_SUCCESS:
      return {
        ...state,
        burnerInfo: {
          ...payload.burnerInfo,
        },
        isFetching: false,
        phoneNumberInfo: {
          serviceProvider:
            payload.phoneNumberInfo.response.serviceProvider.name,
        },
        subscription: payload.subscription.response
          .map((sub) => ({
            burnerIds: sub.burnerIds,
            id: sub.id,
            sku: sub.sku,
          }))
          .find((sub) => sub.burnerIds.includes(payload.burnerInfo.id)),
        userInfo: { phoneNumber: payload.userInfo.response.phoneNumber },
        burnerFeatureSets: payload.burnerFeatureSets,
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: initialState.message,
      };
    case HANDLE_NOTIFICATIONS_CHANGE:
      return {
        ...state,
        burnerInfo: { ...state.burnerInfo, notifications: payload },
      };
    case HANDLE_RINGER_CHANGE:
      return {
        ...state,
        burnerInfo: { ...state.burnerInfo, ringer: payload },
      };
    case HANDLE_SIP_CHANGE:
      return {
        ...state,
        burnerInfo: { ...state.burnerInfo, useSip: payload },
      };
    case REMOVE_BURNER_DIALOG_OPEN:
      return {
        ...state,
        removeBurnerDialogIsOpen: payload,
      };
    case REMOVE_BURNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case REMOVE_BURNER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_BURNERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload.message },
      };
    case SEARCH_BURNERS_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case SEARCH_NUMBER_HISTORY_SUCCESS:
      return {
        ...state,
        numberHistory: payload.response,
      };
    case SEARCH_NUMBER_HISTORY_FAILURE:
      return {
        ...state,
        numberHistory: initialState.numberHistory,
      };
    case TRANSFER_OWNERSHIP_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case TRANSFER_OWNERSHIP_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_BURNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case UPDATE_BURNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        burnerInfo: {
          ...payload.burnerInfo,
        },
      };
    case UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case UPDATE_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        subscription: payload.subscription
          .map((sub) => ({
            burnerIds: sub.burnerIds,
            id: sub.id,
            sku: sub.sku,
          }))
          .find((sub) => sub.burnerIds.includes(state.burnerInfo.id)),
      };
    case UPDATE_BURNER_FEATURE_SET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const searchActiveBurnersEpic = (action$) => {
  const searchActiveBurners = (action) => {
    const { country, phoneNumberOrId, token } = action.payload;
    const formattedNumber = formatE164IfValidPhoneNumber(
      phoneNumberOrId,
      country
    );
    return ajax({
      url: formattedNumber
        ? `${
            process.env.REACT_APP_BASE_URL
          }/admin/burners?phoneNumber=${encodeURIComponent(formattedNumber)}`
        : `${process.env.REACT_APP_BASE_URL}/admin/burners/${phoneNumberOrId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(SEARCH_BURNERS_REQUEST),
    mergeMap((action) =>
      searchActiveBurners(action).pipe(
        map((response) =>
          fetchBurnerRequest({
            burnerInfo: response.response,
            token: action.payload.token,
          })
        ),
        catchError((error) =>
          of(
            searchBurnersFailure({
              ...action.payload,
              message:
                error.response && error.response.error
                  ? error.response.error
                  : 'Something went wrong.',
            })
          )
        )
      )
    )
  );
};

export const searchViewBurnerNumberHistoryEpic = (action$) => {
  const searchNumberHistory = (action) => {
    const { country, phoneNumberOrId, token } = action.payload;
    const formattedNumber = formatE164IfValidPhoneNumber(
      phoneNumberOrId,
      country
    );
    if (formattedNumber) {
      return ajax({
        url: `${
          process.env.REACT_APP_BASE_URL
        }/admin/phone-numbers/${encodeURIComponent(formattedNumber)}/history`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    } else {
      return throwError('Number not found');
    }
  };

  return action$.pipe(
    ofType(SEARCH_BURNERS_FAILURE),
    mergeMap((action) =>
      searchNumberHistory(action).pipe(
        map((response) => searchNumberHistoryStatusSuccess(response)),
        catchError(() => searchNumberHistoryStatusFailure())
      )
    )
  );
};

export const fetchBurnerInfoEpic = (action$) => {
  const getUserInfo = (action) => {
    const { burnerInfo, token } = action.payload;
    const { userId } = burnerInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${userId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getSubscriptions = (action) => {
    const { burnerInfo, token } = action.payload;
    const { userId } = burnerInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${userId}/subscriptions`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const getPhoneNumberInfo = (action) => {
    const { burnerInfo, token } = action.payload;
    const { phoneNumberId } = burnerInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/phone-numbers/${phoneNumberId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const fetchBurnerFeatureSets = (action) => {
    const { token } = action.payload;

    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/burner-feature-set`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_BURNER_REQUEST),
    mergeMap((action) =>
      forkJoin({
        userInfo: getUserInfo(action).pipe(
          catchError((error) => throwError(error))
        ),
        subscription: getSubscriptions(action).pipe(
          catchError((error) => throwError(error))
        ),
        phoneNumberInfo: getPhoneNumberInfo(action).pipe(
          catchError((error) => throwError(error))
        ),
        burnerFeatureSets: fetchBurnerFeatureSets(action).pipe(
          map(({ response }) => response),
          catchError((error) => throwError(error))
        ),
      }).pipe(
        map((response) =>
          fetchBurnerSuccess({
            ...response,
            burnerInfo: action.payload.burnerInfo,
          })
        ),
        catchError((error) =>
          of(
            fetchBurnerFailure(
              error.response && error.response.error
                ? error.response.error
                : 'There was a problem retrieving Burner'
            )
          )
        )
      )
    )
  );
};

export const updateBurnerSettingsEpic = (action$) => {
  const updateBurnerSettings = (action) => {
    const { settingsObject, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/burners/${settingsObject.id}`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        ...settingsObject,
        // this endpoint enables any feature sent to it, even if it is marked as disabled
        // so we must only send enabled features
        features: getEnabledFeatures(settingsObject.features),
      },
    });
  };

  return action$.pipe(
    ofType(UPDATE_SETTINGS_REQUEST),
    mergeMap((action) =>
      updateBurnerSettings(action).pipe(
        map(() =>
          updateBurnerRequest({
            ...action.payload,
          })
        ),
        catchError((error) =>
          of(
            updateSettingsFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error updating settings.'
            )
          )
        )
      )
    )
  );
};

export const updateBurnerFeatureSetRequestEpic = (action$) => {
  const update = (action) => {
    const { burnerInfo, token, featureSet } = action.payload;

    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/burners/${burnerInfo.id}/feature-set`,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        featureSetId: featureSet.id,
      },
    });
  };

  return action$.pipe(
    ofType(UPDATE_BURNER_FEATURE_SET_REQUEST),
    mergeMap((action) =>
      update(action).pipe(
        map((_) => {
          let { payload } = action;
          let { burnerInfo, token } = payload;

          return updateBurnerRequest({
            settingsObject: burnerInfo,
            token,
          });
        }),
        catchError((error) =>
          of(
            updateBurnerFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error updating Burner.'
            )
          )
        )
      )
    )
  );
};

export const updateBurnerInfoEpic = (action$) => {
  const updateBurnerInfo = (action) => {
    const { settingsObject, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/burners/${settingsObject.id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(UPDATE_BURNER_REQUEST),
    mergeMap((action) =>
      updateBurnerInfo(action).pipe(
        map((response) =>
          updateBurnerSuccess({
            message: 'Burner updated.',
            burnerInfo: response.response,
          })
        ),
        catchError((error) =>
          of(
            updateBurnerFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error updating Burner.'
            )
          )
        )
      )
    )
  );
};

export const removeBurnerEpic = (action$) => {
  const removeBurnerFromSubscription = (action) => {
    const { burnerId, subscriptionId, token, userId } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/burners/${burnerId}/subscription?subscriptionId=${subscriptionId}&userId=${userId}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(REMOVE_BURNER_REQUEST),
    mergeMap((action) =>
      removeBurnerFromSubscription(action).pipe(
        map(() =>
          updateSubscriptionRequest({
            ...action.payload,
          })
        ),
        catchError((error) =>
          of(
            removeBurnerFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error removing Burner.'
            )
          )
        )
      )
    )
  );
};

export const updateSubscriptionEpic = (action$) => {
  const updateSubscriptions = (action) => {
    const { token, userId } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${userId}/subscriptions`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(UPDATE_SUBSCRIPTION_REQUEST),
    mergeMap((action) =>
      updateSubscriptions(action).pipe(
        map((response) =>
          updateSubscriptionSuccess({
            message: 'Burner removed from subscription.',
            subscription: response.response,
          })
        ),
        catchError((error) =>
          of(
            updateSubscriptionFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error updating subscription.'
            )
          )
        )
      )
    )
  );
};

export const burnNumberEpic = (action$) => {
  const burnNumber = (action) => {
    const { id, token, userId } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${userId}/burners/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(BURN_NUMBER_REQUEST),
    mergeMap((action) =>
      burnNumber(action).pipe(
        map(() => burnNumberSuccess('Burner burned.')),
        catchError((error) =>
          of(
            burnNumberFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error burning Burner.'
            )
          )
        )
      )
    )
  );
};

export const transferOwnershipEpic = (action$) => {
  const transferOwnership = (action) => {
    const { country, userIdentifier, settingsObject, token } = action.payload;
    const { id, userId } = settingsObject;
    const formattedNumber = formatE164IfValidPhoneNumber(
      userIdentifier,
      country
    );
    const userIdentifierQueryParam = formattedNumber
      ? `phoneNumber=${encodeURIComponent(formattedNumber)}`
      : isEmail(userIdentifier)
      ? `email=${encodeURIComponent(userIdentifier)}`
      : `userId=${userIdentifier}`;

    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${userId}/burners/${id}/transfer?${userIdentifierQueryParam}`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(TRANSFER_OWNERSHIP_REQUEST),
    mergeMap((action) =>
      transferOwnership(action).pipe(
        map(() =>
          updateBurnerRequest({
            ...action.payload,
          })
        ),
        catchError((error) =>
          of(
            transferOwnershipFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Something went wrong while trying to transfer burner.'
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getBurnerHasSubscription = createSelector(
  (state) => state.viewBurner.burnerInfo,
  (state) => state.viewBurner.subscription,
  (burnerInfo, subscription) =>
    transformBurnerHasSubscription(burnerInfo, subscription)
);

export const getBurnerInfo = createSelector(
  (state) => state.viewBurner.burnerInfo,
  (burnerInfo) => enrichBurnerInfo(burnerInfo)
);

export const getPhoneNumberInfo = createSelector(
  (state) => state.viewBurner.phoneNumberInfo,
  (phoneNumberInfo) => enrichPhoneNumberInfo(phoneNumberInfo)
);

export const getNumberHistory = createSelector(
  (state) => state.viewBurner.numberHistory,
  (numberHistory) => transformNumberHistory(numberHistory)
);

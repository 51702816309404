import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { from } from 'rxjs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ConfirmDialog from 'components/ConfirmDialog';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAuth0 } from 'utils/react-auth0-wrapper';
const propTypes = {
  handleOpen: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userDeletion: PropTypes.shape({
    dateForDeletion: PropTypes.string,
  }).isRequired,
  removeUserDeletionDialogIsOpen: PropTypes.bool.isRequired,
  removeUserDeletionRequest: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function UserDeletionSection({
  handleOpen,
  isFetching,
  removeUserDeletionDialogIsOpen,
  removeUserDeletionRequest,
  userDeletion,
  userInfo,
}) {
  const { dateForDeletion } = userDeletion;
  const { getTokenSilently, logout } = useAuth0();
  const { id } = userInfo;
  return (
    !isEmpty(userDeletion) && (
      <Grid item xs={12}>
        <Box mt={3}>
          <Typography
            className="button"
            display="inline"
            gutterBottom
            variant="h6"
          >
            Scheduled for deletion on or after {dateForDeletion}.
          </Typography>
          <Button
            className="button"
            color="primary"
            disabled={isFetching}
            onClick={() => handleOpen(true)}
            size="medium"
            type="submit"
            variant="outlined"
          >
            {' '}
            Remove
          </Button>
          <ConfirmDialog
            content={`Are you sure you want to remove the user from the deletion queue?`}
            dialogIsOpen={removeUserDeletionDialogIsOpen}
            handleClick={() =>
              from(getTokenSilently()).subscribe({
                next: (token) =>
                  removeUserDeletionRequest({
                    id,
                    token,
                  }),
                error: () => logout({ returnTo: window.location.origin }),
                complete: () => handleOpen(false),
              })
            }
            handleClose={() => handleOpen(false)}
            title="Remove From Deletion Queue"
          />
        </Box>
      </Grid>
    )
  );
}

UserDeletionSection.propTypes = propTypes;

export default UserDeletionSection;

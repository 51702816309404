import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Details from 'components/pages/view-user-info/details/Details';
import Links from 'components/pages/view-user-info/details/Links';

const propTypes = {
  blacklistInfo: PropTypes.shape({
    isBlacklisted: PropTypes.string,
    reason: PropTypes.string,
  }).isRequired,
  toggleSampleRequest: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    appVersion: PropTypes.string,
    carrierName: PropTypes.string,
    countryCode: PropTypes.string,
    createdSample: PropTypes.bool,
    credits: PropTypes.number,
    dateCreated: PropTypes.string,
    email: PropTypes.string,
    firebaseId: PropTypes.string,
    id: PropTypes.string,
    lastUpdatedDate: PropTypes.string,
    identityProviders: PropTypes.array,
    phoneNumber: PropTypes.string,
    platformName: PropTypes.string,
    rtdbShard: PropTypes.string,
  }).isRequired,
};

function DetailsSection({ blacklistInfo, toggleSampleRequest, userInfo }) {
  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Details
      </Typography>
      <Divider />
      <Details
        blacklistInfo={blacklistInfo}
        toggleSampleRequest={toggleSampleRequest}
        userInfo={userInfo}
      />
      <Links userInfo={userInfo} />
    </Grid>
  );
}

DetailsSection.propTypes = propTypes;

export default DetailsSection;

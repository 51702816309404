import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { numberSchema } from 'utils/view-burner';

const propTypes = {
  burnerInfo: PropTypes.shape({
    usage: PropTypes.shape({
      remainingMinutes: PropTypes.number,
      remainingTexts: PropTypes.number,
      totalMinutes: PropTypes.number,
      totalTexts: PropTypes.number,
    }),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function AddTextsMinutes({ burnerInfo, handleSubmit, isFetching }) {
  const initialValues = { texts: 0, minutes: 0 };
  const { getTokenSilently, logout } = useAuth0();

  return (
    <Grid item sm={12} md={6}>
      <Typography className="heading" gutterBottom variant="h5">
        Add Texts/Minutes
      </Typography>
      <Divider />
      <Typography className="text" variant="body2">
        Set the remaining texts and/or minutes for this number. Note: this will
        automatically reset the total texts/minutes as well.
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                settingsObject: {
                  ...burnerInfo,
                  usage: {
                    remainingTexts: values.texts,
                    totalTexts: values.texts,
                    remainingMinutes: values.minutes,
                    totalMinutes: values.minutes,
                  },
                },
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => resetForm(initialValues),
          });
        }}
        validationSchema={numberSchema}
      >
        {() => (
          <Form>
            <Box alignItems="baseline" display="flex" flexWrap="wrap" mb={1}>
              <Field name="texts">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    {...field}
                    className="input"
                    disabled={isFetching}
                    error={errors.texts && touched.texts}
                    helperText={errors.texts && touched.texts && errors.texts}
                    id="texts"
                    InputProps={
                      errors.texts &&
                      touched.texts && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <ErrorOutlineIcon className="error" />
                          </InputAdornment>
                        ),
                      }
                    }
                    label="Texts"
                    margin="dense"
                    type="number"
                    variant="standard"
                  />
                )}
              </Field>
              <Field name="minutes">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    {...field}
                    className="input"
                    disabled={isFetching}
                    error={errors.minutes && touched.minutes}
                    helperText={
                      errors.minutes && touched.minutes && errors.minutes
                    }
                    id="minutes"
                    InputProps={
                      errors.minutes &&
                      touched.minutes && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <ErrorOutlineIcon className="error" />
                          </InputAdornment>
                        ),
                      }
                    }
                    label="Minutes"
                    margin="dense"
                    type="number"
                    variant="outlined"
                  />
                )}
              </Field>
              <Button
                className="button"
                disabled={isFetching}
                size="medium"
                color="primary"
                type="submit"
                variant="outlined"
              >
                Change
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

AddTextsMinutes.propTypes = propTypes;

export default AddTextsMinutes;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { from } from 'rxjs';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  blacklistInfo: PropTypes.shape({
    isBlacklisted: PropTypes.string,
    reason: PropTypes.string,
  }).isRequired,
  toggleSampleRequest: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    appVersion: PropTypes.string,
    carrierName: PropTypes.string,
    countryCode: PropTypes.string,
    createdSample: PropTypes.bool,
    credits: PropTypes.number,
    dateCreated: PropTypes.string,
    email: PropTypes.string,
    firebaseId: PropTypes.string,
    id: PropTypes.string,
    lastUpdatedDate: PropTypes.string,
    identityProviders: PropTypes.array,
    phoneNumber: PropTypes.string,
    platformName: PropTypes.string,
    rtdbShard: PropTypes.string,
  }).isRequired,
};

function Details({ blacklistInfo, toggleSampleRequest, userInfo }) {
  const { getTokenSilently, logout } = useAuth0();
  const {
    appVersion,
    carrierName,
    countryCode,
    createdSample,
    credits,
    dateCreated,
    email,
    firebaseId,
    id,
    lastUpdatedDate,
    identityProviders,
    phoneNumber,
    platformName,
    rtdbShard,
  } = userInfo;
  const { isBlacklisted, reason } = blacklistInfo;
  return (
    <Grid item xs={12}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">User ID</TableCell>
            <TableCell>{id}</TableCell>
            <TableCell variant="head">Phone Number</TableCell>
            <TableCell>{phoneNumber}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Email</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell variant="head">Linked Providers</TableCell>
            <TableCell>{identityProviders.join(' | ')}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Firebase User ID</TableCell>
            <TableCell>{firebaseId}</TableCell>
            <TableCell variant="head">Firebase RTDB Shard</TableCell>
            <TableCell>{rtdbShard}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Platform</TableCell>
            <TableCell>{platformName}</TableCell>
            <TableCell variant="head">Credits</TableCell>
            <TableCell>{credits}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">App Version</TableCell>
            <TableCell>{appVersion}</TableCell>
            <TableCell variant="head">Has created sample</TableCell>
            <TableCell>
              <Grid alignItems="center" component="label" container spacing={1}>
                <Grid item>False</Grid>
                <Grid item>
                  <Switch
                    checked={createdSample}
                    color="error"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    onChange={() =>
                      from(getTokenSilently()).subscribe({
                        next: (token) =>
                          toggleSampleRequest({
                            id,
                            token,
                          }),
                        error: () =>
                          logout({ returnTo: window.location.origin }),
                      })
                    }
                  />
                </Grid>
                <Grid item>True</Grid>
              </Grid>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Country Code</TableCell>
            <TableCell>{countryCode}</TableCell>
            <TableCell variant="head">Carrier</TableCell>
            <TableCell>{carrierName}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Date Created</TableCell>
            <TableCell>{dateCreated}</TableCell>
            <TableCell variant="head">Last updated</TableCell>
            <TableCell>{lastUpdatedDate}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Blacklisted</TableCell>
            <TableCell
              className={clsx(isBlacklisted === 'True' ? 'warning' : null)}
            >
              {isBlacklisted}
            </TableCell>
            <TableCell variant="head">
              {isBlacklisted === 'True' && 'Reason'}
            </TableCell>
            <TableCell>{isBlacklisted === 'True' && reason}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}

Details.propTypes = propTypes;

export default Details;

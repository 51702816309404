import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';

/*
Actions
*/
export const CLEAR_RATE_LIMITS_FAILURE =
  'dashboard/clearRateLimits/CLEAR_RATE_LIMITS_FAILURE';
export const CLEAR_RATE_LIMITS_REQUEST =
  'dashboard/clearRateLimits/CLEAR_RATE_LIMITS_REQUEST';
export const CLEAR_RATE_LIMITS_SUCCESS =
  'dashboard/clearRateLimits/CLEAR_RATE_LIMITS_SUCCESS';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/clearRateLimits/HANDLE_CLOSE_SNACKBAR';

/*
Action Creators
*/
export function clearRateLimitsFailure(payload) {
  return { type: CLEAR_RATE_LIMITS_FAILURE, payload };
}

export function clearRateLimitsRequest(payload) {
  return { type: CLEAR_RATE_LIMITS_REQUEST, payload };
}

export function clearRateLimitsSuccess(payload) {
  return { type: CLEAR_RATE_LIMITS_SUCCESS, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

/*
Reducer
*/
const initialState = {
  isFetching: false,
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case CLEAR_RATE_LIMITS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case CLEAR_RATE_LIMITS_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case CLEAR_RATE_LIMITS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload },
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: { isError: false, isOpen: false, text: '' },
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const clearRateLimitsEpic = (action$) => {
  const clearRateLimits = (action) => {
    const { phoneNumberOrId, rateLimitType, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${encodeURIComponent(
        phoneNumberOrId
      )}/abuse/${rateLimitType}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(CLEAR_RATE_LIMITS_REQUEST),
    mergeMap((action) =>
      clearRateLimits(action).pipe(
        map(() => clearRateLimitsSuccess('Rate limit cleared')),
        catchError((error) =>
          of(
            clearRateLimitsFailure(
              error.response && error.response.error
                ? error.response.error
                : "User isn't rate limited."
            )
          )
        )
      )
    )
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  countries,
  formatE164IfValidPhoneNumber,
  isEmail,
} from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import ConfirmDialog from 'components/ConfirmDialog';
import { userIdentifierSchema } from '../../../utils/view-user-info';

const propTypes = {
  burnerInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    phoneNumberId: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

function TransferOwnership({
  burnerInfo,
  handleOpen,
  handleSubmit,
  isFetching,
  isOpen,
}) {
  const initialValues = { country: 'US', userIdentifier: '' };
  const { getTokenSilently, logout } = useAuth0();

  const onComplete = (resetForm) => {
    handleOpen(false);
    resetForm(initialValues);
  };

  const getConfirmationUserSnippet = (values) => {
    const formattedNumber = formatE164IfValidPhoneNumber(
      values.userIdentifier,
      values.country
    );

    return formattedNumber
      ? `User account with phone number ${formattedNumber}.
         Please note if this user doesn't have an account yet, the system will create the account
         for them and send them a verification code to sign up for Burner.`
      : isEmail(values.userIdentifier)
      ? `User account with email ${values.userIdentifier}.
         Please note if this user doesn't have an account yet, the system will throw an error.`
      : `User account with ID ${values.userIdentifier}.
         Please note if this user doesn't have an account yet, the system will throw an error.`;
  };

  return (
    <Grid item sm={12} md={6}>
      <Typography className="heading" gutterBottom variant="h5">
        Transfer Ownership
      </Typography>
      <Divider />
      <Typography className="text" variant="body2">
        Use this feature to transfer ownership of this Burner to another user.
        Note that if a phone number is entered that is not attached to an
        account, the system will create an account for them and send them a
        verification code. It will not create a new account for an email or user
        id entered here.
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                settingsObject: burnerInfo,
                token,
                ...values,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => onComplete(resetForm),
          });
        }}
        validationSchema={userIdentifierSchema}
      >
        {({ submitForm, values }) => (
          <Form>
            <Box alignItems="baseline" display="flex" flexWrap="wrap" mb={1}>
              <Field name="userIdentifier">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    {...field}
                    className="input"
                    disabled={isFetching}
                    error={errors.userIdentifier && touched.userIdentifier}
                    helperText={
                      errors.userIdentifier &&
                      touched.userIdentifier &&
                      errors.userIdentifier
                    }
                    id="userIdentifier"
                    InputProps={
                      errors.userIdentifier &&
                      touched.userIdentifier && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <ErrorOutlineIcon className="error" />
                          </InputAdornment>
                        ),
                      }
                    }
                    label="User ID, phone, or email*"
                    margin="dense"
                    type="text"
                    variant="standard"
                  />
                )}
              </Field>
              <Field name="country">
                {({ field }) => (
                  <TextField
                    {...field}
                    className="input"
                    disabled={isFetching}
                    label="Country"
                    margin="dense"
                    select
                    SelectProps={{
                      MenuProps: {
                        className: 'menu',
                      },
                    }}
                    variant="outlined"
                  >
                    {countries &&
                      countries.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Field>
              <Button
                className="button"
                disabled={isFetching || values.userIdentifier.length < 3}
                color="primary"
                onClick={() => handleOpen(true)}
                size="medium"
                variant="outlined"
              >
                Transfer Ownership
              </Button>
              <ConfirmDialog
                content={`Please confirm that you would like to transfer the
              Burner with name ${burnerInfo.name}
              and phone number ${
                burnerInfo.phoneNumberId
              } to ${getConfirmationUserSnippet(values)}`}
                dialogIsOpen={isOpen}
                handleClick={submitForm}
                handleClose={() => handleOpen(false)}
                title="Confirm transfer of ownership"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

TransferOwnership.propTypes = propTypes;

export default TransferOwnership;

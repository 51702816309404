import { ofType } from 'redux-observable';
import { iif, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { navigate } from '@reach/router';
import { createSelector } from 'reselect';
import { formatE164IfValidPhoneNumber } from 'utils/general';
import { isHistoryPresentString } from 'utils/number-lookup';

/*
Actions
*/
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/numberLookup/HANDLE_CLOSE_SNACKBAR';
export const SEARCH_ACCOUNTS_FAILURE =
  'dashboard/numberLookup/SEARCH_ACCOUNTS_FAILURE';
export const SEARCH_ACCOUNTS_REQUEST =
  'dashboard/numberLookup/SEARCH_ACCOUNTS_REQUEST';
export const SEARCH_ACCOUNTS_SUCCESS =
  'dashboard/numberLookup/SEARCH_ACCOUNTS_SUCCESS';
export const SEARCH_NUMBERS_FAILURE =
  'dashboard/numberLookup/SEARCH_NUMBERS_FAILURE';
export const SEARCH_NUMBERS_REQUEST =
  'dashboard/numberLookup/SEARCH_NUMBERS_REQUEST';
export const SEARCH_NUMBERS_SUCCESS =
  'dashboard/numberLookup/SEARCH_NUMBERS_SUCCESS';
export const SEARCH_RELEASED_FAILURE =
  'dashboard/numberLookup/SEARCH_RELEASED_FAILURE';
export const SEARCH_RELEASED_REQUEST =
  'dashboard/numberLookup/SEARCH_RELEASED_REQUEST';
export const SEARCH_RELEASED_SUCCESS =
  'dashboard/numberLookup/SEARCH_RELEASED_SUCCESS';
export const SEARCH_TWILIO_FAILURE =
  'dashboard/numberLookup/SEARCH_TWILIO_FAILURE';
export const SEARCH_TWILIO_REQUEST =
  'dashboard/numberLookup/SEARCH_TWILIO_REQUEST';
export const SEARCH_TWILIO_SUCCESS =
  'dashboard/numberLookup/SEARCH_TWILIO_SUCCESS';

/*
Action Creators
*/
export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

export function searchAccountsFailure(payload) {
  return { type: SEARCH_ACCOUNTS_FAILURE, payload };
}

export function searchAccountsRequest(payload) {
  return { type: SEARCH_ACCOUNTS_REQUEST, payload };
}

export function searchAccountsSuccess(payload) {
  return { type: SEARCH_ACCOUNTS_SUCCESS, payload };
}

export function searchNumbersFailure(payload) {
  return { type: SEARCH_NUMBERS_FAILURE, payload };
}

export function searchNumbersRequest(payload) {
  return { type: SEARCH_NUMBERS_REQUEST, payload };
}

export function searchNumbersSuccess() {
  return { type: SEARCH_NUMBERS_SUCCESS };
}

export function searchReleasedFailure(payload) {
  return { type: SEARCH_RELEASED_FAILURE, payload };
}

export function searchReleasedRequest(payload) {
  return { type: SEARCH_RELEASED_REQUEST, payload };
}

export function searchReleasedSuccess(payload) {
  return { type: SEARCH_RELEASED_SUCCESS, payload };
}

export function searchTwilioFailure(payload) {
  return { type: SEARCH_TWILIO_FAILURE, payload };
}

export function searchTwilioRequest(payload) {
  return { type: SEARCH_TWILIO_REQUEST, payload };
}

export function searchTwilioSuccess(payload) {
  return { type: SEARCH_TWILIO_SUCCESS, payload };
}

/*
Reducer
*/
const initialState = {
  activeStep: 0,
  isFetching: false,
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
  burnerHistory: [],
  steps: [
    'Checking active Burner numbers.',
    'Checking user accounts.',
    'Checking released Burner numbers.',
    'Checking number in Twilio.',
  ],
  twilioData: {},
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: initialState.message,
      };
    case SEARCH_ACCOUNTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case SEARCH_ACCOUNTS_REQUEST:
      return {
        ...state,
        activeStep: 2,
      };
    case SEARCH_ACCOUNTS_SUCCESS:
      return {
        ...initialState,
      };
    case SEARCH_NUMBERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case SEARCH_NUMBERS_REQUEST:
      return {
        ...initialState,
        activeStep: 1,
        isFetching: true,
      };
    case SEARCH_NUMBERS_SUCCESS:
      return {
        ...initialState,
      };
    case SEARCH_RELEASED_FAILURE:
      return {
        ...state,
        isFetching: false,
        burnerHistory: [],
        message: { isError: true, isOpen: true, text: payload },
      };
    case SEARCH_RELEASED_REQUEST:
      return {
        ...state,
        activeStep: 3,
      };
    case SEARCH_RELEASED_SUCCESS:
      return {
        ...state,
        isFetching: false,
        burnerHistory: payload,
      };
    case SEARCH_TWILIO_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case SEARCH_TWILIO_REQUEST:
      return {
        ...state,
        activeStep: 4,
      };
    case SEARCH_TWILIO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        twilioData: payload,
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const searchActiveNumbersEpic = (action$) => {
  const searchActiveNumbers = (action) => {
    const { phoneNumber, token } = action.payload;
    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/burners?phoneNumber=${encodeURIComponent(phoneNumber)}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(SEARCH_NUMBERS_REQUEST),
    mergeMap((action) =>
      searchActiveNumbers(action).pipe(
        tap((response) =>
          navigate(`/dashboard/view-burner/${response.response.id}`)
        ),
        map(() => searchNumbersSuccess()),
        catchError((error) =>
          iif(
            () => error.status && error.status === 404,
            of(searchAccountsRequest({ ...action.payload })),
            of(
              searchNumbersFailure(
                error.response && error.response.error
                  ? error.response.error
                  : 'Something went wrong.'
              )
            )
          )
        )
      )
    )
  );
};

export const searchAccountsEpic = (action$) => {
  const searchUserAccounts = (action) => {
    const { phoneNumber, token } = action.payload;
    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/users?phoneNumber=${encodeURIComponent(phoneNumber)}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(SEARCH_ACCOUNTS_REQUEST),
    mergeMap((action) =>
      searchUserAccounts(action).pipe(
        tap((response) =>
          navigate(`/dashboard/view-user/${response.response.id}`)
        ),
        map(() => searchAccountsSuccess()),
        catchError((error) =>
          iif(
            () => error.status && error.status === 404,
            of(searchReleasedRequest({ ...action.payload })),
            of(
              searchAccountsFailure(
                error.response && error.response.error
                  ? error.response.error
                  : 'Something went wrong.'
              )
            )
          )
        )
      )
    )
  );
};

export const searchReleasedNumbersEpic = (action$) => {
  const searchReleasedNumbers = (action) => {
    const { country, phoneNumber, token } = action.payload;
    const formattedNumber = formatE164IfValidPhoneNumber(phoneNumber, country);
    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/phone-numbers/${encodeURIComponent(formattedNumber)}/history`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(SEARCH_RELEASED_REQUEST),
    mergeMap((action) =>
      searchReleasedNumbers(action).pipe(
        mergeMap((response) =>
          iif(
            () => response.response.length > 0,
            of(searchReleasedSuccess(response.response)),
            of(searchTwilioRequest({ ...action.payload }))
          )
        ),
        catchError((error) =>
          iif(
            () => error.status && error.status === 400,
            of(searchTwilioRequest({ ...action.payload })),
            of(
              searchReleasedFailure(
                error.response && error.response.error
                  ? error.response.error
                  : 'Something went wrong.'
              )
            )
          )
        )
      )
    )
  );
};

export const searchTwilioEpic = (action$) => {
  const searchTwilio = (action) => {
    const { phoneNumber, token } = action.payload;
    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/phone-numbers/${encodeURIComponent(phoneNumber)}/lookup`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(SEARCH_TWILIO_REQUEST),
    mergeMap((action) =>
      searchTwilio(action).pipe(
        map((response) => searchTwilioSuccess(response.response)),
        catchError((error) =>
          of(
            searchTwilioFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Something went wrong.'
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getPreviouslyAssignedBurner = createSelector(
  (state) => state.numberLookup.burnerHistory,
  (burnerHistory) => isHistoryPresentString(burnerHistory)
);

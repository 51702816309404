import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import { formatDate } from 'utils/general';

export const enrichUserInfo = (userInfo) =>
  isEmpty(userInfo)
    ? userInfo
    : {
        ...userInfo,
        createdSample: capitalize(toString(userInfo.createdSample)),
        dateCreated: formatDate(userInfo.dateCreated),
        lastUpdatedDate: formatDate(userInfo.lastUpdatedDate),
      };

export const transformIsBlacklisted = (isBlacklisted) =>
  isEmpty(isBlacklisted) ? 'False' : 'True';

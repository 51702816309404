import { connect } from 'react-redux';
import { getUserInfo, getUserTransactions } from 'ducks/view-user-info';
import TransactionsSection from 'components/pages/view-user-info/transactions/TransactionsSection';

const mapStateToProps = (state) => ({
  userInfo: getUserInfo(state),
  userTransactions: getUserTransactions(state),
});

export default connect(mapStateToProps, null)(TransactionsSection);

import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getNumberCacheAnalyticsToken,
  enrichNumberCacheData,
} from 'utils/number-cache-analytics';

/*
Actions
*/
export const GET_NUMBER_CACHE_DATA_FAILURE =
  'dashboard/numberCacheAnalytics/GET_NUMBER_CACHE_DATA_FAILURE';

export const GET_NUMBER_CACHE_DATA_REQUEST =
  'dashboard/numberCacheAnalytics/GET_NUMBER_CACHE_DATA_REQUEST';

export const GET_NUMBER_CACHE_DATA_SUCCESS =
  'dashboard/numberCacheAnalytics/GET_NUMBER_CACHE_DATA_SUCCESS';

export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/numberCacheAnalytics/HANDLE_CLOSE_SNACKBAR';

/*
Action Creators
*/
export function getNumberCacheDataFailure(payload) {
  return { type: GET_NUMBER_CACHE_DATA_FAILURE, payload };
}

export function getNumberCacheDataRequest(payload) {
  return { type: GET_NUMBER_CACHE_DATA_REQUEST, payload };
}

export function getNumberCacheDataSuccess(payload) {
  return { type: GET_NUMBER_CACHE_DATA_SUCCESS, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

/*
Reducer
*/
const initialState = {
  isFetching: false,
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
  numberCacheData: [],
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case GET_NUMBER_CACHE_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case GET_NUMBER_CACHE_DATA_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case GET_NUMBER_CACHE_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        numberCacheData: payload,
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: { isError: false, isOpen: false, text: '' },
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const getNumberCacheAnalyticsDataEpic = (action$) => {
  const getNumberCacheAnalyticsData = (action) => {
    // This is the token from auth0
    // const { token } = action.payload;

    // This is the basic auth that nca expects
    const token = getNumberCacheAnalyticsToken();

    return ajax({
      url: `${process.env.REACT_APP_NCA_BASE_URL}/latest`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(GET_NUMBER_CACHE_DATA_REQUEST),
    mergeMap((action) =>
      getNumberCacheAnalyticsData(action).pipe(
        map((response) => getNumberCacheDataSuccess(response.response)),
        catchError((error) =>
          of(
            getNumberCacheDataFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Something went wrong.'
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getNumberCacheData = createSelector(
  (state) => state.numberCacheAnalytics.numberCacheData,
  (numCacheData) => enrichNumberCacheData(numCacheData)
);

import { connect } from 'react-redux';
import BlockedNumbersSection from 'components/pages/view-user-info/blocked-numbers/BlockedNumbersSection';

const mapStateToProps = (state) => {
  const { blockedContacts, userInfo } = state.viewUser;

  return {
    blockedContacts,
    userInfo,
  };
};

export default connect(mapStateToProps, null)(BlockedNumbersSection);

import React, { useEffect } from 'react';
import { from } from 'rxjs';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MUIDataTable from 'mui-datatables';
import { columns, options } from 'utils/view-experiments';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  experimentsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      platform: PropTypes.string,
      sendEvent: PropTypes.string,
      sendUserProps: PropTypes.string,
    })
  ).isRequired,
  fetchExperimentsFailure: PropTypes.func.isRequired,
  fetchExperimentsRequest: PropTypes.func.isRequired,
};

function ViewExperimentsTable({
  experimentsData,
  fetchExperimentsFailure,
  fetchExperimentsRequest,
}) {
  const { getTokenSilently, logout } = useAuth0();

  useEffect(() => {
    const token$ = from(getTokenSilently()).subscribe({
      next: (token) => fetchExperimentsRequest({ token }),
      error: () => logout({ returnTo: window.location.origin }),
    });

    return () => token$.unsubscribe();
  }, [
    fetchExperimentsFailure,
    fetchExperimentsRequest,
    getTokenSilently,
    logout,
  ]);

  return (
    !isEmpty(experimentsData) && (
      <MUIDataTable
        columns={columns}
        data={experimentsData}
        options={options}
      />
    )
  );
}

ViewExperimentsTable.propTypes = propTypes;

export default ViewExperimentsTable;

import React from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import PushNotificationModal from 'components/pages/view-user-info/account-controls/PushNotificationModal';
import SettingsSwitch from 'components/pages/view-user-info/account-controls/SettingsSwitch';

const propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  pushDialogIsOpen: PropTypes.bool.isRequired,
  sendPushRequest: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    dndEnabled: PropTypes.bool,
  }).isRequired,
  toggleDoNotDisturbRequest: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function AppControls({
  handleClick,
  handleOpen,
  isFetching,
  pushDialogIsOpen,
  sendPushRequest,
  settings,
  toggleDoNotDisturbRequest,
  userInfo,
}) {
  const { dndEnabled } = settings;
  const { getTokenSilently, logout } = useAuth0();
  const { id } = userInfo;

  return (
    <Grid item sm={12} md={4} className="item">
      <Typography className="heading" gutterBottom variant="h5">
        App Controls
      </Typography>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Do not disturb</TableCell>
            <TableCell>
              <SettingsSwitch
                handleChange={toggleDoNotDisturbRequest}
                userInfo={userInfo}
                value={dndEnabled}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Send text push notification</TableCell>
            <TableCell>
              <Button
                className="button"
                color="primary"
                disabled={isFetching}
                onClick={() => handleOpen(true)}
                size="medium"
                variant="outlined"
              >
                Send To User&apos;s Phone
              </Button>
              <PushNotificationModal
                handleOpen={handleOpen}
                handleSubmit={sendPushRequest}
                isFetching={isFetching}
                isOpen={pushDialogIsOpen}
                userInfo={userInfo}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Log this user out</TableCell>
            <TableCell>
              <Button
                className="button"
                color="primary"
                disabled={isFetching}
                onClick={() =>
                  from(getTokenSilently()).subscribe({
                    next: (token) =>
                      handleClick({
                        id,
                        token,
                      }),
                    error: () => logout({ returnTo: window.location.origin }),
                  })
                }
                size="medium"
                type="button"
                variant="outlined"
              >
                Log Out
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}

AppControls.propTypes = propTypes;

export default AppControls;

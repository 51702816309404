import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function View({ children }) {
  return (
    <Container maxWidth={false}>
      <Grid container>{children}</Grid>
    </Container>
  );
}

View.propTypes = propTypes;

export default View;

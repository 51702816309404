import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MessagingService from 'components/MessagingService';
import View from 'components/View';
import Unauthorized from 'components/Unauthorized';
import ViewLegalInput from 'components/pages/view-legal/ViewLegalInput';
import { createCsvDownload } from 'utils/view-legal';
import { routeScope } from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  countryParam: PropTypes.string,
  fetchLegalRequest: PropTypes.func.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  legalData: PropTypes.shape({
    data: PropTypes.string,
    name: PropTypes.string,
    phoneNumberOrEmail: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  phoneNumberOrEmailParam: PropTypes.string,
  startDateParam: PropTypes.string,
  endDateParam: PropTypes.string,
};

function ViewLegal({
  fetchLegalRequest,
  handleCloseSnackbar,
  isFetching,
  legalData,
  message,
  phoneNumberOrEmailParam = '',
  countryParam = '',
  startDateParam = '',
  endDateParam = '',
}) {
  useEffect(() => {
    if (isEmpty(legalData)) return;

    createCsvDownload({
      data: legalData.data,
      filename: `${legalData.name}_${legalData.phoneNumberOrEmail}.csv`,
    });
  }, [legalData]);

  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('viewLegal')) ? (
    <View>
      <ViewLegalInput
        handleSubmit={fetchLegalRequest}
        isFetching={isFetching}
        phoneNumberOrEmailParam={phoneNumberOrEmailParam}
        countryParam={countryParam}
        startDateParam={startDateParam}
        endDateParam={endDateParam}
      />
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

ViewLegal.propTypes = propTypes;

export default ViewLegal;

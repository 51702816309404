import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      dateCreated: PropTypes.string,
      id: PropTypes.string,
      note: PropTypes.string,
    })
  ).isRequired,
};

function Notes({ notes }) {
  return (
    <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan="6">
              {notes && notes.length === 1 ? '1 Note' : `${notes.length} Notes`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan="1">Note</TableCell>
            <TableCell colSpan="1">Created</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {notes &&
            notes.map((note) => (
              <TableRow key={note.id}>
                <TableCell>{note.note}</TableCell>
                <TableCell>{note.dateCreated}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

Notes.propTypes = propTypes;

export default Notes;

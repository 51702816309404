import { connect } from 'react-redux';
import {
  fetchLegalRequest,
  getLegalData,
  handleCloseSnackbar,
} from 'ducks/view-legal';
import ViewLegal from 'components/pages/ViewLegal';

const mapStateToProps = (state) => {
  const { isFetching, message } = state.viewLegal;

  return {
    isFetching,
    legalData: getLegalData(state),
    message,
  };
};

const mapDispatchToProps = {
  fetchLegalRequest,
  handleCloseSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewLegal);

import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { transformExperimentData } from 'utils/view-experiments';

/*
Actions
*/
export const CREATE_EXPERIMENT_FAILURE =
  'dashboard/viewExperiments/CREATE_EXPERIMENT_FAILURE';
export const CREATE_EXPERIMENT_REQUEST =
  'dashboard/viewExperiments/CREATE_EXPERIMENT_REQUEST';
export const CREATE_EXPERIMENT_SUCCESS =
  'dashboard/viewExperiments/CREATE_EXPERIMENT_SUCCESS';
export const FETCH_EXPERIMENTS_FAILURE =
  'dashboard/viewExperiments/FETCH_EXPERIMENTS_FAILURE';
export const FETCH_EXPERIMENTS_REQUEST =
  'dashboard/viewExperiments/FETCH_EXPERIMENTS_REQUEST';
export const FETCH_EXPERIMENTS_SUCCESS =
  'dashboard/viewExperiments/FETCH_EXPERIMENTS_SUCCESS';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/viewExperiments/HANDLE_CLOSE_SNACKBAR';

/*
Action Creators
*/

export function createExperimentFailure(payload) {
  return { type: CREATE_EXPERIMENT_FAILURE, payload };
}

export function createExperimentRequest(payload) {
  return { type: CREATE_EXPERIMENT_REQUEST, payload };
}

export function createExperimentSuccess(payload) {
  return { type: CREATE_EXPERIMENT_SUCCESS, payload };
}

export function fetchExperimentsFailure(payload) {
  return { type: FETCH_EXPERIMENTS_FAILURE, payload };
}

export function fetchExperimentsRequest(payload) {
  return { type: FETCH_EXPERIMENTS_REQUEST, payload };
}

export function fetchExperimentsSuccess(payload) {
  return { type: FETCH_EXPERIMENTS_SUCCESS, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

/*
Reducer
*/
const initialState = {
  isFetching: false,
  experimentsData: [],
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case CREATE_EXPERIMENT_FAILURE:
      return {
        ...state,
        message: { isError: true, isOpen: true, text: payload },
      };
    case CREATE_EXPERIMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_EXPERIMENT_SUCCESS:
      return {
        ...state,
        message: {
          isError: false,
          isOpen: true,
          text: 'Experiment successfully created',
        },
        isFetching: false,
      };
    case FETCH_EXPERIMENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: {
          isError: true,
          isFetching: false,
          isOpen: true,
          text: payload,
        },
      };
    case FETCH_EXPERIMENTS_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case FETCH_EXPERIMENTS_SUCCESS:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: initialState.message,
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const fetchExperimentsDataEpic = (action$) => {
  const fetchExperimentsData = (action) => {
    const { token } = action.payload;

    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/experiments`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_EXPERIMENTS_REQUEST),
    mergeMap((action) =>
      fetchExperimentsData(action).pipe(
        map((response) =>
          fetchExperimentsSuccess({
            experimentsData: response.response,
          })
        ),
        catchError((error) =>
          of(
            fetchExperimentsFailure(
              error.status && error.status === 400
                ? 'Could not find any experiments'
                : 'Something went wrong'
            )
          )
        )
      )
    )
  );
};

export const createExperimentEpic = (action$) => {
  const createExperiment = (action) => {
    const { token, experiment } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/experiments`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: experiment,
    });
  };

  return action$.pipe(
    ofType(CREATE_EXPERIMENT_REQUEST),
    mergeMap((action) =>
      createExperiment(action).pipe(
        map(() => createExperimentSuccess('Experiment successfully created')),
        catchError((error) =>
          of(
            createExperimentFailure(
              error.response && error.response.message
                ? error.response.message
                : 'Experiment could not be created'
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getExperimentsData = createSelector(
  (state) => state.viewExperiments.experimentsData,
  (experimentData) => transformExperimentData(experimentData)
);

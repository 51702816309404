import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AdjustBurnersAssigned from 'components/pages/view-user-info/subscription-details/AdjustBurnersAssigned';
import CurrentBurnersAssigned from 'components/pages/view-user-info/subscription-details/CurrentBurnersAssigned';
import RefreshSubscription from './RefreshSubscription';

const propTypes = {
  addBurnerRequest: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshSubHasBeenClicked: PropTypes.bool.isRequired,
  removeBurnerRequest: PropTypes.func.isRequired,
  setBurnersAssignedRequest: PropTypes.func.isRequired,
  subscriptionToEdit: PropTypes.shape({
    burnerIds: PropTypes.arrayOf(PropTypes.string),
    burnersAssignedInPeriod: PropTypes.number,
    id: PropTypes.string,
  }).isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  validateSubscriptionRequest: PropTypes.func.isRequired,
};

function EditSubscriptionModal({
  addBurnerRequest,
  handleOpen,
  isFetching,
  isOpen,
  refreshSubHasBeenClicked,
  removeBurnerRequest,
  setBurnersAssignedRequest,
  subscriptionToEdit,
  userInfo,
  validateSubscriptionRequest,
}) {
  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      onClose={() => handleOpen(false)}
      open={isOpen}
    >
      <DialogTitle id="form-dialog-title">Edit Subscription</DialogTitle>
      <DialogContent>
        <RefreshSubscription
          subscriptionInfo={subscriptionToEdit}
          validateSubscriptionRequest={validateSubscriptionRequest}
          refreshSubHasBeenClicked={refreshSubHasBeenClicked}
        />
        <AdjustBurnersAssigned
          handleOpen={handleOpen}
          handleSubmit={setBurnersAssignedRequest}
          isFetching={isFetching}
          subscriptionToEdit={subscriptionToEdit}
        />
        <CurrentBurnersAssigned
          addBurnerRequest={addBurnerRequest}
          handleOpen={handleOpen}
          isFetching={isFetching}
          removeBurnerRequest={removeBurnerRequest}
          subscriptionToEdit={subscriptionToEdit}
          userInfo={userInfo}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

EditSubscriptionModal.propTypes = propTypes;

export default EditSubscriptionModal;

import { format } from 'date-fns';
import { get, isEmpty, kebabCase } from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ajax } from 'rxjs/ajax';
import * as Yup from 'yup';
import * as buffer from "buffer";
// This buffer stuff is needed for some base64 encoding/decoding
window.Buffer = buffer.Buffer;

export const formatE164IfValidPhoneNumber = (phoneNumberInput, country) => {
  try {
    const phoneNumber = parsePhoneNumber(phoneNumberInput, country);
    return phoneNumber && phoneNumber.isValid()
      ? phoneNumber.format('E.164')
      : null;
  } catch (error) {
    return null;
  }
};

export const formatIdentifierIfPhoneNumber = (identifier, country) => {
  const formattedPossiblePhone = formatE164IfValidPhoneNumber(
    identifier,
    country
  );
  if (formattedPossiblePhone === null) {
    return identifier;
  } else {
    return formattedPossiblePhone;
  }
};

export const isEmail = (userIdentifier) => {
  return userIdentifier.includes('@');
};

export const cleanPhone = (phone) => {
  phone = phone.replace(/[^\d+]/g, '');
  if (phone.substr(0, 1) === '+') {
    phone = '+' + phone.replace(/[^\d]/g, '');
  } else {
    phone = phone.replace(/[^\d]/g, '');
  }
  return phone;
};

export const base64Encode = (string) => Buffer.from(string).toString('base64');

export const countries = [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'Australia',
    value: 'AU',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
];

export const phoneOrIdSchema = Yup.object().shape({
  phoneNumberOrId: Yup.string()
    .min(10, '* Minimum 10 characters')
    .required('* Required'),
});

export const phoneOrAreaCodeSchema = Yup.object().shape({
  phoneNumberOrAreaCode: Yup.string()
    .min(3, '* Minimum 3 digits')
    .max(12, '* Maximum 12 digits')
    .required('* Required'),
});

export const textSchema = Yup.object().shape({
  body: Yup.string()
    .max(500, '* Maximum 500 characters')
    .required('* Required'),
});

export const formatDate = (aDate) => {
  if (!aDate) return '';
  // An auto-renewing burners expiration date is set to the maximum value for a
  // 64-bit signed integer, which is not a valid date, so return 'Auto-Renewing'
  return aDate >= Number.MAX_SAFE_INTEGER
    ? 'Auto-Renewing'
    : format(aDate, 'PPpp');
};

export const routeNameToLink = (string) => `/dashboard/${kebabCase(string)}`;
export const routeScope = (key) => get(routeScopes, key);

export const routeScopes = {
  accountsToDelete: 'read:admin-accounts-to-delete',
  blacklistUser: 'read:admin-blacklist-user',
  clearRateLimits: 'read:admin-clear-rate-limits',
  hostedMedia: 'read:admin-hosted-media',
  logContext: 'read:admin-view-log-context',
  numberCacheAnalytics: 'read:admin-number-cache-analytics',
  numberLookup: 'read:admin-number-lookup',
  portOutPin: 'read:admin-port-out-pins',
  provisionNumber: 'read:admin-provision-number',
  replaceNumber: 'read:admin-replace-number',
  telephonyExtras: 'read:admin-bandwidth-extras',
  viewBurner: 'read:admin-view-burner',
  viewExperiments: 'read:admin-view-experiments',
  viewLegal: 'read:admin-view-legal',
  viewUser: 'read:admin-view-user',
};

export const routes = [
  { key: 'numberLookup', name: 'Number Lookup' },
  { key: 'viewUser', name: 'View User' },
  { key: 'viewBurner', name: 'View Burner' },
  { key: 'replaceNumber', name: 'Replace Number' },
  { key: 'portOutPin', name: 'Port Out Pin' },
  { key: 'provisionNumber', name: 'Provision Number' },
  { key: 'clearRateLimits', name: 'Clear Rate Limits' },
  { key: 'blacklistUser', name: 'Blacklist User' },
  { key: 'logContext', name: 'Log Context' },
  { key: 'hostedMedia', name: 'Hosted Media' },
  { key: 'numberCacheAnalytics', name: 'Number Cache Analytics' },
  { key: 'viewLegal', name: 'View Legal' },
  { key: 'accountsToDelete', name: 'Accounts To Delete' },
  { key: 'telephonyExtras', name: 'Telephony Extras' },
  { key: 'viewExperiments', name: 'View Experiments' },
].map((route) => ({
  ...route,
  link: routeNameToLink(route.name),
  scope: routeScope(route.key),
}));

export const makeRequest = (url, token) => {
  return ajax({
    url,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
};

export const isDataPresentStringification = (container) =>
  !isEmpty(container) ? 'True' : 'False';

import { connect } from 'react-redux';
import {
  fetchHostedMediaRequest,
  enableHostedMediaRequest,
  disableHostedMediaRequest,
  handleCloseSnackbar,
} from 'ducks/hosted-media';
import HostedMediaSection from 'components/pages/hosted-media/HostedMediaSection';

const mapStateToProps = (state) => {
  const { isFetching, message, mediaInfo } = state.hostedMedia;

  return {
    isFetching,
    message,
    mediaInfo,
  };
};

const mapDispatchToProps = {
  fetchHostedMediaRequest,
  enableHostedMediaRequest,
  disableHostedMediaRequest,
  handleCloseSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(HostedMediaSection);

import { connect } from 'react-redux';
import { updateBurnerFeatureSetRequest } from 'ducks/view-burner';
import ReplaceFeatureSets from 'components/pages/view-burner/ReplaceFeatureSets';

const mapStateToProps = (state) => {
  const { burnerInfo, isFetching, burnerFeatureSets } = state.viewBurner;
  const { featureSet: currentFeatureSet } = burnerInfo;

  return {
    burnerInfo,
    isFetching,
    burnerFeatureSets,
    currentFeatureSet,
  };
};

const mapDispatchToProps = {
  handleSubmit: updateBurnerFeatureSetRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceFeatureSets);

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const propTypes = {
  blacklistInfo: PropTypes.shape({
    isBlacklisted: PropTypes.string,
    reason: PropTypes.string,
  }).isRequired,
  userInfo: PropTypes.shape({
    countryCode: PropTypes.string,
    createdSample: PropTypes.string,
    credits: PropTypes.number,
    dateCreated: PropTypes.string,
    email: PropTypes.string,
    firebaseId: PropTypes.string,
    id: PropTypes.string,
    lastUpdatedDate: PropTypes.string,
    identityProviders: PropTypes.array,
    phoneNumber: PropTypes.string,
    platformName: PropTypes.string,
    rtdbShard: PropTypes.string,
    totalNumberBurners: PropTypes.number,
  }).isRequired,
};

function UserDetails({ blacklistInfo, userInfo }) {
  const { isBlacklisted, reason } = blacklistInfo;
  const {
    countryCode,
    createdSample,
    credits,
    dateCreated,
    email,
    firebaseId,
    id,
    lastUpdatedDate,
    identityProviders,
    phoneNumber,
    platformName,
    rtdbShard,
    totalNumberBurners,
  } = userInfo;

  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        User Details
      </Typography>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">User ID</TableCell>
            <TableCell>{id}</TableCell>
            <TableCell variant="head">Phone Number</TableCell>
            <TableCell>{phoneNumber}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Email</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell variant="head">Linked Providers</TableCell>
            <TableCell>{identityProviders.join(' | ')}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Firebase User ID</TableCell>
            <TableCell>{firebaseId}</TableCell>
            <TableCell variant="head">Firebase RTDB Shard</TableCell>
            <TableCell>{rtdbShard}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Platform</TableCell>
            <TableCell>{platformName}</TableCell>
            <TableCell variant="head">Credits</TableCell>
            <TableCell>{credits}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Has created sample</TableCell>
            <TableCell>{createdSample}</TableCell>
            <TableCell variant="head">Country Code</TableCell>
            <TableCell>{countryCode}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Total Burner Count</TableCell>
            <TableCell>{totalNumberBurners}</TableCell>
            <TableCell variant="head">Date Created</TableCell>
            <TableCell>{dateCreated}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell variant="head">Last updated</TableCell>
            <TableCell>{lastUpdatedDate}</TableCell>
            <TableCell variant="head">Blacklisted</TableCell>
            <TableCell
              className={clsx(isBlacklisted === 'True' ? 'warning' : null)}
            >
              {isBlacklisted}
            </TableCell>
          </TableRow>

          {isBlacklisted === 'True' && (
            <TableRow>
              <TableCell variant="head">Reason</TableCell>
              <TableCell colSpan={3}>{reason}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Grid>
  );
}

UserDetails.propTypes = propTypes;

export default UserDetails;

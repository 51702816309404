import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const propTypes = {
  content: PropTypes.string.isRequired,
  dialogIsOpen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

function ConfirmDialog({
  content,
  handleClick,
  handleClose,
  dialogIsOpen,
  title,
}) {
  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="xs"
      open={dialogIsOpen}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleClose(false)}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => handleClick()}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = propTypes;

export default ConfirmDialog;

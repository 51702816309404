import React from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import ConfirmDialog from 'components/ConfirmDialog';
import SeeSubscriptionEventsModal from 'components/pages/view-user-info/subscription-details/SeeSubscriptionEventsModal';
import EditSubscriptionDialogContainer from 'containers/EditSubscriptionDialogContainer';

const propTypes = {
  deleteSubscriptionDialogIsOpen: PropTypes.bool.isRequired,
  deleteSubscriptionDialogOpen: PropTypes.func.isRequired,
  deleteSubscriptionRequest: PropTypes.func.isRequired,
  editSubscriptionDialogOpen: PropTypes.func.isRequired,
  setSubscription: PropTypes.func.isRequired,
  setSubscriptionToDelete: PropTypes.func.isRequired,
  subscriptionEvents: PropTypes.shape({
    count: PropTypes.number,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        burnerNumber: PropTypes.string,
        id: PropTypes.string,
        dateCreated: PropTypes.string,
        price: PropTypes.number,
        sku: PropTypes.string,
        storeId: PropTypes.number,
        subscriptionEventType: PropTypes.string,
        subscriptionId: PropTypes.string,
        userId: PropTypes.string,
      })
    ),
  }).isRequired,
  subscriptionEventsDialogIsOpen: PropTypes.bool.isRequired,
  subscriptionEventsDialogOpen: PropTypes.func.isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      burnerIds: PropTypes.arrayOf(PropTypes.string),
      burnersAssignedInPeriod: PropTypes.number,
      cancellationDate: PropTypes.string,
      creationDate: PropTypes.string,
      expirationDate: PropTypes.string,
      id: PropTypes.string,
      renewalDate: PropTypes.string,
      sku: PropTypes.string,
      store: PropTypes.string,
    })
  ).isRequired,
  subscriptionToDelete: PropTypes.shape({
    id: PropTypes.string,
    sku: PropTypes.string,
  }).isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },
  link: {
    margin: theme.spacing(1),
  },
  activeSub: {
    backgroundColor: '#FFFFFF',
  },
  expiredSub: {
    backgroundColor: '#DDDDDD',
  },
}));

function SubscriptionDetailsSection({
  deleteSubscriptionRequest,
  deleteSubscriptionDialogIsOpen,
  deleteSubscriptionDialogOpen,
  editSubscriptionDialogOpen,
  setSubscription,
  setSubscriptionToDelete,
  subscriptionEvents,
  latestOrderIds,
  subscriptionEventsDialogIsOpen,
  subscriptionEventsDialogOpen,
  fetchLatestOrderIdRequest,
  subscriptions,
  subscriptionToDelete,
  userInfo,
}) {
  const { getTokenSilently, logout } = useAuth0();
  const { id } = userInfo;
  const classes = useStyles();

  const handleFetchOrderId = (subId) => {
    subscriptions.forEach((sub) => {
      from(getTokenSilently()).subscribe({
        next: (token) => {
          fetchLatestOrderIdRequest({
            token,
            subId: subId,
          });
        },
        error: () => logout({ returnTo: window.location.origin }),
        complete: () => {},
      });
    });
  };

  const handleClick = (sub) => {
    setSubscription(sub);
    editSubscriptionDialogOpen(true);
  };

  const handleDeleteDialogOpen = (sub) => {
    setSubscriptionToDelete(sub);
    deleteSubscriptionDialogOpen(true);
  };

  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Subscription Details
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell>Store</TableCell>
            <TableCell>Creation Date</TableCell>
            <TableCell>Renewal Date</TableCell>
            <TableCell>Expiration Date</TableCell>
            <TableCell>Cancellation Date</TableCell>
            <TableCell>Burners Assigned in Period</TableCell>
            <TableCell>Burner IDs</TableCell>
            <TableCell>Latest Order Id</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions &&
            subscriptions.map((subscription) => (
              <TableRow
                key={subscription.id}
                className={
                  subscription.expirationDate
                    ? classes.expiredSub
                    : classes.activeSub
                }
              >
                <TableCell>{subscription.sku}</TableCell>
                <TableCell>{subscription.store}</TableCell>
                <TableCell>{subscription.creationDate}</TableCell>
                <TableCell>{subscription.renewalDate}</TableCell>
                <TableCell>{subscription.expirationDate}</TableCell>
                <TableCell>{subscription.cancellationDate}</TableCell>
                <TableCell>{subscription.burnersAssignedInPeriod}</TableCell>
                <TableCell>
                  <List dense>
                    {subscription.burnerIds &&
                      subscription.burnerIds.map((id) => (
                        <ListItem key={id}>
                          <Typography>
                            <Link
                              className={classes.link}
                              href={`${process.env.REACT_APP_URL}/dashboard/view-burner/${id}`}
                              rel="noopener noreferrer"
                              variant="body2"
                            >
                              {id}
                            </Link>
                          </Typography>
                        </ListItem>
                      ))}
                  </List>
                </TableCell>
                <TableCell>
                  {subscription.id in latestOrderIds ? (
                    latestOrderIds[subscription.id].orderId
                  ) : (
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        className="button"
                        onClick={() => handleFetchOrderId(subscription.id)}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    className="button"
                    onClick={() => handleClick(subscription)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    className="button"
                    onClick={() => handleDeleteDialogOpen(subscription)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Button
        className={classes.button}
        color="primary"
        onClick={() => subscriptionEventsDialogOpen(true)}
        size="medium"
        type="button"
        variant="outlined"
      >
        See Subscription Events
      </Button>
      <EditSubscriptionDialogContainer />
      <ConfirmDialog
        content={`Are you sure you want to delete the following subscription?\n sku: ${subscriptionToDelete.sku}\n subId: ${subscriptionToDelete.id}`}
        dialogIsOpen={deleteSubscriptionDialogIsOpen}
        handleClick={() =>
          from(getTokenSilently()).subscribe({
            next: (token) =>
              deleteSubscriptionRequest({
                sku: subscriptionToDelete.sku,
                subscriptionId: subscriptionToDelete.id,
                token,
                userId: id,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => deleteSubscriptionDialogOpen(false),
          })
        }
        handleClose={deleteSubscriptionDialogOpen}
        title="Delete Subscription"
      />
      <SeeSubscriptionEventsModal
        handleOpen={subscriptionEventsDialogOpen}
        isOpen={subscriptionEventsDialogIsOpen}
        subscriptionEvents={subscriptionEvents}
        latestOrderIds={latestOrderIds}
        fetchLatestOrderIdRequest={fetchLatestOrderIdRequest}
      />
    </Grid>
  );
}

SubscriptionDetailsSection.propTypes = propTypes;

export default SubscriptionDetailsSection;

import { connect } from 'react-redux';
import {
  getBurnerHasSubscription,
  getBurnerInfo,
  getPhoneNumberInfo,
  handleNotificationsChange,
  handleRingerChange,
  handleSipChange,
  removeBurnerDialogOpen,
  removeBurnerRequest,
  updateSettingsRequest,
} from 'ducks/view-burner';
import BurnerDetailsSection from 'components/pages/view-burner/BurnerDetailsSection';

const mapStateToProps = (state) => {
  const {
    burnerInfo,
    removeBurnerDialogIsOpen,
    subscription,
    userInfo,
  } = state.viewBurner;

  return {
    burnerHasSubscription: getBurnerHasSubscription(state),
    burnerInfo: getBurnerInfo(state), // burner info for display
    isOpen: removeBurnerDialogIsOpen,
    phoneNumberInfo: getPhoneNumberInfo(state),
    settingsObject: burnerInfo, // burner info to send to server
    subscription,
    userInfo,
  };
};

const mapDispatchToProps = {
  handleClick: removeBurnerRequest,
  handleNotificationsChange,
  handleOpen: removeBurnerDialogOpen,
  handleRingerChange,
  handleSipChange,
  updateSettingsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BurnerDetailsSection);

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

function SettingsSwitch({ handleChange, isChecked }) {
  return (
    <Grid alignItems="center" component="label" container spacing={1}>
      <Grid item>Off</Grid>
      <Grid item>
        <Switch
          checked={isChecked}
          color="error"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          onChange={() => handleChange()}
        />
      </Grid>
      <Grid item>On</Grid>
    </Grid>
  );
}

SettingsSwitch.propTypes = propTypes;

export default SettingsSwitch;

import isEmpty from 'lodash/isEmpty';
import { formatDate } from 'utils/general';

export const enrichAccountsToDelete = (accounts) =>
  isEmpty(accounts)
    ? accounts
    : accounts.map((account) => ({
        ...account,
        dateForDeletion: formatDate(account.dateForDeletion),
      }));

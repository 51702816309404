import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CreateNotes from 'components/pages/view-user-info/internal-notes/CreateNotes';
import Notes from 'components/pages/view-user-info/internal-notes/Notes';

const propTypes = {
  createNoteRequest: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      dateCreated: PropTypes.string,
      id: PropTypes.string,
      note: PropTypes.string,
    })
  ).isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function InternalNotesSection({
  createNoteRequest,
  isFetching,
  notes,
  userInfo,
}) {
  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Internal Notes
      </Typography>
      <Notes notes={notes} />
      <CreateNotes
        handleSubmit={createNoteRequest}
        isFetching={isFetching}
        userInfo={userInfo}
      />
    </Grid>
  );
}

InternalNotesSection.propTypes = propTypes;

export default InternalNotesSection;

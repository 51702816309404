import { connect } from 'react-redux';
import {
  getPreviouslyAssignedBurner,
  handleCloseSnackbar,
  searchNumbersRequest,
} from 'ducks/number-lookup';
import NumberLookup from 'components/pages/NumberLookup';

const mapStateToProps = (state) => {
  const {
    activeStep,
    isFetching,
    message,
    steps,
    twilioData,
  } = state.numberLookup;

  return {
    activeStep,
    isFetching,
    message,
    previouslyAssignedToBurner: getPreviouslyAssignedBurner(state),
    steps,
    twilioData,
  };
};

const mapDispatchToProps = {
  handleCloseSnackbar,
  searchNumbersRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(NumberLookup);

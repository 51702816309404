import { connect } from 'react-redux';
import {
  handleCloseSnackbar,
  getNumberCacheData,
  getNumberCacheDataRequest,
} from 'ducks/number-cache-analytics';
import NumberCacheAnalytics from 'components/pages/NumberCacheAnalytics';

const mapStateToProps = (state) => {
  const { message } = state.numberCacheAnalytics;

  return {
    message,
    numberCacheData: getNumberCacheData(state),
  };
};

const mapDispatchToProps = {
  getNumberCacheDataRequest,
  handleCloseSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberCacheAnalytics);

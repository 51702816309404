import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MessagingService from 'components/MessagingService';
import ReplaceNumberInput from 'components/pages/replace-number/ReplaceNumberInput';
import UserDetails from 'components/pages/replace-number/UserDetails';
import View from 'components/View';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';
import UserSearch from './UserSearch';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const propTypes = {
  fetchDataRequest: PropTypes.func.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  isBlacklisted: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  replaceNumberRequest: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    countryCode: PropTypes.string,
    createdSample: PropTypes.string,
    credits: PropTypes.number,
    dateCreated: PropTypes.string,
    id: PropTypes.string,
    lastUpdatedDate: PropTypes.string,
    phoneNumber: PropTypes.string,
    platformName: PropTypes.string,
    totalNumberBurners: PropTypes.number,
  }).isRequired,
};

function ReplaceNumber({
  fetchDataRequest,
  handleCloseSnackbar,
  isBlacklisted,
  isFetching,
  message,
  replaceNumberRequest,
  userIdentifierParam = null,
  userInfo = {},
}) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('replaceNumber')) ? (
    <View>
      <Grid item xs={12}>
        <Typography
          component="h1"
          data-testid="title-test"
          gutterBottom
          variant="h4"
        >
          Replace User Phone Number
        </Typography>
        <Divider />
        <UserSearch
          handleSubmit={fetchDataRequest}
          isFetching={isFetching}
          userIdentifierParam={userIdentifierParam}
        />
      </Grid>
      {!isEmpty(userInfo) && (
        <>
          <UserDetails isBlacklisted={isBlacklisted} userInfo={userInfo} />
          <ReplaceNumberInput
            handleSubmit={replaceNumberRequest}
            isFetching={isFetching}
            userInfo={userInfo}
          />
        </>
      )}
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

ReplaceNumber.propTypes = propTypes;

export default ReplaceNumber;

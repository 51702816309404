import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { from } from 'rxjs';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { textSchema } from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

function CreateNotes({ handleSubmit, isFetching, userInfo }) {
  const initialValues = { body: '' };
  const { id } = userInfo;
  const { getTokenSilently, logout } = useAuth0();
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                id,
                reason: values.body,
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => resetForm(initialValues),
          });
        }}
        validationSchema={textSchema}
      >
        {() => (
          <Form>
            <Field name="body">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  {...field}
                  disabled={isFetching}
                  error={errors.body && touched.body}
                  fullWidth
                  helperText={errors.body && touched.body && errors.body}
                  id="body"
                  InputProps={
                    errors.body &&
                    touched.body && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ErrorOutline className="error" />
                        </InputAdornment>
                      ),
                    }
                  }
                  label="Create A Note"
                  multiline
                  margin="normal"
                  rows="4"
                  variant="outlined"
                />
              )}
            </Field>
            <Button
              className={classes.button}
              color="primary"
              disabled={isFetching}
              size="medium"
              type="submit"
              variant="outlined"
            >
              Create Note
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

CreateNotes.propTypes = propTypes;

export default CreateNotes;

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import AvailablePhoneNumbers from 'components/pages/provision-number/AvailablePhoneNumbers';
import MessagingService from 'components/MessagingService';
import ProvisionNumberInput from 'components/ProvisionNumberInput';
import View from 'components/View';
import ProvisionNumberModalContainer from 'containers/ProvisionNumberModalContainer';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      areaCode: PropTypes.number,
      availableSince: PropTypes.string,
      id: PropTypes.string,
      serviceProvider: PropTypes.string,
    })
  ).isRequired,
  fetchNumberOrAreaCodeRequest: PropTypes.func.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
  setNumberToProvision: PropTypes.func.isRequired,
};

function ProvisionNumber({
  data,
  fetchNumberOrAreaCodeRequest,
  handleCloseSnackbar,
  isFetching,
  message,
  modalIsOpen,
  setModalIsOpen,
  setNumberToProvision,
}) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('provisionNumber')) ? (
    <View>
      <ProvisionNumberInput
        handleSubmit={fetchNumberOrAreaCodeRequest}
        isFetching={isFetching}
      />
      {!isEmpty(data) && (
        <AvailablePhoneNumbers
          data={data}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setNumberToProvision={setNumberToProvision}
        />
      )}
      <ProvisionNumberModalContainer />
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

ProvisionNumber.propTypes = propTypes;

export default ProvisionNumber;

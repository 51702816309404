import { connect } from 'react-redux';
import {
  deleteSubscriptionDialogOpen,
  deleteSubscriptionRequest,
  editSubscriptionDialogOpen,
  getSubscriptionEvents,
  getLatestOrderId,
  getSubscriptions,
  getUserInfo,
  setSubscription,
  setSubscriptionToDelete,
  subscriptionEventsDialogOpen,
  fetchLatestOrderIdRequest,
} from 'ducks/view-user-info';
import SubscriptionDetailsSection from 'components/pages/view-user-info/subscription-details/SubscriptionDetailsSection';

const mapStateToProps = (state) => {
  const {
    deleteSubscriptionDialogIsOpen,
    subscriptionEventsDialogIsOpen,
    subscriptionToDelete,
  } = state.viewUser;

  return {
    deleteSubscriptionDialogIsOpen,
    subscriptionEvents: getSubscriptionEvents(state),
    latestOrderIds: getLatestOrderId(state),
    subscriptionEventsDialogIsOpen,
    subscriptions: getSubscriptions(state),
    subscriptionToDelete,
    userInfo: getUserInfo(state),
  };
};

const mapDispatchToProps = {
  deleteSubscriptionDialogOpen,
  deleteSubscriptionRequest,
  editSubscriptionDialogOpen,
  fetchLatestOrderIdRequest,
  setSubscription,
  setSubscriptionToDelete,
  subscriptionEventsDialogOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionDetailsSection);

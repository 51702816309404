import { connect } from 'react-redux';
import {
  getTelephonyPhoneNumberData,
  handleCloseSnackbar,
  lookupTelephonyPhoneNumberRequest,
  sendTelephonyMessageRequest,
} from 'ducks/telephony-extras';
import TelephonyExtras from 'components/pages/TelephonyExtras';

const mapStateToProps = (state) => {
  const { isFetching, message } = state.telephonyExtras;

  return {
    data: getTelephonyPhoneNumberData(state),
    isFetching,
    message,
  };
};

const mapDispatchToProps = {
  handleCloseSnackbar,
  lookupTelephonyPhoneNumberRequest,
  sendTelephonyMessageRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TelephonyExtras);

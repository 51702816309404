import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { from } from 'rxjs';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { ANALYTICS_ENUMS } from 'utils/view-experiments';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    margin: theme.spacing(1),
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
}));

function CreateExperiment({ handleSubmit, isFetching }) {
  const { getTokenSilently, logout } = useAuth0();
  const classes = useStyles();

  const initialValues = {
    name: '',
    platforms: ['Amplitude', 'Braze'],
    sendEvent: true,
    sendUserProps: true,
  };

  const onComplete = (resetForm) => {
    resetForm(initialValues);
  };

  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Create a new experiment
      </Typography>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                experiment: {
                  name: values.name,
                  analyticsPlatformSettings: map(
                    values.platforms,
                    (platform) => ({
                      platformId: get(ANALYTICS_ENUMS, platform),
                      sendEvent: values.sendEvent,
                      sendUserProps: values.sendUserProps,
                    })
                  ),
                },
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => onComplete(resetForm),
          });
        }}
        validate={(values) => {
          const errors = {};
          if (isEmpty(values.platforms)) {
            errors.platforms = 'Platform Required';
          } else if (!values.name) {
            errors.name = '* Required';
          }
          return errors;
        }}
      >
        {() => (
          <Form>
            <Field name="name">
              {({ field, form: { errors, touched, values } }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name && errors.name}
                  id="name"
                  InputProps={
                    errors.name &&
                    touched.name && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ErrorOutline className="error" />
                        </InputAdornment>
                      ),
                    }
                  }
                  label="name"
                  margin="normal"
                  type="text"
                  variant="standard"
                  value={values.name}
                />
              )}
            </Field>
            <FormGroup>
              <Field id="sendEvent" type="checkbox" name="sendEvent">
                {({ field, form: { errors, touched, values } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={values.sendEvent}
                        className={classes.checkbox}
                        disabled={isFetching}
                        value={values.sendEvent}
                      />
                    }
                    label="Send events"
                  />
                )}
              </Field>
              <Field id="sendUserProps" type="checkbox" name="sendUserProps">
                {({ field, form: { errors, touched, values } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={values.sendUserProps}
                        className={classes.checkbox}
                        disabled={isFetching}
                        value={values.sendUserProps}
                      />
                    }
                    label="Send User Props"
                  />
                )}
              </Field>
              <Field id="platforms" type="checkbox" name="platforms">
                {({ field, form: { errors, touched, values } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={get(values, 'platforms', []).includes(
                            'Amplitude'
                          )}
                          disabled={isFetching}
                          className={classes.checkbox}
                          value="Amplitude"
                        />
                      }
                      label="Amplitude"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={get(values, 'platforms', []).includes(
                            'Braze'
                          )}
                          disabled={isFetching}
                          className={classes.checkbox}
                          value="Braze"
                        />
                      }
                      label="Braze"
                    />
                    {errors && errors.platforms && errors.platforms}
                  </FormGroup>
                )}
              </Field>
            </FormGroup>
            <Field>
              {({ field, form: { errors, touched, values } }) => (
                <Button
                  className={classes.button}
                  color="primary"
                  disabled={isFetching || !isEmpty(errors)}
                  size="medium"
                  type="submit"
                  variant="outlined"
                >
                  Create
                </Button>
              )}
            </Field>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

CreateExperiment.propTypes = propTypes;

export default CreateExperiment;

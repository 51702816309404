import React from 'react';
import { Link } from '@reach/router';
import 'typeface-roboto';
import clsx from 'clsx';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routes } from 'utils/general';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Toolbar,
  useTheme,
} from '@mui/material';
import logo from 'assets/logo.svg';
import ErrorBoundary from 'components/ErrorBoundary';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.button': {
      margin: theme.spacing(1),
    },
    '.error': {
      color: '#b00020',
    },
    '.grid-item': {
      marginBottom: theme.spacing(2),
    },
    '.heading': {
      marginTop: theme.spacing(3),
    },
    '.input-textarea': {
      margin: theme.spacing(1),
      width: '100%',
    },
    '.input': {
      margin: theme.spacing(1),
      width: 200,
    },
    '.menu': {
      width: 200,
    },
    '.number-input': {
      marginTop: theme.spacing(3),
    },
    '.root': {
      overflowX: 'auto',
      padding: theme.spacing(3, 2),
      width: '100%',
    },
    '.text': {
      margin: theme.spacing(2, 1),
    },
    '.text-input': {
      margin: theme.spacing(1),
    },
    '.warning': {
      color: '#b00020',
    },
  },
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    height: '100%',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function ListItemLink(props) {
  const { primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <Link to={to} {...itemProps} ref={ref} />
      )),
    [to]
  );

  return (
    <li>
      <ListItemButton component={renderLink}>
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  );
}

function Layout({ children }) {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const {
    hasPermission,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const renderRoute = (route) => {
    const { link, name, scope } = route;
    return (
      hasPermission(scope) && (
        <ListItemLink key={name} to={link} primary={name} />
      )
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          maxHeight: '64px',
          backgroundColor: 'black',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img alt="burner logo" src={logo} height="30px" />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List dense>
          {isAuthenticated ? (
            <ListItemButton onClick={() => logoutWithRedirect()}>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          ) : (
            <ListItemButton onClick={() => loginWithRedirect({})}>
              <ListItemText primary="Sign In" />
            </ListItemButton>
          )}
        </List>
        <Divider />
        {isAuthenticated && (
          <List dense>
            {routes && routes.map((route) => renderRoute(route))}
          </List>
        )}
      </Drawer>
      <Box
        component="main"
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 0 }}
      >
        <Toolbar />
        <Paper className="root">
          <ErrorBoundary>{children}</ErrorBoundary>
        </Paper>
      </Box>
    </div>
  );
}

export default Layout;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

const propTypes = {
  closeSnackbar: PropTypes.func.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.any,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  error: {
    color: '#f50057',
    marginRight: theme.spacing(1),
  },
  message: {
    alignItems: 'center',
    display: 'flex',
  },
}));

function MessagingService({ closeSnackbar, message }) {
  const { isError, isOpen, text } = message;
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    closeSnackbar();
  };

  return (
    <Snackbar
      action={[
        <IconButton
          aria-label="close"
          className={classes.close}
          color="inherit"
          key="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      autoHideDuration={6000}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <span
          className={clsx(classes.message, isError && classes.error)}
          id="message-id"
        >
          {isError && <ErrorOutline className={classes.error} />}
          {text}
        </span>
      }
      onClose={handleClose}
      open={isOpen}
    />
  );
}

MessagingService.propTypes = propTypes;

export default MessagingService;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { from } from 'rxjs';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import {
  cleanPhone,
  countries,
  formatE164IfValidPhoneNumber,
} from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    margin: theme.spacing(1),
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
}));

function SendMessage({ data, handleSubmit, isFetching }) {
  const { getTokenSilently, logout } = useAuth0();
  const initialState = {
    shouldAttachImage: false,
  };
  const [state, setState] = useState(initialState);
  const { shouldAttachImage } = state;
  const classes = useStyles();
  const { id } = data;

  const initialValues = {
    fromCountry: 'US',
    fromNumber: id,
    text: '',
    toCountry: 'US',
    toNumber: '',
  };

  const handleChange = (name) => (event) => {
    setState({ [name]: event.target.checked });
  };

  const onComplete = (resetForm) => {
    resetForm(initialValues);
    setState(initialState);
  };

  return (
    !isEmpty(data) && (
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5">
          Want to send a message?
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            from(getTokenSilently()).subscribe({
              next: (token) =>
                handleSubmit({
                  message: values.text,
                  phoneNumber: formatE164IfValidPhoneNumber(
                    values.fromNumber,
                    values.fromCountry
                  ),
                  recipient: formatE164IfValidPhoneNumber(
                    values.toNumber,
                    values.toCountry
                  ),
                  token,
                }),
              error: () => logout({ returnTo: window.location.origin }),
              complete: () => onComplete(resetForm),
            });
          }}
          validate={(values) => {
            const errors = {};
            if (!values.fromNumber) {
              errors.fromNumber = '* Required';
            } else if (!values.toNumber) {
              errors.toNumber = '* Required';
            } else if (!values.text) {
              errors.text = '* Required';
            } else if (
              !formatE164IfValidPhoneNumber(
                values.fromNumber,
                values.fromCountry
              )
            ) {
              errors.fromNumber = '* Invalid phone number';
            } else if (
              !formatE164IfValidPhoneNumber(values.toNumber, values.toCountry)
            ) {
              errors.toNumber = '* Invalid phone number';
            }
            return errors;
          }}
        >
          {() => (
            <Form>
              <Field name="fromNumber">
                {({ field, form: { errors, touched, values } }) => (
                  <TextField
                    {...field}
                    className="input"
                    disabled={isFetching}
                    error={errors.fromNumber && touched.fromNumber}
                    helperText={
                      errors.fromNumber &&
                      touched.fromNumber &&
                      errors.fromNumber
                    }
                    id="fromNumber"
                    InputProps={
                      errors.fromNumber &&
                      touched.fromNumber && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <ErrorOutline className="error" />
                          </InputAdornment>
                        ),
                      }
                    }
                    label="From*"
                    margin="dense"
                    type="text"
                    value={cleanPhone(values.fromNumber)}
                    variant="standard"
                  />
                )}
              </Field>
              <Field name="fromCountry">
                {({ field }) => (
                  <TextField
                    {...field}
                    className="input"
                    disabled={isFetching}
                    label="Country"
                    margin="dense"
                    select
                    SelectProps={{
                      MenuProps: {
                        className: 'menu',
                      },
                    }}
                    variant="standard"
                  >
                    {countries &&
                      countries.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Field>
              <Box>
                <Field name="toNumber">
                  {({ field, form: { errors, touched, values } }) => (
                    <TextField
                      {...field}
                      className="input"
                      disabled={isFetching}
                      error={errors.toNumber && touched.toNumber}
                      helperText={
                        errors.toNumber && touched.toNumber && errors.toNumber
                      }
                      id="toNumber"
                      InputProps={
                        errors.toNumber &&
                        touched.toNumber && {
                          endAdornment: (
                            <InputAdornment position="end">
                              <ErrorOutline className="error" />
                            </InputAdornment>
                          ),
                        }
                      }
                      label="To*"
                      margin="dense"
                      type="text"
                      value={cleanPhone(values.toNumber)}
                      variant="standard"
                    />
                  )}
                </Field>
                <Field name="toCountry">
                  {({ field }) => (
                    <TextField
                      {...field}
                      className="input"
                      disabled={isFetching}
                      label="Country"
                      margin="dense"
                      select
                      SelectProps={{
                        MenuProps: {
                          className: 'menu',
                        },
                      }}
                      variant="standard"
                    >
                      {countries &&
                        countries.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                </Field>
              </Box>
              <Field name="text">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    {...field}
                    className="text-input"
                    disabled={isFetching}
                    error={errors.text && touched.text}
                    fullWidth
                    helperText={errors.text && touched.text && errors.text}
                    id="text"
                    InputProps={
                      errors.text &&
                      touched.text && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <ErrorOutline className="error" />
                          </InputAdornment>
                        ),
                      }
                    }
                    label="Message"
                    margin="normal"
                    multiline
                    rows="4"
                    variant="outlined"
                  />
                )}
              </Field>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shouldAttachImage}
                    className={classes.checkbox}
                    disabled={isFetching}
                    onChange={handleChange('shouldAttachImage')}
                    value="shouldAttachImage"
                  />
                }
                label="Attach placeholder image?"
              />
              <Button
                className={classes.button}
                color="primary"
                disabled={isFetching}
                size="medium"
                type="submit"
                variant="outlined"
              >
                Send
              </Button>
            </Form>
          )}
        </Formik>
      </Grid>
    )
  );
}

SendMessage.propTypes = propTypes;

export default SendMessage;

import { connect } from 'react-redux';
import {
  createExperimentRequest,
  getExperimentsData,
  fetchExperimentsFailure,
  fetchExperimentsRequest,
  handleCloseSnackbar,
} from 'ducks/view-experiments';
import ViewExperiments from 'components/pages/ViewExperiments';

const mapStateToProps = (state) => {
  const { isFetching, message } = state.viewExperiments;

  return {
    experimentsData: getExperimentsData(state),
    isFetching,
    message,
  };
};

const mapDispatchToProps = {
  createExperimentRequest,
  fetchExperimentsFailure,
  fetchExperimentsRequest,
  handleCloseSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewExperiments);

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const propTypes = {
  previouslyOwnedPhoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      dateCreated: PropTypes.string,
      dateReleased: PropTypes.string,
      phoneNumber: PropTypes.string,
    })
  ),
};

function PreviouslyOwnedPhoneNumbers({ previouslyOwnedPhoneNumbers }) {
  return (
    <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6}>
              {previouslyOwnedPhoneNumbers &&
              previouslyOwnedPhoneNumbers.length === 1
                ? '1 Previously owned phone number'
                : `${previouslyOwnedPhoneNumbers.length} Previously owned phone numbers`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1}>Phone Number</TableCell>
            <TableCell colSpan={1}>Created</TableCell>
            <TableCell colSpan={1}>Expired/Burned</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {previouslyOwnedPhoneNumbers &&
            previouslyOwnedPhoneNumbers.map((burner) => (
              <TableRow key={burner.phoneNumber}>
                <TableCell>{burner.phoneNumber}</TableCell>
                <TableCell>{burner.dateCreated}</TableCell>
                <TableCell>{burner.dateReleased}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

PreviouslyOwnedPhoneNumbers.propTypes = propTypes;

export default PreviouslyOwnedPhoneNumbers;

import React from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  isFetching: PropTypes.bool.isRequired,
  sendVerifyRequest: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    phoneNumber: PropTypes.string,
  }).isRequired,
  verifyCode: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

function VerifyCode({ isFetching, sendVerifyRequest, userInfo, verifyCode }) {
  const { getTokenSilently, logout } = useAuth0();
  const { phoneNumber } = userInfo;
  const { code } = verifyCode;

  return (
    <Grid item sm={12} md={4}>
      <Typography className="heading" gutterBottom variant="h5">
        Verify Code
      </Typography>
      <Divider />
      <Box mt={3}>
        <Typography
          className="button"
          color="error"
          display="inline"
          gutterBottom
          variant="h6"
        >
          {code}
        </Typography>
        <Button
          className="button"
          color="primary"
          disabled={isFetching || !phoneNumber}
          onClick={() =>
            from(getTokenSilently()).subscribe({
              next: (token) =>
                sendVerifyRequest({
                  phoneNumber,
                  token,
                }),
              error: () => logout({ returnTo: window.location.origin }),
            })
          }
          size="medium"
          type="submit"
          variant="outlined"
        >
          {code === 'None' ? 'Send Verify Code' : 'Resend Verify Code'}
        </Button>
      </Box>
    </Grid>
  );
}

VerifyCode.propTypes = propTypes;

export default VerifyCode;

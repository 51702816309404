import { connect } from 'react-redux';
import {
  getBlacklistInfo,
  getUserInfo,
  toggleSampleRequest,
} from 'ducks/view-user-info';
import DetailsSection from 'components/pages/view-user-info/details/DetailsSection';

const mapStateToProps = (state) => ({
  blacklistInfo: getBlacklistInfo(state),
  userInfo: getUserInfo(state),
});

const mapDispatchToProps = { toggleSampleRequest };

export default connect(mapStateToProps, mapDispatchToProps)(DetailsSection);

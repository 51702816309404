import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { rateLimits } from 'utils/clear-rate-limit';
import {
  countries,
  formatE164IfValidPhoneNumber,
  phoneOrIdSchema,
} from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function ClearRateLimitsInput({ handleSubmit, isFetching }) {
  const { getTokenSilently, logout } = useAuth0();
  const initialValues = {
    country: 'US',
    phoneNumberOrId: '',
    rateLimitType: 'verifyCode',
  };

  return (
    <Grid item xs={12}>
      <Typography data-testid="title-test" gutterBottom variant="h4">
        Clear Rate Limits
      </Typography>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const phoneNumber = formatE164IfValidPhoneNumber(
            values.phoneNumberOrId,
            values.country
          );
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                phoneNumberOrId: phoneNumber
                  ? phoneNumber
                  : values.phoneNumberOrId,
                rateLimitType: values.rateLimitType,
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
          });
        }}
        validationSchema={phoneOrIdSchema}
      >
        {() => (
          <Form className="number-input">
            <Field name="phoneNumberOrId">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  error={errors.phoneNumberOrId && touched.phoneNumberOrId}
                  helperText={
                    errors.phoneNumberOrId &&
                    touched.phoneNumberOrId &&
                    errors.phoneNumberOrId
                  }
                  id="phoneNumberOrId"
                  InputProps={
                    errors.phoneNumberOrId &&
                    touched.phoneNumberOrId && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ErrorOutline className="error" />
                        </InputAdornment>
                      ),
                    }
                  }
                  label="Phone Number or ID*"
                  margin="normal"
                  type="text"
                  variant="standard"
                />
              )}
            </Field>
            <Field name="country">
              {({ field }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  label="Country"
                  margin="normal"
                  select
                  variant="standard"
                >
                  {countries &&
                    countries.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Field>
            <Field name="rateLimitType">
              {({ field }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  label="Rate Limit"
                  margin="normal"
                  select
                  variant="standard"
                >
                  {rateLimits &&
                    rateLimits.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Field>
            <Button
              className="button"
              color="primary"
              disabled={isFetching}
              size="medium"
              type="submit"
              variant="outlined"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
      <p>
        Note: VerifyCode rate limit requires a phone number, all others require
        a User ID.
      </p>
    </Grid>
  );
}

ClearRateLimitsInput.propTypes = propTypes;

export default ClearRateLimitsInput;

import { connect } from 'react-redux';
import { updateSettingsRequest } from 'ducks/view-burner';
import ChangeExpirationDate from 'components/pages/view-burner/ChangeExpirationDate';

const mapStateToProps = (state) => {
  const { burnerInfo, isFetching } = state.viewBurner;

  return {
    burnerInfo,
    isFetching,
  };
};

const mapDispatchToProps = {
  handleSubmit: updateSettingsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeExpirationDate);

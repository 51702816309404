import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MessagingService from 'components/MessagingService';
import View from 'components/View';
import LogContextSearch from 'components/pages/log-context/LogContextSearch';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LogContextInfoTable from './LogContextInfo';

const propTypes = {
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  fetchLogContextRequest: PropTypes.func.isRequired,
  contextIdParam: PropTypes.string,
  logContextData: PropTypes.arrayOf(
    PropTypes.shape({
      contextId: PropTypes.string,
      message: PropTypes.string,
      service: PropTypes.string,
      time: PropTypes.string,
    })
  ),
};

function LogContextSection({
  handleCloseSnackbar,
  isFetching,
  message,
  fetchLogContextRequest,
  contextIdParam = null,
  logContextData,
}) {
  const { hasPermission } = useAuth0();
  return hasPermission(routeScope('logContext')) ? (
    <View>
      <Grid item xs={12}>
        <Typography
          component="h1"
          data-testid="title-test"
          gutterBottom
          variant="h4"
        >
          Search Logs by Context ID
        </Typography>
        <Divider />
        <LogContextSearch
          handleSubmit={fetchLogContextRequest}
          isFetching={isFetching}
          contextIdParam={contextIdParam}
        />
      </Grid>
      {!isEmpty(logContextData) && (
        <>
          <LogContextInfoTable logContextData={logContextData} />
        </>
      )}

      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

LogContextSection.propTypes = propTypes;

export default LogContextSection;

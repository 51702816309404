import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      areaCode: PropTypes.number,
      id: PropTypes.string,
      isAvailable: PropTypes.string,
      serviceProvider: PropTypes.string,
    })
  ),
  modalIsOpen: PropTypes.bool.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
  setNumberToProvision: PropTypes.func.isRequired,
};

const useStyles = makeStyles(() => ({
  button: {
    margin: 0,
  },
}));

function AvailablePhoneNumbers({ data, setModalIsOpen, setNumberToProvision }) {
  const classes = useStyles();

  const handleClick = (phoneNumber) => {
    setNumberToProvision(phoneNumber);
    setModalIsOpen(true);
  };

  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Available Phone Numbers
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={5}>
              {data && data.length === 1
                ? '1 available number'
                : `${data.length} available numbers`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phone Number</TableCell>
            <TableCell>Area Code</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Available</TableCell>
            <TableCell>Provision</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.map((number) => (
              <TableRow key={number.id}>
                <TableCell>{number.id}</TableCell>
                <TableCell>{number.areaCode}</TableCell>
                <TableCell>{number.serviceProvider}</TableCell>
                <TableCell>{number.isAvailable}</TableCell>
                <TableCell>
                  {
                    <Button
                      className={classes.button}
                      color="primary"
                      onClick={() => handleClick(number.id)}
                      size="medium"
                      type="button"
                      variant="outlined"
                    >
                      Provision
                    </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

AvailablePhoneNumbers.propTypes = propTypes;

export default AvailablePhoneNumbers;

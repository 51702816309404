import { connect } from 'react-redux';
import {
  provisionNumberRequest,
  setModalIsOpen,
  setNumberToProvision,
} from 'ducks/provision-number';
import ProvisionNumberModal from 'components/pages/provision-number/ProvisionNumberModal';

const mapStateToProps = (state) => {
  const { isFetching, modalIsOpen, numberToProvision } = state.provisionNumber;

  return {
    isFetching,
    modalIsOpen,
    numberToProvision,
  };
};

const mapDispatchToProps = {
  handleSubmit: provisionNumberRequest,
  setModalIsOpen,
  setNumberToProvision,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProvisionNumberModal);

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { burnerIdSchema } from 'utils/view-user-info';

const propTypes = {
  addBurnerRequest: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  removeBurnerRequest: PropTypes.func.isRequired,
  subscriptionToEdit: PropTypes.shape({
    burnerIds: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
  }).isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

function CurrentBurnersAssigned({
  addBurnerRequest,
  handleOpen,
  isFetching,
  removeBurnerRequest,
  subscriptionToEdit,
  userInfo,
}) {
  const { burnerIds, id } = subscriptionToEdit;
  const { getTokenSilently, logout } = useAuth0();
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography variant="h6">Current list of Burners assigned.</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={1}>Burner ID</TableCell>
            <TableCell colSpan={1}>Remove</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {burnerIds &&
            burnerIds.map((burnerId) => (
              <TableRow key={burnerId}>
                <TableCell>{burnerId}</TableCell>
                <TableCell size="small">
                  <IconButton
                    className="button"
                    aria-label="delete"
                    onClick={() =>
                      from(getTokenSilently()).subscribe({
                        next: (token) =>
                          removeBurnerRequest({
                            burnerId,
                            subscriptionId: id,
                            token,
                            userId: userInfo.id,
                          }),
                        error: () =>
                          logout({ returnTo: window.location.origin }),
                        complete: () => handleOpen(false),
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <Formik
        initialValues={{ burnerId: '' }}
        onSubmit={(values) => {
          from(getTokenSilently()).subscribe({
            next: (token) =>
              addBurnerRequest({
                burnerId: values.burnerId,
                subscriptionId: id,
                token,
                userId: userInfo.id,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => handleOpen(false),
          });
        }}
        validationSchema={burnerIdSchema}
      >
        {() => (
          <Form>
            <Box alignItems="baseline" display="flex" flexWrap="wrap" mb={1}>
              <Field name="burnerId">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    {...field}
                    className="input"
                    disabled={isFetching}
                    error={errors.burnerId && touched.burnerId}
                    helperText={
                      errors.burnerId && touched.burnerId && errors.burnerId
                    }
                    id="burnerId"
                    InputProps={
                      errors.burnerId &&
                      touched.burnerId && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <ErrorOutline className="error" />
                          </InputAdornment>
                        ),
                      }
                    }
                    label="Add Burner (ID/Phone)"
                    margin="dense"
                    type="text"
                    variant="standard"
                  />
                )}
              </Field>
              <Button
                color="primary"
                disabled={isFetching}
                size="medium"
                className="button"
                type="submit"
              >
                <AddIcon
                  className={clsx(classes.leftIcon, classes.iconSmall)}
                />
                Add
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

CurrentBurnersAssigned.propTypes = propTypes;

export default CurrentBurnersAssigned;

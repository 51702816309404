import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link as RouterLink } from '@reach/router';

const propTypes = {
  burners: PropTypes.arrayOf(
    PropTypes.shape({
      dates: PropTypes.shape({
        dateCreated: PropTypes.string,
        expirationDate: PropTypes.string,
      }),
      id: PropTypes.string,
      name: PropTypes.string,
      numberType: PropTypes.string,
      phoneNumberId: PropTypes.string,
      usage: PropTypes.shape({
        remainingMinutes: PropTypes.number,
        remainingTexts: PropTypes.number,
      }),
    })
  ).isRequired,
};

function ActiveBurners({ burners }) {
  return (
    <Grid item xs={12}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={7}>
              {burners && burners.length === 1
                ? '1 Active Burner'
                : `${burners.length} Active Burners`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1}>Name</TableCell>
            <TableCell colSpan={1}>Phone Number</TableCell>
            <TableCell colSpan={1}>Number Type</TableCell>
            <TableCell colSpan={1}>Created</TableCell>
            <TableCell colSpan={1}>Expires</TableCell>
            <TableCell colSpan={1}>Texts</TableCell>
            <TableCell colSpan={1}>Minutes</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {burners &&
            burners.map((burner) => (
              <TableRow key={burner.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/dashboard/view-burner/${burner.id}`}
                  >
                    {burner.name}
                  </Link>
                </TableCell>
                <TableCell>{burner.phoneNumberId}</TableCell>
                <TableCell>{burner.numberType}</TableCell>
                <TableCell>{burner.dates.dateCreated}</TableCell>
                <TableCell>{burner.dates.expirationDate}</TableCell>
                <TableCell>{burner.usage.remainingTexts}</TableCell>
                <TableCell>{burner.usage.remainingMinutes}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

ActiveBurners.propTypes = propTypes;

export default ActiveBurners;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { of } from 'rxjs';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

function PrivateRoute({ component: Component, path, ...rest }) {
  const { isAuthenticated, loading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) return;

    const path$ = of(path).subscribe({
      next: (url) => loginWithRedirect({ appState: { targetUrl: url } }),
      error: (error) => console.error(`Unable to login ${error}`),
    });

    return () => path$.unsubscribe();
  }, [isAuthenticated, loading, loginWithRedirect, path]);

  return isAuthenticated && <Component {...rest} />;
}

PrivateRoute.propTypes = propTypes;

export default PrivateRoute;

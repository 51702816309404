import { connect } from 'react-redux';
import { getNotes, getUserInfo, createNoteRequest } from 'ducks/view-user-info';
import InternalNotesSection from 'components/pages/view-user-info/internal-notes/InternalNotesSection';

const mapStateToProps = (state) => {
  const { isFetching } = state.viewUser;

  return {
    isFetching,
    notes: getNotes(state),
    userInfo: getUserInfo(state),
  };
};

const mapDispatchToProps = {
  createNoteRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalNotesSection);

import { addDays, getTime } from 'date-fns';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';
import { formatDate } from 'utils/general';

export const numberSchema = Yup.object().shape({
  texts: Yup.number()
    .integer('* Must be an integer')
    .min(0, '* Must be positive')
    .required('* Required'),
  minutes: Yup.number()
    .integer('* Must be an integer')
    .min(0, '* Must be positive')
    .required('* Required'),
});

export const daysSchema = Yup.object().shape({
  days: Yup.number()
    .integer('* Must be an integer')
    .required('* Required'),
});

export const stringify = (featuresArray) =>
  featuresArray
    .filter((feature) => feature.isEnabled === true)
    .map((feature) => feature.label)
    .join(', ');

export const changeExpirationDate = (expirationDate, days) => {
  return getTime(addDays(expirationDate, days));
};

export const features = [
  {
    featureName: 'free',
    id: 11,
    isEnabled: false,
    label: 'Free',
  },
  {
    featureName: 'mms',
    id: 5,
    isEnabled: false,
    label: 'MMS',
  },
  {
    featureName: 'renewsManually',
    id: 9,
    isEnabled: false,
    label: 'Renews Manually',
  },
  {
    featureName: 'textOnly',
    id: 8,
    isEnabled: false,
    label: 'Text Only',
  },
  {
    featureName: 'unlimitedUsage',
    id: 4,
    isEnabled: false,
    label: 'Unlimited Usage',
  },
];

export const enrichBurnerInfo = (burnerInfo) =>
  isEmpty(burnerInfo)
    ? burnerInfo
    : {
        ...burnerInfo,
        dates: {
          ...burnerInfo.dates,
          dateCreated: formatDate(burnerInfo.dates.dateCreated),
          expirationDate: formatDate(burnerInfo.dates.expirationDate),
          lastUpdatedDate: formatDate(burnerInfo.dates.lastUpdatedDate),
        },
        featuresString: stringify(burnerInfo.features),
      };

export const enrichPhoneNumberInfo = (phoneNumberInfo) =>
  isEmpty(phoneNumberInfo)
    ? phoneNumberInfo
    : {
        ...phoneNumberInfo,
        serviceProvider: capitalize(phoneNumberInfo.serviceProvider),
      };

export const getEnabledFeatures = (features) =>
  features
    .filter((feature) => feature.isEnabled === true)
    .map((feature) => ({
      id: feature.id,
      featureName: feature.featureName,
    }));

export const transformBurnerHasSubscription = (burnerInfo, subscription) =>
  isEmpty(burnerInfo) || isEmpty(subscription)
    ? false
    : subscription.burnerIds.includes(burnerInfo.id);

export const transformNumberHistory = (numberHistory) => {
  const hasHistory = !isEmpty(numberHistory);
  return {
    showNumberHistory: hasHistory,
    numberHistory: {
      previouslyOwned: String(hasHistory),
    },
  };
};

import { connect } from 'react-redux';
import {
  addBurnerRequest,
  editSubscriptionDialogOpen,
  getUserInfo,
  removeBurnerRequest,
  setBurnersAssignedRequest,
  validateSubscriptionRequest,
} from 'ducks/view-user-info';
import EditSubscriptionModal from 'components/pages/view-user-info/subscription-details/EditSubscriptionModal';

const mapStateToProps = (state) => {
  const {
    editSubscriptionDialogIsOpen,
    isFetching,
    refreshSubHasBeenClicked,
    subscriptionToEdit,
  } = state.viewUser;

  return {
    isFetching,
    isOpen: editSubscriptionDialogIsOpen,
    refreshSubHasBeenClicked,
    subscriptionToEdit,
    userInfo: getUserInfo(state),
  };
};

const mapDispatchToProps = {
  addBurnerRequest,
  handleOpen: editSubscriptionDialogOpen,
  removeBurnerRequest,
  setBurnersAssignedRequest,
  validateSubscriptionRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSubscriptionModal);

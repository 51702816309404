import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiBox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '8px',
        },
      },
    },
    MUIDataTableBodyCell: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          width: '300px',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        hintIconAlone: {
          alignSelf: 'center',
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 3,
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
  },
});

export default theme;

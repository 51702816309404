import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from '@reach/router';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import ConfirmDialog from 'components/ConfirmDialog';
import SettingsSwitch from 'components/SettingsSwitch';

const propTypes = {
  burnerHasSubscription: PropTypes.bool.isRequired,
  burnerInfo: PropTypes.shape({
    dates: PropTypes.shape({
      dateCreated: PropTypes.string,
      expirationDate: PropTypes.string,
      lastUpdatedDate: PropTypes.string,
    }),
    features: PropTypes.arrayOf(
      PropTypes.shape({
        featureName: PropTypes.string,
        id: PropTypes.number,
      })
    ),
    id: PropTypes.string,
    name: PropTypes.string,
    numberType: PropTypes.string,
    phoneNumberId: PropTypes.string,
    usage: PropTypes.shape({
      remainingMinutes: PropTypes.number,
      remainingTexts: PropTypes.number,
      totalMinutes: PropTypes.number,
      totalTexts: PropTypes.number,
    }),
    userId: PropTypes.string,
    voicemailUrl: PropTypes.string,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  handleNotificationsChange: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleRingerChange: PropTypes.func.isRequired,
  handleSipChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  phoneNumberInfo: PropTypes.shape({
    serviceProvider: PropTypes.string,
  }),
  settingsObject: PropTypes.shape({
    notifications: PropTypes.bool,
    ringer: PropTypes.bool,
    useSip: PropTypes.bool,
  }).isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    sku: PropTypes.string,
  }),
  updateSettingsRequest: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({ phoneNumber: PropTypes.string }).isRequired,
};

function BurnerDetailsSection({
  burnerHasSubscription,
  burnerInfo,
  handleClick,
  handleNotificationsChange,
  handleOpen,
  handleRingerChange,
  handleSipChange,
  isOpen,
  phoneNumberInfo,
  settingsObject,
  subscription = {},
  updateSettingsRequest,
  userInfo,
}) {
  const {
    dates,
    id,
    name,
    numberType,
    phoneNumberId,
    usage,
    userId,
    voicemailUrl,
  } = burnerInfo;
  const { serviceProvider } = phoneNumberInfo;
  const { notifications, ringer, useSip } = settingsObject;
  const { getTokenSilently, logout } = useAuth0();
  const previousBurnerSettings = usePrevious(settingsObject);
  const { phoneNumber } = userInfo;

  useEffect(() => {
    if (isEmpty(previousBurnerSettings) || isEmpty(settingsObject)) return;

    const token$ = from(getTokenSilently()).subscribe({
      next: (token) =>
        updateSettingsRequest({
          settingsObject,
          token,
        }),
      error: () => logout({ returnTo: window.location.origin }),
    });

    return () => token$.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getTokenSilently,
    logout,
    notifications,
    ringer,
    updateSettingsRequest,
    useSip,
  ]);

  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Burner Details
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Burner ID</TableCell>
            <TableCell>{id}</TableCell>
            <TableCell variant="head">Burner Phone</TableCell>
            <TableCell>{phoneNumberId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">User Phone</TableCell>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/dashboard/view-user/${userId}`}
              >
                {phoneNumber}
              </Link>
            </TableCell>
            <TableCell variant="head">User ID</TableCell>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/dashboard/view-user/${userId}`}
              >
                {userId}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Carrier</TableCell>
            <TableCell>{serviceProvider}</TableCell>
            <TableCell variant="head">Number Type</TableCell>
            <TableCell>{numberType}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Burner Name</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell variant="head">Voicemail URL</TableCell>
            <TableCell>{voicemailUrl}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Total texts</TableCell>
            <TableCell>{usage.totalTexts}</TableCell>
            <TableCell variant="head">Total minutes</TableCell>
            <TableCell>{usage.totalMinutes}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Remaining texts</TableCell>
            <TableCell>{usage.remainingTexts}</TableCell>
            <TableCell variant="head">Remaining minutes</TableCell>
            <TableCell>{usage.remainingMinutes}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Created</TableCell>
            <TableCell>{dates.dateCreated}</TableCell>
            <TableCell variant="head">Notifications</TableCell>
            <TableCell>
              <SettingsSwitch
                handleChange={() => handleNotificationsChange(!notifications)}
                isChecked={notifications}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Last updated</TableCell>
            <TableCell>{dates.lastUpdatedDate}</TableCell>
            <TableCell variant="head">Ringer</TableCell>
            <TableCell>
              <SettingsSwitch
                handleChange={() => handleRingerChange(!ringer)}
                isChecked={ringer}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Expires</TableCell>
            <TableCell>{dates.expirationDate}</TableCell>
            <TableCell variant="head">In-App Calling</TableCell>
            <TableCell>
              <SettingsSwitch
                handleChange={() => handleSipChange(!useSip)}
                isChecked={useSip}
              />
            </TableCell>
          </TableRow>
          {burnerHasSubscription && (
            <TableRow>
              <TableCell variant="head">Subscription SKU</TableCell>
              <TableCell colSpan={2}>{subscription.sku}</TableCell>
              <TableCell>
                <Button
                  className="button"
                  color="error"
                  onClick={() => handleOpen(true)}
                  variant="outlined"
                >
                  Remove From Subscription
                </Button>
                <ConfirmDialog
                  content={`Are you sure you want to remove ${name} from the subscription?`}
                  dialogIsOpen={isOpen}
                  handleClick={() =>
                    from(getTokenSilently()).subscribe({
                      next: (token) =>
                        handleClick({
                          burnerId: id,
                          subscriptionId: subscription.id,
                          token,
                          userId,
                        }),
                      error: () => logout({ returnTo: window.location.origin }),
                      complete: () => handleOpen(false),
                    })
                  }
                  handleClose={() => handleOpen(false)}
                  title="Remove From Subscription"
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Grid>
  );
}

BurnerDetailsSection.propTypes = propTypes;

export default BurnerDetailsSection;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { countries, formatIdentifierIfPhoneNumber } from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  countryParam: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  phoneOrEmailParam: PropTypes.string,
  startDateParam: PropTypes.string,
  endDateParam: PropTypes.string,
};

function ViewLegalInput({
  handleSubmit,
  isFetching,
  phoneOrEmailParam = '',
  countryParam = '',
  startDateParam = '',
  endDateParam = '',
}) {
  const { getTokenSilently, logout } = useAuth0();
  const initialValues = {
    country: 'US',
    phoneNumberOrEmail: '',
    startDate: '',
    endDate: '',
  };

  return (
    <Grid item xs={12}>
      <Typography data-testid="title-test" gutterBottom variant="h4">
        Legal Inquiry
      </Typography>
      <Divider />
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (!values.phoneNumberOrEmail) {
            errors.phoneNumberOrEmail = '* Required';
          } else if (
            !formatIdentifierIfPhoneNumber(
              values.phoneNumberOrEmail,
              values.country
            )
          ) {
            errors.phoneNumberOrEmail = '* Invalid phone or email';
          }

          if (values.startDate && isNaN(Date.parse(values.startDate))) {
            errors.startDate = '* Invalid start date';
          }
          if (values.endDate && isNaN(Date.parse(values.endDate))) {
            errors.endDate = '* Invalid end date';
          }
          if (values.startDate && values.endDate) {
            const start = Date.parse(values.startDate);
            const end = Date.parse(values.endDate);
            if (!isNaN(start) && !isNaN(end) && start > end) {
              errors.endDate = '* End date must be after start date';
            }
          }
          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          const { country, phoneNumberOrEmail, startDate, endDate } = values;

          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                country,
                phoneNumberOrEmail: formatIdentifierIfPhoneNumber(
                  phoneNumberOrEmail,
                  country
                ),
                // Adding a two-day buffer to make sure people get the full date
                // range they intend to - e.g. if someone puts the end date as
                // 1/1/2024, they won't get any info from 1/1/2024 since the date
                // will be midnight that day. Made the buffer two days to account
                // for UTC time differences potentially messing things up.
                startDate: startDate ? Date.parse(startDate) - 172800000 : null,
                endDate: endDate ? Date.parse(endDate) + 172800000 : null,
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => {
              const resetValues = {
                values: {
                  country: 'US',
                  phoneNumberOrEmail: '',
                  startDate: startDate,
                  endDate: endDate,
                },
              };
              resetForm(resetValues);
            },
          });
        }}
      >
        {() => (
          <Form className="input">
            <Field name="phoneNumberOrEmail">
              {({ field, form: { errors, touched, values } }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  error={
                    errors.phoneNumberOrEmail && touched.phoneNumberOrEmail
                  }
                  helperText={
                    errors.phoneNumberOrEmail &&
                    touched.phoneNumberOrEmail &&
                    errors.phoneNumberOrEmail
                  }
                  id="phoneNumberOrEmail"
                  InputProps={
                    errors.phoneNumberOrEmail &&
                    touched.phoneNumberOrEmail && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ErrorOutline className="error" />
                        </InputAdornment>
                      ),
                    }
                  }
                  label="Phone Number or Email*"
                  margin="normal"
                  type="text"
                  variant="standard"
                  value={values.phoneNumberOrEmail}
                />
              )}
            </Field>
            <Field name="country">
              {({ field }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  label="Country"
                  margin="normal"
                  select
                  variant="standard"
                >
                  {countries &&
                    countries.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Field>
            <Field name="startDate">
              {({ field, form: { errors, touched, values } }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  error={errors.startDate && touched.startDate}
                  helperText={
                    errors.startDate && touched.startDate && errors.startDate
                  }
                  id="startDate"
                  InputProps={
                    errors.startDate &&
                    touched.startDate && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ErrorOutline className="error" />
                        </InputAdornment>
                      ),
                    }
                  }
                  label="Start Date"
                  margin="normal"
                  type="text"
                  variant="standard"
                  value={values.startDate}
                />
              )}
            </Field>
            <Field name="endDate">
              {({ field, form: { errors, touched, values } }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  error={errors.endDate && touched.endDate}
                  helperText={
                    errors.endDate && touched.endDate && errors.endDate
                  }
                  id="startDate"
                  InputProps={
                    errors.endDate &&
                    touched.endDate && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ErrorOutline className="error" />
                        </InputAdornment>
                      ),
                    }
                  }
                  label="End Date"
                  margin="normal"
                  type="text"
                  variant="standard"
                  value={values.endDate}
                />
              )}
            </Field>
            <Button
              className="button"
              color="primary"
              disabled={isFetching}
              size="medium"
              type="submit"
              variant="outlined"
            >
              Export
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

ViewLegalInput.propTypes = propTypes;

export default ViewLegalInput;

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const propTypes = {
  blockedContacts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function BlockedNumbersSection({ blockedContacts }) {
  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Blocked Numbers
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6}>
              {blockedContacts && blockedContacts.length === 1
                ? '1 Blocked Number'
                : `${blockedContacts.length} Blocked Numbers`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1}>Phone Number</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {blockedContacts &&
            blockedContacts.map((contact) => (
              <TableRow key={contact}>
                <TableCell>{contact}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

BlockedNumbersSection.propTypes = propTypes;

export default BlockedNumbersSection;

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import BlacklistUserMessage from 'components/pages/blacklist-user/BlacklistUserMessage';
import MessagingService from 'components/MessagingService';
import UserDetails from 'components/pages/blacklist-user/UserDetails';
import View from 'components/View';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';
import UserSearch from './UserSearch';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

const propTypes = {
  blacklistInfo: PropTypes.shape({
    isBlacklisted: PropTypes.string,
    reason: PropTypes.string,
  }).isRequired,
  blacklistUserDialogIsOpen: PropTypes.bool.isRequired,
  blacklistUserRequest: PropTypes.func.isRequired,
  getUserInfoRequest: PropTypes.func.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  setBlacklistUserDialogIsOpen: PropTypes.func.isRequired,
  userIdentifierParam: PropTypes.string,
  userInfo: PropTypes.shape({
    countryCode: PropTypes.string,
    createdSample: PropTypes.string,
    credits: PropTypes.number,
    dateCreated: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string,
    lastUpdatedDate: PropTypes.string,
    phoneNumber: PropTypes.string,
    platformName: PropTypes.string,
    totalNumberBurners: PropTypes.number,
  }).isRequired,
};

function BlacklistUser({
  blacklistInfo,
  blacklistUserDialogIsOpen,
  blacklistUserRequest,
  getUserInfoRequest,
  handleCloseSnackbar,
  isFetching,
  message,
  setBlacklistUserDialogIsOpen,
  userIdentifierParam = null,
  userInfo,
}) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('blacklistUser')) ? (
    <View>
      <Grid item xs={12}>
        <Typography
          component="h1"
          data-testid="title-test"
          gutterBottom
          variant="h4"
        >
          Add/Remove From Blacklist
        </Typography>
        <Divider />
        <UserSearch
          handleSubmit={getUserInfoRequest}
          isFetching={isFetching}
          userIdentifierParam={userIdentifierParam}
        />
      </Grid>
      {!isEmpty(userInfo) && (
        <>
          <UserDetails blacklistInfo={blacklistInfo} userInfo={userInfo} />
          <BlacklistUserMessage
            blacklistInfo={blacklistInfo}
            blacklistUserDialogIsOpen={blacklistUserDialogIsOpen}
            blacklistUserRequest={blacklistUserRequest}
            isFetching={isFetching}
            setBlacklistUserDialogIsOpen={setBlacklistUserDialogIsOpen}
            userInfo={userInfo}
          />
        </>
      )}
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

BlacklistUser.propTypes = propTypes;

export default BlacklistUser;

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { countries } from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { countryCodeSchema } from 'utils/view-user-info';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    countryCode: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};

function SetCountryCode({ handleSubmit, isFetching, userInfo }) {
  const { getTokenSilently, logout } = useAuth0();
  const { countryCode, id, phoneNumber } = userInfo;

  return (
    <Grid item sm={12} md={4} className="item">
      <Typography className="heading" gutterBottom variant="h5">
        Set Country Code
      </Typography>
      <Divider />
      <Formik
        initialValues={{ countryCode }}
        onSubmit={(values) => {
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                countryCode: values.countryCode,
                id,
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
          });
        }}
        validationSchema={countryCodeSchema}
      >
        {() => (
          <Form className="number-input">
            <Box alignItems="baseline" display="flex" flexWrap="wrap" mb={1}>
              <Field name="countryCode">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    {...field}
                    className="input"
                    disabled={isFetching}
                    error={errors.countryCode && touched.countryCode}
                    helperText={
                      errors.countryCode &&
                      touched.countryCode &&
                      errors.countryCode
                    }
                    id="countryCode"
                    label="Country Code"
                    margin="dense"
                    select
                    type="number"
                    variant="standard"
                  >
                    {countries &&
                      countries.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Field>
              <Button
                className="button"
                color="primary"
                disabled={isFetching || !phoneNumber}
                size="medium"
                type="submit"
                variant="outlined"
              >
                Set User Country Code
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

SetCountryCode.propTypes = propTypes;

export default SetCountryCode;

import { connect } from 'react-redux';
import { fetchLogContextRequest, handleCloseSnackbar } from 'ducks/log-context';
import LogContextSection from 'components/pages/log-context/LogContextSection';

const mapStateToProps = (state) => {
  const { isFetching, message, logContextData } = state.logContext;

  return {
    isFetching,
    message,
    logContextData,
  };
};

const mapDispatchToProps = {
  fetchLogContextRequest,
  handleCloseSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogContextSection);

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { changeExpirationDate, daysSchema } from 'utils/view-burner';

const propTypes = {
  burnerInfo: PropTypes.shape({
    dates: PropTypes.shape({
      expirationDate: PropTypes.number,
    }),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function ChangeExpirationDate({ burnerInfo, handleSubmit, isFetching }) {
  const initialValues = { days: 0 };
  const { getTokenSilently, logout } = useAuth0();

  return (
    <Grid item sm={12} md={6}>
      <Typography className="heading" gutterBottom variant="h5">
        Change Expiration Date
      </Typography>
      <Divider />
      <Typography className="text" variant="body2">
        Add or remove days from the expiration date of a Burner.
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                settingsObject: {
                  ...burnerInfo,
                  dates: {
                    ...burnerInfo.dates,
                    expirationDate: changeExpirationDate(
                      burnerInfo.dates.expirationDate,
                      values.days
                    ),
                  },
                },
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => resetForm(initialValues),
          });
        }}
        validationSchema={daysSchema}
      >
        {() => (
          <Form>
            <Box alignItems="baseline" display="flex" flexWrap="wrap" mb={1}>
              <Field name="days">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    {...field}
                    className="input"
                    disabled={isFetching}
                    error={errors.days && touched.days}
                    helperText={errors.days && touched.days && errors.days}
                    id="days"
                    InputProps={
                      errors.days &&
                      touched.days && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <ErrorOutlineIcon className="error" />
                          </InputAdornment>
                        ),
                      }
                    }
                    label="Days"
                    margin="dense"
                    type="number"
                    variant="standard"
                  />
                )}
              </Field>
              <Button
                className="button"
                color="primary"
                disabled={isFetching}
                size="medium"
                type="submit"
                variant="outlined"
              >
                Change
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

ChangeExpirationDate.propTypes = propTypes;

export default ChangeExpirationDate;

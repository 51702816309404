import { connect } from 'react-redux';
import {
  accountsToDeleteFailure,
  accountsToDeleteRequest,
  deleteAccountFailure,
  deleteAccountRequest,
  getAccountsToDelete,
  setAccountToDelete,
  setDeleteAccountDialogIsOpen,
} from 'ducks/accounts-to-delete';
import AccountsToDeleteTable from 'components/pages/accounts-to-delete/AccountsToDeleteTable';

const mapStateToProps = (state) => {
  const {
    accountToDelete,
    deleteAccountDialogIsOpen,
    isFetching,
  } = state.accountsToDelete;

  return {
    accountsToDelete: getAccountsToDelete(state),
    accountToDelete,
    deleteAccountDialogIsOpen,
    isFetching,
  };
};

const mapDispatchToProps = {
  deleteAccount: deleteAccountRequest,
  deleteAccountFailure,
  fetchAccountsFailure: accountsToDeleteFailure,
  fetchAccountsToDelete: accountsToDeleteRequest,
  setAccountToDelete,
  setDeleteAccountDialogIsOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsToDeleteTable);

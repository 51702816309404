import React from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const propTypes = {
  validateSubscriptionRequest: PropTypes.func.isRequired,
  refreshSubHasBeenClicked: PropTypes.bool.isRequired,
  subscriptionInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(3),
  },
}));

function RefreshSubscription({
  validateSubscriptionRequest,
  refreshSubHasBeenClicked,
  subscriptionInfo,
}) {
  const { getTokenSilently, logout } = useAuth0();
  const { id } = subscriptionInfo;
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6">
        Refresh subscription data from store.
      </Typography>
      <Button
        color="primary"
        className={classes.button}
        disabled={refreshSubHasBeenClicked}
        onClick={() =>
          from(getTokenSilently()).subscribe({
            next: (token) =>
              validateSubscriptionRequest({
                id,
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
          })
        }
        size="medium"
        type="submit"
        variant="outlined"
      >
        Refresh
      </Button>
    </Grid>
  );
}

RefreshSubscription.propTypes = propTypes;

export default RefreshSubscription;

import { connect } from 'react-redux';
import { updateSettingsRequest } from 'ducks/view-burner';
import AddTextsMinutes from 'components/pages/view-burner/AddTextsMinutes';

const mapStateToProps = (state) => {
  const { burnerInfo, isFetching } = state.viewBurner;

  return {
    burnerInfo,
    isFetching,
  };
};

const mapDispatchToProps = {
  handleSubmit: updateSettingsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTextsMinutes);

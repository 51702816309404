import { connect } from 'react-redux';
import {
  getPinRequest,
  handleCloseSnackbar,
  setPinRequest,
} from 'ducks/port-out-pin';
import PortOutPin from 'components/pages/port-out-pin/PortOutPin';

const mapStateToProps = (state) => {
  const { isFetching, message, data } = state.portOutPin;
  return {
    isFetching,
    message,
    data,
  };
};

const mapDispatchToProps = {
  getPinRequest,
  handleCloseSnackbar,
  setPinRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortOutPin);

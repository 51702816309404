import React from 'react';
import PropTypes from 'prop-types';
import MessagingService from 'components/MessagingService';

const propTypes = {
  closeSnackbar: PropTypes.func.isRequired,
};

function Unauthorized({ closeSnackbar }) {
  const message = {
    isError: true,
    text: 'You are not authorized to view this page',
    isOpen: true,
  };

  return <MessagingService closeSnackbar={closeSnackbar} message={message} />;
}

Unauthorized.propTypes = propTypes;

export default Unauthorized;

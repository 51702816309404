import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const propTypes = {
  timestamp: PropTypes.string.isRequired,
};

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold',
  },
  section: {
    width: '500px',
  },
}));

function NumberCacheAnalyticsInfo({ timestamp }) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography data-testid="title-test" gutterBottom variant="h4">
        Number Cache Analytics
      </Typography>
      <Divider />
      <Typography className="heading" variant="h5">
        {`Latest number cache stats as of: ${timestamp && timestamp}`}
      </Typography>
      <Grid
        className="text"
        component="label"
        container
        justify="space-evenly"
        spacing={1}
      >
        <Grid className={classes.section} item>
          <Typography gutterBottom variant="h6">
            Column Definitions
          </Typography>
          <Typography className={classes.bold} gutterBottom variant="body2">
            Hovering on a column title will give the full explanation for that
            column.
          </Typography>
          <Typography gutterBottom variant="body2">
            Column filter terms are defined in the glossary to the right. Column
            names with multiple terms indicate a value that filters on all
            terms.
          </Typography>
          <Typography gutterBottom variant="body2">
            For example, Available Clean means these numbers meet both the
            Available and the Clean criteria.
          </Typography>
        </Grid>
        <Grid className={classes.section} item>
          <Typography gutterBottom variant="h6">
            Glossary
          </Typography>
          <Typography variant="body2">
            <span className={classes.bold}>Burner: </span>
            Phone numbers attached to a Burner
          </Typography>
          <Typography variant="body2">
            <span className={classes.bold}>Available: </span>
            Phone numbers NOT attached to a Burner and available to attach
          </Typography>
          <Typography variant="body2">
            <span className={classes.bold}>Holding: </span>
            Numbers detached from a Burner in the last 7 days
          </Typography>
          <Typography variant="body2">
            <span className={classes.bold}>New: </span>
            Phone numbers purchased in the last 24 hours
          </Typography>
          <Typography variant="body2">
            <span className={classes.bold}>Detached: </span>
            Phone numbers detached from a Burner in the last 48 hours
          </Typography>
          <Typography variant="body2">
            <span className={classes.bold}>Clean: </span>
            Phone numbers with NO inbound texts/calls in the last 24 hours
          </Typography>
          <Typography variant="body2">
            <span className={classes.bold}>Dirty: </span>
            Phone numbers with at least one inbound text/call in the last 24
            hours
          </Typography>
          <Typography variant="body2">
            <span className={classes.bold}>Pending: </span>
            Phone numbers stuck in Pending Burner status
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

NumberCacheAnalyticsInfo.propTypes = propTypes;

export default NumberCacheAnalyticsInfo;

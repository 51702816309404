import { connect } from 'react-redux';
import {
  blacklistUserRequest,
  getBlacklistInfo,
  getUserInfo,
  getUserInfoRequest,
  handleCloseSnackbar,
  setBlacklistUserDialogIsOpen,
} from 'ducks/blacklist-user';
import BlacklistUser from 'components/pages/BlacklistUser';

const mapStateToProps = (state) => {
  const {
    blacklistUserDialogIsOpen,
    isFetching,
    message,
  } = state.blacklistUser;

  return {
    blacklistInfo: getBlacklistInfo(state),
    blacklistUserDialogIsOpen,
    isFetching,
    message,
    userInfo: getUserInfo(state),
  };
};

const mapDispatchToProps = {
  blacklistUserRequest,
  getUserInfoRequest,
  handleCloseSnackbar,
  setBlacklistUserDialogIsOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlacklistUser);

import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { transformExperimentData } from 'utils/view-experiments';

/*
Actions
*/
export const SET_PIN_FAILURE = 'dashboard/porOutPin/SET_PIN_FAILURE';
export const SET_PIN_REQUEST = 'dashboard/portOutPin/SET_PIN_REQUEST';
export const SET_PIN_SUCCESS = 'dashboard/portOutPin/SET_PIN_SUCCESS';
export const GET_PIN_FAILURE = 'dashboard/portOutPin/GET_PIN_FAILURE';
export const GET_PIN_REQUEST = 'dashboard/portOutPin/GET_PIN_REQUEST';
export const GET_PIN_SUCCESS = 'dashboard/portOutPin/GET_PIN_SUCCESS';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/portOutPin/HANDLE_CLOSE_SNACKBAR';

/*
Action Creators
*/

export function setPinFailure(payload) {
  return { type: SET_PIN_FAILURE, payload };
}

export function setPinRequest(payload) {
  return { type: SET_PIN_REQUEST, payload };
}

export function setPinSuccess(payload) {
  return { type: SET_PIN_SUCCESS, payload };
}

export function getPinFailure(payload) {
  return { type: GET_PIN_FAILURE, payload };
}

export function getPinRequest(payload) {
  return { type: GET_PIN_REQUEST, payload };
}

export function getPinSuccess(payload) {
  return { type: GET_PIN_SUCCESS, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

/*
Reducer
*/
const initialState = {
  data: {},
  isFetching: false,
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case SET_PIN_FAILURE:
      return {
        ...state,
        message: {
          isError: true,
          isOpen: true,
          text: payload,
        },
      };
    case SET_PIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SET_PIN_SUCCESS:
      return {
        ...state,
        message: {
          isError: false,
          isOpen: true,
          text: 'Pin successfully set',
        },
        isFetching: false,
      };
    case GET_PIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: {
          isError: true,
          isFetching: false,
          isOpen: true,
          text: payload,
        },
      };
    case GET_PIN_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case GET_PIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: initialState.message,
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const getPortOutPinEpic = (action$) => {
  const getPinData = (action) => {
    const { phoneNumber, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/phone-numbers/${phoneNumber}/port-out-pin`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(GET_PIN_REQUEST),
    mergeMap((action) =>
      getPinData(action).pipe(
        map((response) =>
          getPinSuccess({
            data: response.response,
          })
        ),
        catchError((error) =>
          of(
            getPinFailure(
              error.status && error.status === 400
                ? 'Could not find pin'
                : 'Something went wrong'
            )
          )
        )
      )
    )
  );
};

export const setPortOutPinEpic = (action$) => {
  const setPin = (action) => {
    const { phoneNumber, pin, token } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/phone-numbers/${phoneNumber}/port-out-pin`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        pin: pin,
      },
    });
  };

  return action$.pipe(
    ofType(SET_PIN_REQUEST),
    mergeMap((action) =>
      setPin(action).pipe(
        map(() => setPinSuccess('Pin successfully set')),
        catchError((error) =>
          of(
            setPinFailure(
              error.response && error.response.message
                ? error.response.message
                : 'Pin could not be set'
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getPortOutPinData = createSelector(
  (state) => state.portOutPin.data,
  (experimentData) => transformExperimentData(experimentData)
);

import isEmpty from 'lodash/isEmpty';
import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { formatE164IfValidPhoneNumber, isEmail } from 'utils/general';
import { toSortedArray, transformPhoneNumber } from 'utils/provision-number';

/*
Actions
*/
export const FETCH_NUMBER_OR_AREA_CODE_FAILURE =
  'dashboard/provisionNumber/FETCH_NUMBER_OR_AREA_CODE_FAILURE';
export const FETCH_NUMBER_OR_AREA_CODE_REQUEST =
  'dashboard/provisionNumber/FETCH_NUMBER_OR_AREA_CODE_REQUEST';
export const FETCH_NUMBER_OR_AREA_CODE_SUCCESS =
  'dashboard/provisionNumber/FETCH_NUMBER_OR_AREA_CODE_SUCCESS';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/provisionNumber/HANDLE_CLOSE_SNACKBAR';
export const PROVISION_NUMBER_FAILURE =
  'dashboard/provisionNumber/PROVISION_NUMBER_FAILURE';
export const PROVISION_NUMBER_REQUEST =
  'dashboard/provisionNumber/PROVISION_NUMBER_REQUEST';
export const RESTORE_BURNER_FAILURE =
  'dashboard/provisionNumber/RESTORE_BURNER_FAILURE';
export const RESTORE_BURNER_REQUEST =
  'dashboard/provisionNumber/RESTORE_BURNER_REQUEST';
export const RESTORE_BURNER_SUCCESS =
  'dashboard/provisionNumber/RESTORE_BURNER_SUCCESS';
export const SET_MODAL_IS_OPEN = 'dashboard/provisionNumber/SET_MODAL_IS_OPEN';
export const SET_NUMBER_TO_PROVISION =
  'dashboard/provisionNumber/SET_NUMBER_TO_PROVISION';

/*
Action Creators
*/
export function fetchNumberOrAreaCodeFailure(payload) {
  return { type: FETCH_NUMBER_OR_AREA_CODE_FAILURE, payload };
}

export function fetchNumberOrAreaCodeRequest(payload) {
  return { type: FETCH_NUMBER_OR_AREA_CODE_REQUEST, payload };
}

export function fetchNumberOrAreaCodeSuccess(payload) {
  return { type: FETCH_NUMBER_OR_AREA_CODE_SUCCESS, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

export function provisionNumberFailure(payload) {
  return { type: PROVISION_NUMBER_FAILURE, payload };
}

export function provisionNumberRequest(payload) {
  return { type: PROVISION_NUMBER_REQUEST, payload };
}

export function restoreBurnerFailure(payload) {
  return { type: RESTORE_BURNER_FAILURE, payload };
}

export function restoreBurnerRequest(payload) {
  return { type: RESTORE_BURNER_REQUEST, payload };
}

export function restoreBurnerSuccess(payload) {
  return { type: RESTORE_BURNER_SUCCESS, payload };
}

export function setModalIsOpen(payload) {
  return { type: SET_MODAL_IS_OPEN, payload };
}

export function setNumberToProvision(payload) {
  return { type: SET_NUMBER_TO_PROVISION, payload };
}

/*
Reducer
*/
const initialState = {
  data: [],
  isFetching: false,
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
  modalIsOpen: false,
  numberToProvision: '',
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case FETCH_NUMBER_OR_AREA_CODE_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case FETCH_NUMBER_OR_AREA_CODE_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case FETCH_NUMBER_OR_AREA_CODE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: toSortedArray(payload).map((number) => ({
          areaCode: number.areaCode,
          id: number.id,
          lastUpdatedDate: number.lastUpdatedDate,
          serviceProvider: number.serviceProvider.name,
        })),
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: { isError: false, isOpen: false, text: '' },
      };
    case PROVISION_NUMBER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case PROVISION_NUMBER_REQUEST:
      return {
        ...state,
        isFetching: true,
        modalIsOpen: false,
        numberToProvision: '',
      };
    case RESTORE_BURNER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case RESTORE_BURNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: state.data.filter(
          (number) => number.id !== payload.response.phoneNumberId
        ),
        message: { isError: false, isOpen: true, text: payload.message },
      };
    case SET_MODAL_IS_OPEN:
      return {
        ...state,
        modalIsOpen: payload,
      };
    case SET_NUMBER_TO_PROVISION:
      return {
        ...state,
        numberToProvision: payload,
      };
    default:
      return state;
  }
}

/*
Epics
*/
export const findAvailableNumberOrAreaCodeEpic = (action$) => {
  const findAvailableNumberOrAreaCode = (action) => {
    const { country, phoneNumberOrAreaCode, token } = action.payload;
    const formattedNumber = formatE164IfValidPhoneNumber(
      phoneNumberOrAreaCode,
      country
    );
    return ajax({
      url: formattedNumber
        ? `${
            process.env.REACT_APP_BASE_URL
          }/admin/phone-number-cache/${encodeURIComponent(formattedNumber)}`
        : `${process.env.REACT_APP_BASE_URL}/admin/phone-number-cache?areaCode=${phoneNumberOrAreaCode}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_NUMBER_OR_AREA_CODE_REQUEST),
    mergeMap((action) =>
      findAvailableNumberOrAreaCode(action).pipe(
        map((response) => {
          return isEmpty(response.response)
            ? fetchNumberOrAreaCodeFailure('No numbers available')
            : fetchNumberOrAreaCodeSuccess(response.response);
        }),
        catchError((error) =>
          of(
            fetchNumberOrAreaCodeFailure(
              error.response && error.response.error
                ? error.response.error
                : "Can't find available numbers."
            )
          )
        )
      )
    )
  );
};

export const provisionNumberEpic = (action$) => {
  const getUserInfo = (action) => {
    const { userIdentifier, token } = action.payload;
    const formattedNumber = formatE164IfValidPhoneNumber(userIdentifier);
    return ajax({
      url: formattedNumber
        ? `${
            process.env.REACT_APP_BASE_URL
          }/admin/users?phoneNumber=${encodeURIComponent(formattedNumber)}`
        : isEmail(userIdentifier)
        ? `${process.env.REACT_APP_BASE_URL}/admin/users?email=${userIdentifier}`
        : `${process.env.REACT_APP_BASE_URL}/admin/users/${userIdentifier}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(PROVISION_NUMBER_REQUEST),
    mergeMap((action) =>
      getUserInfo(action).pipe(
        map((response) =>
          restoreBurnerRequest({
            ...action.payload,
            userId: response.response.id,
          })
        ),
        catchError((error) =>
          of(
            provisionNumberFailure(
              error.response && error.response.error
                ? error.response.error
                : "Can't find user account."
            )
          )
        )
      )
    )
  );
};

export const restoreBurnerEpic = (action$) => {
  const restoreBurner = (action) => {
    const { name, numberToProvision, sku, token, userId } = action.payload;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${userId}/restoreBurner`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        name,
        phoneNumber: numberToProvision,
        sku,
      },
    });
  };

  return action$.pipe(
    ofType(RESTORE_BURNER_REQUEST),
    mergeMap((action) =>
      restoreBurner(action).pipe(
        map((response) =>
          restoreBurnerSuccess({
            message: 'Number was successfully provisioned!',
            response: response.response,
          })
        ),
        catchError((error) =>
          of(
            restoreBurnerFailure(
              error.response && error.response.error
                ? error.response.error
                : "Can't provision number."
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getPhoneNumbers = createSelector(
  (state) => state.provisionNumber.data,
  (numbers) => transformPhoneNumber(numbers)
);

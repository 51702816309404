import React from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ConfirmDialog from 'components/ConfirmDialog';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  burnNumberRequest: PropTypes.func.isRequired,
  burnerInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
  deleteBurnerDialogIsOpen: PropTypes.bool.isRequired,
  deleteBurnerDialogOpen: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    margin: theme.spacing(3, 1),
  },
}));

function DeleteBurner({
  burnNumberRequest,
  burnerInfo,
  deleteBurnerDialogIsOpen,
  deleteBurnerDialogOpen,
}) {
  const { id, userId } = burnerInfo;
  const { getTokenSilently, logout } = useAuth0();
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Typography className="heading" gutterBottom variant="subtitle1">
        Delete Burner
      </Typography>

      <Button
        className={classes.deleteButton}
        color="error"
        onClick={() => deleteBurnerDialogOpen(true)}
        variant="outlined"
      >
        Delete Burner
      </Button>
      <ConfirmDialog
        content="Are you sure you want to burn this burner?"
        dialogIsOpen={deleteBurnerDialogIsOpen}
        handleClick={() =>
          from(getTokenSilently()).subscribe({
            next: (token) =>
              burnNumberRequest({
                id,
                token,
                userId,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => deleteBurnerDialogOpen(false),
          })
        }
        handleClose={() => deleteBurnerDialogOpen(false)}
        title="Delete Burner"
      />
    </Grid>
  );
}

DeleteBurner.propTypes = propTypes;

export default DeleteBurner;

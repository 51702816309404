import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import DisableMediaModal from './DisableMediaModal';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  mediaId: PropTypes.string.isRequired,
  enableMedia: PropTypes.func.isRequired,
  disableMedia: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

const MediaActions = ({
  disabled,
  mediaId,
  enableMedia,
  disableMedia,
  isFetching,
}) => {
  const [isReasonModalOpen, setReasonModalOpen] = useState(false);

  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Media Actions
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Button
                className="button"
                color="primary"
                disabled={isFetching}
                onClick={() => setReasonModalOpen(true)}
                size="medium"
                type="submit"
                variant="outlined"
              >
                {disabled ? 'Enable' : 'Disable'}
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <DisableMediaModal
        mediaIdParam={mediaId}
        disabled={disabled}
        handleOpen={setReasonModalOpen}
        handleSubmitEnable={enableMedia}
        handleSubmitDisable={disableMedia}
        isFetching={isFetching}
        isOpen={isReasonModalOpen}
      />
    </Grid>
  );
};

MediaActions.propTypes = propTypes;

export default MediaActions;

import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { countries, formatIdentifierIfPhoneNumber } from 'utils/general';
import { products } from 'utils/provision-number';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  numberToProvision: PropTypes.string.isRequired,
  setModalIsOpen: PropTypes.func.isRequired,
  setNumberToProvision: PropTypes.func.isRequired,
};

function ProvisionNumberModal({
  handleSubmit,
  isFetching,
  modalIsOpen,
  numberToProvision,
  setModalIsOpen,
  setNumberToProvision,
}) {
  const { getTokenSilently, logout } = useAuth0();

  const handleClose = () => {
    setModalIsOpen(false);
    setNumberToProvision('');
  };

  const initialValues = {
    country: 'US',
    userIdentifier: '',
    sku: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        const { country, userIdentifier, sku } = values;
        from(getTokenSilently()).subscribe({
          next: (token) =>
            handleSubmit({
              name: 'My New Burner',
              numberToProvision,
              userIdentifier: formatIdentifierIfPhoneNumber(
                userIdentifier,
                country
              ),
              sku,
              token,
            }),
          error: () => logout({ returnTo: window.location.origin }),
          complete: () => resetForm(initialValues),
        });
      }}
      validate={(values) => {
        const errors = {};
        if (!values.userIdentifier) {
          errors.userIdentifier = '* Required';
        } else if (values.sku === '') {
          errors.sku = '* Required';
        }
        return errors;
      }}
    >
      {({ submitForm }) => (
        <Dialog
          aria-labelledby="form-dialog-title"
          fullWidth
          onClose={handleClose}
          open={modalIsOpen}
        >
          <DialogTitle id="form-dialog-title">{`Provision Number ${numberToProvision}`}</DialogTitle>
          <DialogContent>
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Field
                    name="userIdentifier"
                    render={({ field, form: { errors, touched, values } }) => (
                      <TextField
                        {...field}
                        disabled={isFetching}
                        error={errors.userIdentifier && touched.userIdentifier}
                        fullWidth
                        helperText={
                          errors.userIdentifier &&
                          touched.userIdentifier &&
                          errors.userIdentifier
                        }
                        id="userIdentifier"
                        InputProps={
                          errors.userIdentifier &&
                          touched.userIdentifier && {
                            endAdornment: (
                              <InputAdornment position="end">
                                <ErrorOutline className="error" />
                              </InputAdornment>
                            ),
                          }
                        }
                        label="User Phone / ID / Email*"
                        margin="normal"
                        type="text"
                        value={values.userIdentifier}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="country"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        disabled={isFetching}
                        label="Country"
                        fullWidth
                        margin="normal"
                        select
                        SelectProps={{
                          MenuProps: {
                            className: 'menu',
                          },
                        }}
                        variant="outlined"
                      >
                        {countries &&
                          countries.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="sku"
                    render={({ field, form: { errors, touched } }) => (
                      <TextField
                        {...field}
                        disabled={isFetching}
                        error={errors.sku && touched.sku}
                        fullWidth
                        helperText={errors.sku && touched.sku && errors.sku}
                        label="Burner Product*"
                        margin="normal"
                        select
                        SelectProps={{
                          MenuProps: {
                            className: 'menu',
                          },
                        }}
                        variant="outlined"
                      >
                        {products &&
                          products.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              className="button"
              disabled={isFetching}
              onClick={submitForm}
              size="medium"
              type="submit"
            >
              Provision
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

ProvisionNumberModal.propTypes = propTypes;

export default ProvisionNumberModal;

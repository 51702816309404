import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const propTypes = {
  previouslyAssignedToBurner: PropTypes.string.isRequired,
};

function BurnerInfoTable({ previouslyAssignedToBurner }) {
  return (
    <Grid item xs={4}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Previously Assigned To Burner</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{previouslyAssignedToBurner}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}

BurnerInfoTable.propTypes = propTypes;

export default BurnerInfoTable;

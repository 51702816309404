import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const propTypes = {
  twilioData: PropTypes.shape({
    carrierName: PropTypes.string,
    carrierType: PropTypes.string,
  }).isRequired,
};

function TwilioDataTable({ twilioData }) {
  return (
    <Grid item xs={6}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Carrier Name</TableCell>
            <TableCell variant="head">Carrier Type</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{twilioData.carrierName}</TableCell>
            <TableCell>{twilioData.carrierType}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}

TwilioDataTable.propTypes = propTypes;

export default TwilioDataTable;

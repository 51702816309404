import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';
import first from 'lodash/first';

export const columns = [
  {
    name: 'name',
    label: 'Experiment Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'platform',
    label: 'Platform',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'sendEvent',
    label: 'Send Event',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'sendUserProps',
    label: 'Send User Props',
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const options = {
  download: false,
  elevation: 0,
  filter: false,
  filterType: 'textField',
  print: false,
  selectableRows: 'none',
};

export const ANALYTICS_PLATFORMS = {
  1: 'Amplitude',
  2: 'Braze',
};

export const ANALYTICS_ENUMS = {
  Amplitude: 1,
  Braze: 2,
};

export const transformExperimentData = (experimentData) => {
  if (isEmpty(experimentData)) return experimentData;

  return map(experimentData, (data) => {
    /* we flatten out the analyticsPlatformSettings array for each row,
       since there is an experiment record per analytics platform
    [
      { data: { name: 'x_test', analyticsPlatformSettings: [{platformId: 1, sendEvent: true, sendUserProps: true}]}},
      { data: { name: 'x_test', analyticsPlatformSettings: [{platformId: 2, sendEvent: true, sendUserProps: true}]}}
    ]
    */
    const settings = first(get(data, 'analyticsPlatformSettings', []));
    const platformId = get(settings, 'platformId');

    return {
      name: get(data, 'name'),
      platform: get(ANALYTICS_PLATFORMS, platformId, 'Unknown'),
      sendEvent: get(settings, 'sendEvent', 'unknown').toString(),
      sendUserProps: get(settings, 'sendUserProps', 'unknown').toString(),
    };
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

const propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function ProgressStepper({ activeStep, steps }) {
  return (
    <Grid item xs={12}>
      <Stepper activeStep={activeStep} orientation="horizontal">
        {steps &&
          steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
      </Stepper>
    </Grid>
  );
}

ProgressStepper.propTypes = propTypes;

export default ProgressStepper;

import { connect } from 'react-redux';
import {
  burnNumberRequest,
  deleteBurnerDialogOpen,
  getBurnerInfo,
} from 'ducks/view-burner';
import DeleteBurner from 'components/pages/view-burner/DeleteBurner';

const mapStateToProps = (state) => {
  const { deleteBurnerDialogIsOpen } = state.viewBurner;

  return {
    deleteBurnerDialogIsOpen,
    burnerInfo: getBurnerInfo(state),
  };
};

const mapDispatchToProps = {
  burnNumberRequest,
  deleteBurnerDialogOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBurner);

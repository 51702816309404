import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';
import MessagingService from 'components/MessagingService';
import View from 'components/View';
import NumberHistory from 'components/pages/view-burner/NumberHistory';
import ViewBurnerInput from 'components/pages/view-burner/ViewBurnerInput';
import AddTextsMinutesContainer from 'containers/AddTextsMinutesContainer';
import DeleteBurnerContainer from 'containers/DeleteBurnerContainer';
import BurnerDetailsSectionContainer from 'containers/BurnerDetailsSectionContainer';
import ChangeExpirationDateContainer from 'containers/ChangeExpirationDateContainer';
import ReplaceFeatureSetsContainer from 'containers/ReplaceFeatureSetsContainer';
import TransferOwnershipContainer from 'containers/TransferOwnershipContainer';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';

const propTypes = {
  burnerIdParam: PropTypes.string,
  burnerInfo: PropTypes.object.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  numberHistory: PropTypes.object.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  searchBurnersRequest: PropTypes.func.isRequired,
  showNumberHistory: PropTypes.bool.isRequired,
};

function ViewBurner({
  burnerIdParam = null,
  burnerInfo,
  handleCloseSnackbar,
  isFetching,
  numberHistory,
  message,
  searchBurnersRequest,
  showNumberHistory,
}) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('viewBurner')) ? (
    <View>
      <ViewBurnerInput
        handleSubmit={searchBurnersRequest}
        isFetching={isFetching}
        params={burnerIdParam}
      />
      {!isEmpty(burnerInfo) && (
        <>
          <BurnerDetailsSectionContainer />
          <DeleteBurnerContainer />
          <Grid container>
            <AddTextsMinutesContainer />
            <ChangeExpirationDateContainer />
            <ReplaceFeatureSetsContainer />
            <TransferOwnershipContainer />
          </Grid>
        </>
      )}
      {showNumberHistory && <NumberHistory history={numberHistory} />}
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

ViewBurner.propTypes = propTypes;

export default ViewBurner;

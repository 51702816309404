import { from } from 'rxjs';
import { Field, Form, Formik } from 'formik';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React from 'react';
import { useAuth0 } from '../../../utils/react-auth0-wrapper';
import PropTypes from 'prop-types';
import { disableMediaSchema } from '../../../utils/hosted-media';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const propTypes = {
  mediaIdParam: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleSubmitEnable: PropTypes.func.isRequired,
  handleSubmitDisable: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const DisableMediaModal = ({
  mediaIdParam,
  disabled,
  handleOpen,
  handleSubmitEnable,
  handleSubmitDisable,
  isFetching,
  isOpen,
}) => {
  const initialValues = { reason: '', mediaIdentifier: mediaIdParam };
  const { getTokenSilently, logout } = useAuth0();

  const onComplete = (resetForm) => {
    handleOpen(false);
    resetForm(initialValues);
  };

  const handleSubmit = (payload) => {
    return disabled
      ? handleSubmitEnable(payload)
      : handleSubmitDisable(payload);
  };

  const disabledText = disabled ? 'Enable' : 'Disable';

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      open={isOpen}
    >
      <DialogTitle id="confirmation-dialog-title">{disabledText}</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              from(getTokenSilently()).subscribe({
                next: (token) =>
                  handleSubmit({
                    token,
                    ...values,
                  }),
                error: () => logout({ returnTo: window.location.origin }),
                complete: () => onComplete(resetForm),
              });
            }}
            validationSchema={disableMediaSchema}
          >
            {({ submitForm, values }) => (
              <Form>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mb={1}
                >
                  <Box display="flex" flexDirection="row" width="100%">
                    {!disabled && (
                      <Field name="reason">
                        {({ field, form: { errors, touched } }) => (
                          <TextField
                            {...field}
                            className="input-textarea"
                            disabled={isFetching || disabled}
                            error={errors.reason && touched.reason}
                            helperText={
                              errors.reason && touched.reason && errors.reason
                            }
                            id="reason"
                            InputProps={
                              errors.reason &&
                              touched.reason && {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ErrorOutlineIcon className="error" />
                                  </InputAdornment>
                                ),
                              }
                            }
                            label="Reason for disabling media (optional)"
                            type="text"
                            variant="standard"
                            multiline
                          />
                        )}
                      </Field>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="row">
                    <Button
                      className="button"
                      disabled={isFetching}
                      color="primary"
                      onClick={submitForm}
                      size="medium"
                      variant="outlined"
                    >
                      {disabledText}
                    </Button>
                    <Button color="primary" onClick={() => handleOpen(false)}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

DisableMediaModal.propTypes = propTypes;

export default DisableMediaModal;

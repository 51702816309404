import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const propTypes = {
  logContextData: PropTypes.arrayOf(
    PropTypes.shape({
      contextId: PropTypes.string,
      message: PropTypes.string,
      service: PropTypes.string,
      time: PropTypes.string,
    })
  ).isRequired,
};

const LogContextInfoTable = ({ logContextData }) => {
  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Log Details - {logContextData[0].contextId}
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Time </TableCell>
            <TableCell variant="head">Message</TableCell>
            <TableCell variant="head">Service</TableCell>
          </TableRow>
          {logContextData.map((item, i) => (
            <TableRow key={i}>
              <TableCell>{String(item.time)}</TableCell>
              <TableCell>{String(item.message)}</TableCell>
              <TableCell>{String(item.service)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

LogContextInfoTable.propTypes = propTypes;

export default LogContextInfoTable;

import { differenceInMilliseconds } from 'date-fns';
import findLast from 'lodash/findLast';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as Yup from 'yup';
import { formatDate } from 'utils/general';

export const burnerIdSchema = Yup.object().shape({
  burnerId: Yup.string()
    .max(500, '* Maximum 500 characters')
    .required('* Required'),
});

export const countryCodeSchema = Yup.object().shape({
  countryCode: Yup.string()
    .matches(/(US|CA|AU)$/, '* Must be either US, CA, or AU')
    .uppercase()
    .required('* Required'),
});

export const numberSchema = Yup.object().shape({
  credits: Yup.number()
    .integer('* Must be an integer')
    .required('* Required'),
});

export const userIdentifierSchema = Yup.object().shape({
  userIdentifier: Yup.string().required('* Required'),
});

export const enrichBurners = (burners) =>
  isEmpty(burners)
    ? burners
    : burners.map((burner) => ({
        ...burner,
        dates: {
          dateCreated: formatDate(burner.dates.dateCreated),
          expirationDate: formatDate(burner.dates.expirationDate),
        },
      }));

export const enrichSubscriptionEvents = (events) =>
  isEmpty(events)
    ? events
    : {
        ...events,
        rows: events.rows.map((event) => ({
          ...event,
          dateCreated: formatDate(event.dateCreated),
          price: event.price / 100,
        })),
      };

export const enrichSubChangeOrderId = (event) =>
  isEmpty(event)
    ? event
    : {
        ...event,
      };

export const enrichSubscriptions = (subscriptions) =>
  isEmpty(subscriptions)
    ? subscriptions
    : subscriptions.map((sub) => ({
        ...sub,
        creationDate: formatDate(sub.creationDate),
        cancellationDate: formatDate(sub.cancellationDate),
        expirationDate: formatDate(sub.expirationDate),
        renewalDate: formatDate(sub.renewalDate),
      }));

export const enrichUserInfo = (userInfo) =>
  isEmpty(userInfo)
    ? userInfo
    : {
        ...userInfo,
        dateCreated: formatDate(userInfo.dateCreated),
        lastUpdatedDate: formatDate(userInfo.lastUpdatedDate),
      };

export const enrichUserDeletion = (userDeletion) =>
  isEmpty(userDeletion)
    ? userDeletion
    : {
        ...userDeletion,
        dateCreated: formatDate(userDeletion.dateCreated),
        dateForDeletion: formatDate(userDeletion.dateForDeletion),
      };

export const enrichNotes = (notes) =>
  isEmpty(notes)
    ? notes
    : notes.map((note) => ({
        ...note,
        dateCreated: formatDate(note.dateCreated),
      }));

export const enrichTeamChatHistory = (messages) =>
  isEmpty(messages)
    ? messages
    : messages.map((message) => ({
        ...message,
        dateCreated: formatDate(message.dateCreated),
      }));

export const transformBurnerIdForMessaging = (messagesArray, burnersArray) => {
  if (isEmpty(messagesArray) && isEmpty(burnersArray)) {
    return '';
  } else {
    // TODO this is logic from the old admin tool that needs to be refactored
    let result;

    const lastSentMessage = findLast(
      messagesArray,
      (message) => message.direction === 2
    );

    const burnerFromMessage = burnersArray.find((burner) => {
      const burnerId = lastSentMessage ? lastSentMessage.burnerId : '';
      return burner.id === burnerId;
    });

    if (burnersArray.length > 0 && burnerFromMessage !== undefined) {
      result = get(burnerFromMessage, 'id', burnersArray[0].id);
      return result;
    } else {
      result = burnersArray.length > 0 ? burnersArray[0].id : undefined;

      return result;
    }
  }
};

export const enrichBlacklistInfo = (blacklistInfo) => {
  return {
    isBlacklisted: isEmpty(blacklistInfo) ? 'False' : 'True',
    reason: isEmpty(blacklistInfo) ? '' : blacklistInfo.reason,
  };
};

export const transformPreviouslyOwnedPhoneNumbers = (
  activeBurners,
  historicalBurners
) => {
  if (isEmpty(activeBurners) && isEmpty(historicalBurners)) {
    return [];
  } else {
    const activeBurnerArray = activeBurners.map(
      (burner) => burner.phoneNumberId
    );

    const result = historicalBurners.filter(
      (burner) => !activeBurnerArray.includes(burner.phoneNumber)
    );

    return result.map((burner) => ({
      ...burner,
      dateCreated: formatDate(burner.dateCreated),
      dateReleased: formatDate(burner.dateReleased),
    }));
  }
};

export const transformUserTransactions = (eventsArray, transactionsArray) => {
  if (isEmpty(eventsArray) && isEmpty(transactionsArray)) {
    return [];
  } else {
    // TODO this ported logic is flawed and needs to be fixed. (All eventTypes will make it to the final array, not just eventType: 3)
    const creditPurchases = eventsArray.filter(
      (event) => event.eventType === 3
    );

    return transactionsArray.map((transaction) => {
      const creditPurchase = creditPurchases.find(
        (purchase) =>
          differenceInMilliseconds(
            transaction.dateCreated,
            purchase.dateCreated
          ) < 1000
      );

      if (creditPurchase) {
        transaction.credits = creditPurchase.credits;
      }

      return {
        ...transaction,
        dateCreated: formatDate(transaction.dateCreated),
      };
    });
  }
};

export const transformVerifyCode = (verifyCode) =>
  isEmpty(verifyCode) ? { code: 'None' } : verifyCode;

import { formatDistanceStrict, isBefore, subDays } from 'date-fns';
import { capitalize, get, isArray, isEmpty, sortBy } from 'lodash';

export const toSortedArray = (data) =>
  sortArrayByProp(isArray(data) ? data : [data], 'lastUpdatedDate');

export const transformPhoneNumber = (numbers) => {
  if (isEmpty(numbers)) return numbers;

  const aWeekAgo = subDays(Date.now(), 7);
  return numbers.map((number) => ({
    ...number,
    isAvailable: isBefore(number.lastUpdatedDate, aWeekAgo)
      ? 'now'
      : formatDistanceStrict(number.lastUpdatedDate, aWeekAgo, {
          addSuffix: true,
        }),
    serviceProvider: capitalize(number.serviceProvider),
  }));
};

export const products = [
  'com.adhoclabs.burner.mms',
  'com.adhoclabs.burner.burner1',
  'com.adhoclabs.burner.burner3',
  'com.adhoclabs.burner.burner_unlimited',
  'com.adhoclabs.burner.text_only',
  'com.adhoclabs.burner.free_sample',
];

const sortArrayByProp = (data, prop) => sortBy(data, (obj) => get(obj, prop));

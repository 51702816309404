import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const propTypes = {
  userInfo: PropTypes.object.isRequired,
  userTransactions: PropTypes.arrayOf(
    PropTypes.shape({
      credits: PropTypes.number,
      dateCreated: PropTypes.string,
      transactionId: PropTypes.string,
    })
  ),
};

function TransactionsSection({ userTransactions }) {
  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Transactions
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6}>
              {userTransactions && userTransactions.length === 1
                ? '1 Transaction'
                : `${userTransactions.length} Transactions`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1}>Transaction ID</TableCell>
            <TableCell colSpan={1}>Created</TableCell>
            <TableCell colSpan={1}>Credits</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {userTransactions &&
            userTransactions.map((transaction) => (
              <TableRow key={transaction.transactionId}>
                <TableCell>{transaction.transactionId}</TableCell>
                <TableCell>{transaction.dateCreated}</TableCell>
                <TableCell>{transaction.credits}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

TransactionsSection.propTypes = propTypes;

export default TransactionsSection;

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

const propTypes = {
  handleOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  subscriptionEvents: PropTypes.shape({
    count: PropTypes.number,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        burnerNumber: PropTypes.string,
        id: PropTypes.string,
        dateCreated: PropTypes.string,
        price: PropTypes.number,
        sku: PropTypes.string,
        storeId: PropTypes.number,
        subscriptionEventType: PropTypes.string,
        subscriptionId: PropTypes.string,
        userId: PropTypes.string,
      })
    ),
  }).isRequired,
};

function SeeSubscriptionEventsModal({
  subscriptionEvents,
  isOpen,
  handleOpen,
}) {
  const { count, rows } = subscriptionEvents;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={() => handleOpen(false)}
      scroll="paper"
    >
      <DialogTitle>Subscription Events</DialogTitle>
      <DialogContent dividers>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                {count && count === 1 ? '1 Event' : `${count} Events`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={1}>Type</TableCell>
              <TableCell colSpan={1}>Created</TableCell>
              <TableCell colSpan={1}>Data</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows &&
              rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.subscriptionEventType}</TableCell>
                  <TableCell>{row.dateCreated}</TableCell>
                  <TableCell>
                    <List dense>
                      {row.burnerNumber && (
                        <ListItem>
                          <ListItemText>
                            {`Burner Number: ${row.burnerNumber}`}
                          </ListItemText>
                        </ListItem>
                      )}
                      <ListItem>
                        <ListItemText>
                          {`Event/History Id: ${row.id}`}
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>{`Price: $${row.price}`}</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>{`Sku: ${row.sku}`}</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>{`Store Id: ${row.storeId}`}</ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>
                          {`Subscription Id: ${row.subscriptionId}`}
                        </ListItemText>
                      </ListItem>
                      <ListItem>
                        <ListItemText>{`User Id: ${row.userId}`}</ListItemText>
                      </ListItem>
                    </List>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SeeSubscriptionEventsModal.propTypes = propTypes;

export default SeeSubscriptionEventsModal;

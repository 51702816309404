import { connect } from 'react-redux';
import {
  fetchNumberOrAreaCodeRequest,
  getPhoneNumbers,
  handleCloseSnackbar,
  setModalIsOpen,
  setNumberToProvision,
} from 'ducks/provision-number';
import ProvisionNumber from 'components/pages/ProvisionNumber';

const mapStateToProps = (state) => {
  const { isFetching, message, modalIsOpen } = state.provisionNumber;

  return {
    data: getPhoneNumbers(state),
    isFetching,
    message,
    modalIsOpen,
  };
};

const mapDispatchToProps = {
  fetchNumberOrAreaCodeRequest,
  handleCloseSnackbar,
  setModalIsOpen,
  setNumberToProvision,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvisionNumber);

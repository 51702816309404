import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import noop from 'lodash/noop';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { textSchema } from 'utils/general';
import { legalOptions } from 'utils/blacklist-user';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import ConfirmDialog from 'components/ConfirmDialog';

const propTypes = {
  blacklistInfo: PropTypes.shape({
    isBlacklisted: PropTypes.string,
  }).isRequired,
  blacklistUserDialogIsOpen: PropTypes.bool.isRequired,
  blacklistUserRequest: PropTypes.func,
  isFetching: PropTypes.bool.isRequired,
  setBlacklistUserDialogIsOpen: PropTypes.func,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
};

function BlacklistUserMessage({
  blacklistInfo,
  blacklistUserDialogIsOpen,
  blacklistUserRequest = noop,
  isFetching,
  setBlacklistUserDialogIsOpen = noop,
  userInfo,
}) {
  const { isBlacklisted } = blacklistInfo;
  const { getTokenSilently, logout } = useAuth0();
  const { phoneNumber, id } = userInfo;
  const initialValues = {
    isLegalRequest: false,
    body: '',
  };

  const handleClick = ({ closeDialog, submitForm = noop } = {}) => {
    closeDialog();
    submitForm();
  };

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          const { body, isLegalRequest } = values;
          from(getTokenSilently()).subscribe({
            next: (token) =>
              blacklistUserRequest({
                body,
                id,
                isLegalRequest,
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => resetForm(initialValues),
          });
        }}
        validationSchema={textSchema}
      >
        {({ submitForm }) => (
          <Form className="number-input">
            <Field name="isLegalRequest">
              {({ field }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  label="Is it a legal request?"
                  margin="normal"
                  variant="standard"
                  select
                >
                  {legalOptions &&
                    legalOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Field>
            <Field name="body">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  {...field}
                  className="text-input"
                  disabled={isFetching}
                  error={errors.body && touched.body}
                  fullWidth
                  helperText={errors.body && touched.body && errors.body}
                  id="body"
                  InputProps={
                    errors.body &&
                    touched.body && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ErrorOutline className="error" />
                        </InputAdornment>
                      ),
                    }
                  }
                  label="Reason*"
                  multiline
                  margin="normal"
                  rows="4"
                  variant="outlined"
                />
              )}
            </Field>
            <ConfirmDialog
              content={
                isBlacklisted === 'True'
                  ? `Are you sure you want to remove ${phoneNumber} from the blacklist?`
                  : `Are you sure you want to add ${phoneNumber} to the blacklist?`
              }
              dialogIsOpen={blacklistUserDialogIsOpen}
              handleClick={() =>
                handleClick({
                  closeDialog: () => setBlacklistUserDialogIsOpen(false),
                  submitForm: () => submitForm(),
                })
              }
              handleClose={() => setBlacklistUserDialogIsOpen(false)}
              title={
                isBlacklisted === 'True'
                  ? 'Remove From Blacklist'
                  : 'Add To Blacklist'
              }
            />
            <Button
              className="button"
              color="primary"
              disabled={isFetching}
              onClick={() => setBlacklistUserDialogIsOpen(true)}
              size="medium"
              type="button"
              variant="outlined"
            >
              {isBlacklisted === 'True'
                ? 'Remove From Blacklist'
                : 'Add to Blacklist'}
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

BlacklistUserMessage.propTypes = propTypes;

export default BlacklistUserMessage;

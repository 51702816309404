import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import MessagingService from 'components/MessagingService';
import View from 'components/View';
import MediaSearch from 'components/pages/hosted-media/MediaSearch';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MediaInfoTable from './MediaInfo';
import MediaActions from './MediaActions';

const propTypes = {
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  fetchHostedMediaRequest: PropTypes.func.isRequired,
  enableHostedMediaRequest: PropTypes.func.isRequired,
  disableHostedMediaRequest: PropTypes.func.isRequired,
  mediaIdParam: PropTypes.string,
  mediaInfo: PropTypes.shape({
    createdAt: PropTypes.string,
    disabled: PropTypes.bool,
    expiresAt: PropTypes.string,
    fileDownloadUrl: PropTypes.string,
    fileSizeBytes: PropTypes.number,
    isActive: PropTypes.bool,
    mediaId: PropTypes.string,
    mediaState: PropTypes.string,
    mediaType: PropTypes.string,
    previewDownloadUrl: PropTypes.string,
    shortUrl: PropTypes.string,
    updatedAt: PropTypes.string,
    userId: PropTypes.string,
  }),
};

function HostedMediaSection({
  handleCloseSnackbar,
  isFetching,
  message,
  fetchHostedMediaRequest,
  enableHostedMediaRequest,
  disableHostedMediaRequest,
  mediaIdParam = null,
  mediaInfo,
}) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('hostedMedia')) ? (
    <View>
      <Grid item xs={12}>
        <Typography
          component="h1"
          data-testid="title-test"
          gutterBottom
          variant="h4"
        >
          Search Hosted Media
        </Typography>
        <Divider />
        <MediaSearch
          handleSubmit={fetchHostedMediaRequest}
          isFetching={isFetching}
          mediaIdParam={mediaIdParam}
        />
      </Grid>
      {!isEmpty(mediaInfo) && (
        <>
          <MediaActions
            disabled={mediaInfo.disabled}
            mediaId={mediaInfo.id}
            enableMedia={enableHostedMediaRequest}
            disableMedia={disableHostedMediaRequest}
          />
          <MediaInfoTable mediaInfo={mediaInfo} />
        </>
      )}

      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

HostedMediaSection.propTypes = propTypes;

export default HostedMediaSection;

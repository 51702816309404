import React from 'react';
import View from 'components/View';

function Home() {
  return (
    <View>
      <p>Log in to use the Dashboard</p>
    </View>
  );
}

export default Home;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import MessagingService from 'components/MessagingService';
import NumberCacheAnalyticsInfo from 'components/pages/number-cache-analytics/NumberCacheAnalyticsInfo';
import NumberCacheAnalyticsTable from 'components/pages/number-cache-analytics/NumberCacheAnalyticsTable';
import View from 'components/View';
import Unauthorized from 'components/Unauthorized';
import { routeScope } from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleCloseSnackbar: PropTypes.func.isRequired,
  getNumberCacheDataRequest: PropTypes.func.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  numberCacheData: PropTypes.arrayOf(
    PropTypes.shape({
      area_code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      available: PropTypes.number,
      available_clean: PropTypes.number,
      burner: PropTypes.number,
      consistently_dirty: PropTypes.number,
      created_date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      detached: PropTypes.number,
      detached_clean: PropTypes.number,
      dirty: PropTypes.number,
      holding: PropTypes.number,
      new: PropTypes.number,
      pending: PropTypes.number,
      total: PropTypes.number,
    })
  ).isRequired,
};

function NumberCacheAnalytics({
  handleCloseSnackbar,
  getNumberCacheDataRequest,
  message,
  numberCacheData,
}) {
  const { getTokenSilently, hasPermission, logout } = useAuth0();

  useEffect(() => {
    const token$ = from(getTokenSilently()).subscribe({
      next: (token) => getNumberCacheDataRequest({ token }),
      error: () => logout({ returnTo: window.location.origin }),
    });

    return () => token$.unsubscribe();
  }, [getNumberCacheDataRequest, getTokenSilently, logout]);

  return hasPermission(routeScope('numberCacheAnalytics')) ? (
    <View>
      <NumberCacheAnalyticsInfo timestamp={numberCacheData[0].created_date} />
      <NumberCacheAnalyticsTable numberCacheData={numberCacheData} />
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

NumberCacheAnalytics.propTypes = propTypes;

export default NumberCacheAnalytics;

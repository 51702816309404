import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import { formatDate } from 'utils/general';

export const enrichBlacklistInfo = (blacklistInfo) => {
  return {
    isBlacklisted: isEmpty(blacklistInfo) ? 'False' : 'True',
    reason: isEmpty(blacklistInfo) ? '' : blacklistInfo.reason,
  };
};

export const enrichUserInfo = (userInfo) =>
  isEmpty(userInfo)
    ? {}
    : {
        ...userInfo,
        createdSample: capitalize(toString(userInfo.createdSample)),
        dateCreated: formatDate(userInfo.dateCreated),
        lastUpdatedDate: formatDate(userInfo.lastUpdatedDate),
      };

export const legalOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

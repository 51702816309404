import { ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { iif, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { formatE164IfValidPhoneNumber, isEmail } from 'utils/general';
import {
  enrichUserInfo,
  transformIsBlacklisted,
} from 'utils/replace-user-number';

/*
Actions
*/
export const FETCH_BLACKLIST_FAILURE =
  'dashboard/replaceUserNumber/FETCH_BLACKLIST_FAILURE';
export const FETCH_BLACKLIST_REQUEST =
  'dashboard/replaceUserNumber/FETCH_BLACKLIST_REQUEST';
export const FETCH_BLACKLIST_SUCCESS =
  'dashboard/replaceUserNumber/FETCH_BLACKLIST_SUCCESS';
export const FETCH_DATA_FAILURE =
  'dashboard/replaceUserNumber/FETCH_DATA_FAILURE';
export const FETCH_DATA_REQUEST =
  'dashboard/replaceUserNumber/FETCH_DATA_REQUEST';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/replaceUserNumber/HANDLE_CLOSE_SNACKBAR';
export const REPLACE_NUMBER_FAILURE =
  'dashboard/replaceUserNumber/REPLACE_NUMBER_FAILURE';
export const REPLACE_NUMBER_REQUEST =
  'dashboard/replaceUserNumber/REPLACE_NUMBER_REQUEST';
export const REPLACE_NUMBER_SUCCESS =
  'dashboard/replaceUserNumber/REPLACE_NUMBER_SUCCESS';

/*
Action Creators
*/
export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

export function fetchBlacklistFailure(payload) {
  return { type: FETCH_BLACKLIST_FAILURE, payload };
}

export function fetchBlacklistRequest(payload) {
  return { type: FETCH_BLACKLIST_REQUEST, payload };
}

export function fetchBlacklistSuccess(payload) {
  return { type: FETCH_BLACKLIST_SUCCESS, payload };
}

export function fetchDataFailure(payload) {
  return { type: FETCH_DATA_FAILURE, payload };
}

export function fetchDataRequest(payload) {
  return { type: FETCH_DATA_REQUEST, payload };
}

export function replaceNumberFailure(payload) {
  return { type: REPLACE_NUMBER_FAILURE, payload };
}

export function replaceNumberRequest(payload) {
  return { type: REPLACE_NUMBER_REQUEST, payload };
}

export function replaceNumberSuccess(payload) {
  return { type: REPLACE_NUMBER_SUCCESS, payload };
}

/*
Reducer
*/
const initialState = {
  isBlacklisted: {},
  isFetching: false,
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
  userInfo: {},
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case FETCH_BLACKLIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case FETCH_BLACKLIST_SUCCESS:
      return {
        ...state,
        isBlacklisted: payload.isBlacklisted,
        isFetching: false,
        userInfo: {
          countryCode: payload.userInfo.countryCode,
          createdSample: payload.userInfo.createdSample,
          credits: payload.userInfo.credits,
          dateCreated: payload.userInfo.dateCreated,
          id: payload.userInfo.id,
          lastUpdatedDate: payload.userInfo.lastUpdatedDate,
          phoneNumber: payload.userInfo.phoneNumber,
          platformName: payload.userInfo.platform.name,
          totalNumberBurners: payload.userInfo.totalNumberBurners,
        },
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case FETCH_DATA_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: { isError: false, isOpen: false, text: '' },
      };
    case REPLACE_NUMBER_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case REPLACE_NUMBER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case REPLACE_NUMBER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        message: { isError: false, isOpen: true, text: payload.message },
        userInfo: {
          countryCode: payload.userInfo.countryCode,
          createdSample: payload.userInfo.createdSample,
          credits: payload.userInfo.credits,
          dateCreated: payload.userInfo.dateCreated,
          id: payload.userInfo.id,
          lastUpdatedDate: payload.userInfo.lastUpdatedDate,
          phoneNumber: payload.userInfo.phoneNumber,
          platformName: payload.userInfo.platform.name,
          totalNumberBurners: payload.userInfo.totalNumberBurners,
        },
      };
    default:
      return state;
  }
}

/*
Epic
*/
export const fetchUserDataEpic = (action$) => {
  const fetchUserData = (action) => {
    const { country, userIdentifier, token } = action.payload;
    const formattedNumber = formatE164IfValidPhoneNumber(
      userIdentifier,
      country
    );

    const getUserUrl = formattedNumber
      ? `${
          process.env.REACT_APP_BASE_URL
        }/admin/users?phoneNumber=${encodeURIComponent(formattedNumber)}`
      : isEmail(userIdentifier)
      ? `${
          process.env.REACT_APP_BASE_URL
        }/admin/users?email=${encodeURIComponent(userIdentifier)}`
      : `${process.env.REACT_APP_BASE_URL}/admin/users/${userIdentifier}`;

    return ajax({
      url: getUserUrl,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_DATA_REQUEST),
    mergeMap((action) =>
      fetchUserData(action).pipe(
        map((response) =>
          fetchBlacklistRequest({
            ...action.payload,
            userInfo: response.response,
          })
        ),
        catchError((error) =>
          of(
            fetchDataFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error retrieving user.'
            )
          )
        )
      )
    )
  );
};

export const fetchUserBlacklistDataEpic = (action$) => {
  const fetchBlacklistData = (action) => {
    const { token, userInfo } = action.payload;
    const { id } = userInfo;
    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/${id}/blacklist`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_BLACKLIST_REQUEST),
    mergeMap((action) =>
      fetchBlacklistData(action).pipe(
        map((response) =>
          fetchBlacklistSuccess({
            isBlacklisted: response.response,
            userInfo: action.payload.userInfo,
          })
        ),
        catchError((error) =>
          iif(
            () => error.status && error.status === 404,
            of(
              fetchBlacklistSuccess({
                isBlacklisted: {},
                userInfo: action.payload.userInfo,
              })
            ),
            of(
              fetchBlacklistFailure(
                error.response && error.response.error
                  ? error.response.error
                  : 'Error retrieving blacklist info.'
              )
            )
          )
        )
      )
    )
  );
};

export const replaceUserNumberEpic = (action$) => {
  const replaceUserPhoneNumber = (action) => {
    const { id, newPhoneNumber, token } = action.payload;

    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/users/replace`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        newPhoneNumber,
        userId: id,
      },
    });
  };

  return action$.pipe(
    ofType(REPLACE_NUMBER_REQUEST),
    mergeMap((action) =>
      replaceUserPhoneNumber(action).pipe(
        map((response) =>
          replaceNumberSuccess({
            message: 'Phone number replaced',
            userInfo: response.response,
          })
        ),
        catchError((error) =>
          of(
            replaceNumberFailure(
              error.response && error.response.error
                ? error.response.error
                : 'Error replacing phone number.'
            )
          )
        )
      )
    )
  );
};

/*
Selectors
*/
export const getIsBlacklisted = createSelector(
  (state) => state.replaceUserNumber.isBlacklisted,
  (isBlacklisted) => transformIsBlacklisted(isBlacklisted)
);

export const getUserInfo = createSelector(
  (state) => state.replaceUserNumber.userInfo,
  (userInfo) => enrichUserInfo(userInfo)
);

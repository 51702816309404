import React from 'react';
import PropTypes from 'prop-types';
import AccountsToDeleteTableContainer from 'containers/AccountsToDeleteTableContainer';
import MessagingService from 'components/MessagingService';
import View from 'components/View';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';

const propTypes = {
  handleCloseSnackbar: PropTypes.func.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
};

function AccountsToDelete({ handleCloseSnackbar, message }) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('accountsToDelete')) ? (
    <View>
      <AccountsToDeleteTableContainer />
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

AccountsToDelete.propTypes = propTypes;

export default AccountsToDelete;

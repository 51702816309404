import React from 'react';
import PropTypes from 'prop-types';
import View from 'components/View';
import Unauthorized from 'components/Unauthorized';
import { routeScope } from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import MessagingService from 'components/MessagingService';
import ViewExperimentsTable from 'components/pages/view-experiments/ViewExperimentsTable';
import CreateExperiment from 'components/pages/view-experiments/CreateExperiment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const propTypes = {
  experimentsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      platform: PropTypes.string,
      sendEvent: PropTypes.string,
      sendUserProps: PropTypes.string,
    })
  ).isRequired,
  fetchExperimentsFailure: PropTypes.func.isRequired,
  fetchExperimentsRequest: PropTypes.func.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
};

function ViewExperiments({
  experimentsData,
  createExperimentFailure,
  createExperimentRequest,
  fetchExperimentsFailure,
  fetchExperimentsRequest,
  handleCloseSnackbar,
  isFetching,
  message,
}) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('viewExperiments')) ? (
    <View>
      <Grid item xs={12}>
        <Typography data-testid="title-test" gutterBottom variant="h4">
          View Experiments
        </Typography>
      </Grid>
      <ViewExperimentsTable
        experimentsData={experimentsData}
        fetchExperimentsFailure={fetchExperimentsFailure}
        fetchExperimentsRequest={fetchExperimentsRequest}
      />
      <CreateExperiment
        handleSubmit={createExperimentRequest}
        isFetching={isFetching}
      />
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

ViewExperiments.propTypes = propTypes;

export default ViewExperiments;

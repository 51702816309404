import { connect } from 'react-redux';
import {
  getBurners,
  getPreviouslyOwnedPhoneNumbers,
} from 'ducks/view-user-info';
import BurnerDetailsSection from 'components/pages/view-user-info/burner-details/BurnerDetailsSection';

const mapStateToProps = (state) => ({
  burners: getBurners(state),
  previouslyOwnedPhoneNumbers: getPreviouslyOwnedPhoneNumbers(state),
});

export default connect(mapStateToProps, null)(BurnerDetailsSection);

import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import accountsToDelete, {
  deleteAccountEpic,
  fetchAccountsToDeleteEpic,
} from 'ducks/accounts-to-delete';
import blacklistUser, {
  blacklistUserEpic,
  getUserBlacklistInfoEpic,
  getUserInfoEpic,
  updateBlacklistInfoEpic,
} from 'ducks/blacklist-user';
import clearRateLimits, { clearRateLimitsEpic } from 'ducks/clear-rate-limits';
import numberCacheAnalytics, {
  getNumberCacheAnalyticsDataEpic,
} from 'ducks/number-cache-analytics';
import numberLookup, {
  searchAccountsEpic,
  searchActiveNumbersEpic,
  searchReleasedNumbersEpic,
  searchTwilioEpic,
} from 'ducks/number-lookup';
import portOutPin, {
  getPortOutPinEpic,
  setPortOutPinEpic,
} from 'ducks/port-out-pin';
import provisionNumber, {
  findAvailableNumberOrAreaCodeEpic,
  provisionNumberEpic,
  restoreBurnerEpic,
} from 'ducks/provision-number';
import replaceUserNumber, {
  fetchUserBlacklistDataEpic,
  fetchUserDataEpic,
  replaceUserNumberEpic,
} from 'ducks/replace-user-number';
import telephonyExtras, {
  lookupTelephonyPhoneNumberEpic,
  sendTelephonyMessageEpic,
} from 'ducks/telephony-extras';
import viewBurner, {
  burnNumberEpic,
  fetchBurnerInfoEpic,
  removeBurnerEpic,
  searchActiveBurnersEpic,
  searchViewBurnerNumberHistoryEpic,
  transferOwnershipEpic,
  updateBurnerInfoEpic,
  updateBurnerSettingsEpic,
  updateSubscriptionEpic,
  updateBurnerFeatureSetRequestEpic,
} from 'ducks/view-burner';
import viewExperiments, {
  createExperimentEpic,
  fetchExperimentsDataEpic,
} from 'ducks/view-experiments';
import viewLegal, { fetchLegalDataEpic } from 'ducks/view-legal';
import viewUser, {
  addBurnerToSubscriptionEpic,
  adjustUserCreditsEpic,
  changeUserCountryCodeEpic,
  createUserNotesEpic,
  deleteSubscriptionEpic,
  deleteUserAuthTokenEpic,
  deleteUserConfirmationEpic,
  deleteUserEpic,
  fetchTeamChatHistoryEpic,
  fetchUserInfoEpic,
  fetchVerifyCodeEpic,
  fetchOrderIdEpic,
  removeBurnerFromSubscriptionEpic,
  removeUserDeletionEpic,
  searchForUserEpic,
  sendPushNotificationEpic,
  sendTeamChatMessageEpic,
  sendVerifyCodeEpic,
  setBurnersAssignedEpic,
  toggleCreatedSampleEpic,
  toggleDoNotDisturbEpic,
  updateTeamChatHistoryEpic,
  updateUsersNotesEpic,
  updateUsersSubscriptionEpic,
  validateSubscriptionEpic,
} from 'ducks/view-user-info';
import hostedMedia, {
  disableHostedMediaEpic,
  enableHostedMediaEpic,
  fetchHostedMediaEpic,
} from '../ducks/hosted-media';

import logContext, { fetchLogContextEpic } from '../ducks/log-context';

export const rootReducer = combineReducers({
  accountsToDelete,
  blacklistUser,
  clearRateLimits,
  logContext,
  hostedMedia,
  numberCacheAnalytics,
  numberLookup,
  portOutPin,
  provisionNumber,
  replaceUserNumber,
  telephonyExtras,
  viewBurner,
  viewExperiments,
  viewLegal,
  viewUser,
});

export const rootEpic = combineEpics(
  addBurnerToSubscriptionEpic,
  adjustUserCreditsEpic,
  blacklistUserEpic,
  burnNumberEpic,
  changeUserCountryCodeEpic,
  clearRateLimitsEpic,
  createExperimentEpic,
  createUserNotesEpic,
  deleteAccountEpic,
  deleteSubscriptionEpic,
  deleteUserAuthTokenEpic,
  deleteUserConfirmationEpic,
  deleteUserEpic,
  disableHostedMediaEpic,
  enableHostedMediaEpic,
  fetchAccountsToDeleteEpic,
  fetchBurnerInfoEpic,
  fetchExperimentsDataEpic,
  fetchHostedMediaEpic,
  fetchLegalDataEpic,
  fetchLogContextEpic,
  fetchTeamChatHistoryEpic,
  fetchUserBlacklistDataEpic,
  fetchUserDataEpic,
  fetchUserInfoEpic,
  fetchVerifyCodeEpic,
  fetchOrderIdEpic,
  findAvailableNumberOrAreaCodeEpic,
  getPortOutPinEpic,
  getUserBlacklistInfoEpic,
  getUserInfoEpic,
  getNumberCacheAnalyticsDataEpic,
  lookupTelephonyPhoneNumberEpic,
  provisionNumberEpic,
  removeBurnerEpic,
  removeBurnerFromSubscriptionEpic,
  removeUserDeletionEpic,
  replaceUserNumberEpic,
  restoreBurnerEpic,
  searchAccountsEpic,
  searchActiveBurnersEpic,
  searchActiveNumbersEpic,
  searchViewBurnerNumberHistoryEpic,
  searchForUserEpic,
  sendPushNotificationEpic,
  searchReleasedNumbersEpic,
  searchTwilioEpic,
  sendTeamChatMessageEpic,
  sendTelephonyMessageEpic,
  sendVerifyCodeEpic,
  setBurnersAssignedEpic,
  setPortOutPinEpic,
  toggleCreatedSampleEpic,
  toggleDoNotDisturbEpic,
  transferOwnershipEpic,
  updateBlacklistInfoEpic,
  updateBurnerInfoEpic,
  updateBurnerSettingsEpic,
  updateSubscriptionEpic,
  updateTeamChatHistoryEpic,
  updateUsersNotesEpic,
  updateUsersSubscriptionEpic,
  updateBurnerFeatureSetRequestEpic,
  validateSubscriptionEpic
);

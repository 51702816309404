import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import {
  cleanPhone,
  countries,
  formatE164IfValidPhoneNumber,
} from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function TelephonyExtrasInput({ handleSubmit, isFetching }) {
  const { getTokenSilently, logout } = useAuth0();
  const initialValues = {
    country: 'US',
    phoneNumber: '',
  };

  return (
    <Grid item xs={12}>
      <Typography data-testid="title-test" gutterBottom variant="h4">
        Telephony Extras
      </Typography>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          const { country, phoneNumber } = values;

          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                phoneNumber: formatE164IfValidPhoneNumber(phoneNumber, country),
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
            complete: () => resetForm(initialValues),
          });
        }}
        validate={(values) => {
          const errors = {};
          if (!values.phoneNumber) {
            errors.phoneNumber = '* Required';
          } else if (
            !formatE164IfValidPhoneNumber(values.phoneNumber, values.country)
          ) {
            errors.phoneNumber = '* Invalid phone number';
          }
          return errors;
        }}
      >
        {() => (
          <Form className="number-input">
            <Field name="phoneNumber">
              {({ field, form: { errors, touched, values } }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  error={errors.phoneNumber && touched.phoneNumber}
                  helperText={
                    errors.phoneNumber &&
                    touched.phoneNumber &&
                    errors.phoneNumber
                  }
                  id="phoneNumber"
                  InputProps={
                    errors.phoneNumber &&
                    touched.phoneNumber && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ErrorOutline className="error" />
                        </InputAdornment>
                      ),
                    }
                  }
                  label="Phone Number*"
                  margin="normal"
                  type="text"
                  variant="standard"
                  value={cleanPhone(values.phoneNumber)}
                />
              )}
            </Field>
            <Field name="country">
              {({ field }) => (
                <TextField
                  {...field}
                  className="input"
                  disabled={isFetching}
                  label="Country"
                  margin="normal"
                  select
                  variant="standard"
                >
                  {countries &&
                    countries.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Field>
            <Button
              className="button"
              color="primary"
              disabled={isFetching}
              size="medium"
              type="submit"
              variant="outlined"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

TelephonyExtrasInput.propTypes = propTypes;

export default TelephonyExtrasInput;

import React from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function DeleteUserDialog({
  handleOpen,
  handleSubmit,
  isFetching,
  isOpen,
  userInfo,
}) {
  const { getTokenSilently, logout } = useAuth0();
  const { id } = userInfo;

  return (
    <Dialog
      aria-labelledby="confirmation-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={isOpen}
    >
      <DialogTitle id="confirmation-dialog-title">Delete User</DialogTitle>
      <DialogContent>Are you sure you want to delete this user?</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleOpen(false)}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isFetching}
          onClick={() =>
            from(getTokenSilently()).subscribe({
              next: (token) =>
                handleSubmit({
                  id,
                  token,
                }),
              error: () => logout({ returnTo: window.location.origin }),
            })
          }
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteUserDialog.propTypes = propTypes;

export default DeleteUserDialog;

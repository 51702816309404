import { connect } from 'react-redux';
import {
  fetchTeamChatRequest,
  getTeamChatId,
  getTeamChatHistory,
  getUserInfo,
  sendTeamChatRequest,
} from 'ducks/view-user-info';
import BurnerTeamChatSection from 'components/pages/view-user-info/burner-team-chat/BurnerTeamChatSection';

const mapStateToProps = (state) => {
  const { isFetching, teamChatHistoryHasBeenFetched } = state.viewUser;

  return {
    teamChatId: getTeamChatId(state),
    isFetching,
    teamChatHistory: getTeamChatHistory(state),
    teamChatHistoryHasBeenFetched,
    userInfo: getUserInfo(state),
  };
};

const mapDispatchToProps = {
  fetchTeamChatRequest,
  sendTeamChatRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BurnerTeamChatSection);

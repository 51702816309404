/*
Actions
*/
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

export const FETCH_HOSTED_MEDIA_REQUEST =
  'dashboard/hostedMedia/FETCH_HOSTED_MEDIA_REQUEST';
export const FETCH_HOSTED_MEDIA_SUCCESS =
  'dashboard/viewUser/FETCH_HOSTED_MEDIA_SUCCESS';
export const FETCH_HOSTED_MEDIA_FAILURE =
  'dashboard/viewUser/FETCH_HOSTED_MEDIA_FAILURE';
export const ENABLE_HOSTED_MEDIA_REQUEST =
  'dashboard/hostedMedia/ENABLE_HOSTED_MEDIA_REQUEST';
export const ENABLE_HOSTED_MEDIA_SUCCESS =
  'dashboard/hostedMedia/ENABLE_HOSTED_MEDIA_SUCCESS';
export const ENABLE_HOSTED_MEDIA_FAILURE =
  'dashboard/hostedMedia/ENABLE_HOSTED_MEDIA_FAILURE';
export const DISABLE_HOSTED_MEDIA_REQUEST =
  'dashboard/hostedMedia/DISABLE_HOSTED_MEDIA_REQUEST';
export const DISABLE_HOSTED_MEDIA_SUCCESS =
  'dashboard/hostedMedia/DISABLE_HOSTED_MEDIA_SUCCESS';
export const DISABLE_HOSTED_MEDIA_FAILURE =
  'dashboard/hostedMedia/DISABLE_HOSTED_MEDIA_FAILURE';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/hostedMedia/HANDLE_CLOSE_SNACKBAR';

/*
Action Creators
*/
export function fetchHostedMediaRequest(payload) {
  return { type: FETCH_HOSTED_MEDIA_REQUEST, payload };
}

export function fetchHostedMediaSuccess(payload) {
  return { type: FETCH_HOSTED_MEDIA_SUCCESS, payload };
}

export function fetchHostedMediaFailure(payload) {
  return { type: FETCH_HOSTED_MEDIA_FAILURE, payload };
}

export function enableHostedMediaRequest(payload) {
  return { type: ENABLE_HOSTED_MEDIA_REQUEST, payload };
}

export function enableHostedMediaSuccess(payload) {
  return { type: ENABLE_HOSTED_MEDIA_SUCCESS, payload };
}

export function enableHostedMediaFailure(payload) {
  return { type: ENABLE_HOSTED_MEDIA_FAILURE, payload };
}

export function disableHostedMediaRequest(payload) {
  return { type: DISABLE_HOSTED_MEDIA_REQUEST, payload };
}

export function disableHostedMediaSuccess(payload) {
  return { type: DISABLE_HOSTED_MEDIA_SUCCESS, payload };
}

export function disableHostedMediaFailure(payload) {
  return { type: DISABLE_HOSTED_MEDIA_FAILURE, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

/*
Reducer
*/
const initialState = {
  isFetching: false,
  mediaData: {},
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case FETCH_HOSTED_MEDIA_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case FETCH_HOSTED_MEDIA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        mediaInfo: payload,
      };
    case FETCH_HOSTED_MEDIA_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case ENABLE_HOSTED_MEDIA_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case ENABLE_HOSTED_MEDIA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        mediaInfo: payload,
      };
    case ENABLE_HOSTED_MEDIA_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case DISABLE_HOSTED_MEDIA_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case DISABLE_HOSTED_MEDIA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        mediaInfo: payload,
      };
    case DISABLE_HOSTED_MEDIA_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: initialState.message,
      };
    default:
      return state;
  }
}

export const fetchHostedMediaEpic = (action$) => {
  const fetchHostedMedia = (action) => {
    const { mediaIdentifier, token } = action.payload;
    let mediaId;
    try {
      const parsedUrl = new URL(mediaIdentifier);
      const id = parsedUrl.pathname.split('/').slice(-1);
      mediaId = id;
    } catch (e) {
      mediaId = mediaIdentifier;
    }

    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/hosted-media/${encodeURIComponent(mediaId)}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_HOSTED_MEDIA_REQUEST),
    mergeMap((action) =>
      fetchHostedMedia(action).pipe(
        map((response) =>
          fetchHostedMediaSuccess({
            ...response.response,
            message: action.payload.id,
          })
        ),
        catchError((error) =>
          of(
            fetchHostedMediaFailure(
              error.status && (error.status === 400 || error.status === 404)
                ? 'No Hosted Media Found'
                : 'Something went wrong!'
            )
          )
        )
      )
    )
  );
};

export const enableHostedMediaEpic = (action$) => {
  const enableHostedMedia = (action) => {
    const { mediaIdentifier, token } = action.payload;

    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/hosted-media/${encodeURIComponent(mediaIdentifier)}/enable`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(ENABLE_HOSTED_MEDIA_REQUEST),
    mergeMap((action) =>
      enableHostedMedia(action).pipe(
        map((response) =>
          enableHostedMediaSuccess({
            ...response.response,
            message: action.payload.id,
          })
        ),
        catchError((error) =>
          of(
            enableHostedMediaFailure(
              error.status && (error.status === 400 || error.status === 404)
                ? 'No Hosted Media Found'
                : 'Something went wrong!'
            )
          )
        )
      )
    )
  );
};

export const disableHostedMediaEpic = (action$) => {
  const disableHostedMedia = (action) => {
    const { mediaIdentifier, reason, token } = action.payload;

    return ajax({
      url: `${
        process.env.REACT_APP_BASE_URL
      }/admin/hosted-media/${encodeURIComponent(mediaIdentifier)}/disable`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: {
        reason,
      },
    });
  };

  return action$.pipe(
    ofType(DISABLE_HOSTED_MEDIA_REQUEST),
    mergeMap((action) =>
      disableHostedMedia(action).pipe(
        map((response) =>
          disableHostedMediaSuccess({
            ...response.response,
            message: action.payload.id,
          })
        ),
        catchError((error) =>
          of(
            disableHostedMediaFailure(
              error.status && (error.status === 400 || error.status === 404)
                ? 'No Hosted Media Found'
                : 'Something went wrong!'
            )
          )
        )
      )
    )
  );
};

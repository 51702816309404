/*
Actions
*/
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

export const FETCH_LOG_CONTEXT_REQUEST =
  'dashboard/logContext/FETCH_LOG_CONTEXT_REQUEST';
export const FETCH_LOG_CONTEXT_SUCCESS =
  'dashboard/logContext/FETCH_LOG_CONTEXT_SUCCESS';
export const FETCH_LOG_CONTEXT_FAILURE =
  'dashboard/logContext/FETCH_LOG_CONTEXT_FAILURE';
export const HANDLE_CLOSE_SNACKBAR =
  'dashboard/logContext/HANDLE_CLOSE_SNACKBAR';

/*
Action Creators
*/
export function fetchLogContextRequest(payload) {
  return { type: FETCH_LOG_CONTEXT_REQUEST, payload };
}

export function fetchLogContextSuccess(payload) {
  return { type: FETCH_LOG_CONTEXT_SUCCESS, payload };
}

export function fetchLogContextFailure(payload) {
  return { type: FETCH_LOG_CONTEXT_FAILURE, payload };
}

export function handleCloseSnackbar() {
  return { type: HANDLE_CLOSE_SNACKBAR };
}

/*
Reducer
*/
const initialState = {
  isFetching: false,
  logContextData: [],
  message: {
    isError: false,
    isOpen: false,
    text: '',
  },
};

export default function reducer(state = initialState, action = {}) {
  const { payload, type } = action;

  switch (type) {
    case FETCH_LOG_CONTEXT_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: { isError: true, isOpen: true, text: payload },
      };
    case FETCH_LOG_CONTEXT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        logContextData: payload.logContextEntries,
      };
    case FETCH_LOG_CONTEXT_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };
    case HANDLE_CLOSE_SNACKBAR:
      return {
        ...state,
        message: initialState.message,
      };
    default:
      return state;
  }
}

export const fetchLogContextEpic = (action$) => {
  const fetchLogContext = (action) => {
    const { contextId, token } = action.payload;

    return ajax({
      url: `${process.env.REACT_APP_BASE_URL}/admin/support-context/${contextId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  return action$.pipe(
    ofType(FETCH_LOG_CONTEXT_REQUEST),
    mergeMap((action) =>
      fetchLogContext(action).pipe(
        map((response) =>
          fetchLogContextSuccess({
            logContextEntries: response.response,
            message: action.payload,
          })
        ),
        catchError((error) =>
          of(
            fetchLogContextFailure(
              error.status && (error.status === 400 || error.status === 404)
                ? 'No Log Context(s) Found'
                : 'Something went wrong!'
            )
          )
        )
      )
    )
  );
};

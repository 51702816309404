import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { textSchema } from 'utils/general';
import { useAuth0 } from 'utils/react-auth0-wrapper';

const propTypes = {
  handleOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function PushNotificationModal({
  handleOpen,
  handleSubmit,
  isFetching,
  isOpen,
  userInfo,
}) {
  const { getTokenSilently, logout } = useAuth0();
  const { id } = userInfo;

  return (
    <Formik
      initialValues={{ body: 'This is a test push notification' }}
      onSubmit={(values) => {
        from(getTokenSilently()).subscribe({
          next: (token) =>
            handleSubmit({
              body: values.body,
              id,
              token,
            }),
          error: () => logout({ returnTo: window.location.origin }),
          complete: () => handleOpen(false),
        });
      }}
      validationSchema={textSchema}
    >
      {({ submitForm }) => (
        <Dialog
          aria-labelledby="form-dialog-title"
          fullWidth
          onClose={() => handleOpen(false)}
          open={isOpen}
        >
          <DialogTitle id="form-dialog-title">Push Notification</DialogTitle>
          <DialogContent>
            <Form>
              <Field name="body">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    {...field}
                    disabled={isFetching}
                    error={errors.body && touched.body}
                    fullWidth
                    helperText={errors.body && touched.body && errors.body}
                    id="body"
                    InputProps={
                      errors.body &&
                      touched.body && {
                        endAdornment: (
                          <InputAdornment position="end">
                            <ErrorOutline className="error" />
                          </InputAdornment>
                        ),
                      }
                    }
                    label="Send A Test Push Notification"
                    multiline
                    margin="normal"
                    rows="4"
                    variant="standard"
                  />
                )}
              </Field>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button
              className="button"
              color="primary"
              type="button"
              onClick={() => handleOpen(false)}
            >
              Cancel
            </Button>
            <Button
              className="button"
              color="primary"
              disabled={isFetching}
              onClick={submitForm}
              type="submit"
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

PushNotificationModal.propTypes = propTypes;

export default PushNotificationModal;

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

const propTypes = {
  mediaInfo: PropTypes.shape({
    createdAt: PropTypes.string,
    disabled: PropTypes.bool,
    expiresAt: PropTypes.string,
    fileDownloadUrl: PropTypes.string,
    fileSizeBytes: PropTypes.number,
    isActive: PropTypes.bool,
    mediaId: PropTypes.string,
    mediaState: PropTypes.string,
    mediaType: PropTypes.string,
    previewDownloadUrl: PropTypes.string,
    shortUrl: PropTypes.string,
    updatedAt: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
};

const MediaInfoTable = ({ mediaInfo }) => {
  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h5">
        Media Details
      </Typography>
      <Table>
        <TableBody>
          {Object.entries(mediaInfo)
            .filter(([k, v]) => v !== undefined)
            .map(([k, v], i) => (
              <TableRow key={i}>
                <TableCell variant="head">{k}</TableCell>
                <TableCell>{String(v)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

MediaInfoTable.propTypes = propTypes;

export default MediaInfoTable;

import React from 'react';
import PropTypes from 'prop-types';
import ClearRateLimitsInput from 'components/pages/clear-rate-limits/ClearRateLimitsInput';
import MessagingService from 'components/MessagingService';
import View from 'components/View';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';

const propTypes = {
  clearRateLimitsRequest: PropTypes.func.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
};

function ClearRateLimits({
  clearRateLimitsRequest,
  handleCloseSnackbar,
  isFetching,
  message,
}) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('clearRateLimits')) ? (
    <View>
      <ClearRateLimitsInput
        handleSubmit={clearRateLimitsRequest}
        isFetching={isFetching}
      />
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

ClearRateLimits.propTypes = propTypes;

export default ClearRateLimits;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import { makeStyles } from '@mui/styles';
import { union } from 'lodash';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const propTypes = {
  burnerInfo: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  burnerFeatureSets: PropTypes.array,
  currentFeatureSet: PropTypes.object,
};

const useStyles = makeStyles(() => ({
  featureSetSelect: {
    marginRight: 20,
    marginBottm: 0,
    minWidth: 100,
  },
  settingAdded: {
    color: '#66FF00',
  },
  settingRemoved: {
    color: '#FF6347',
  },
}));

function ReplaceFeatureSets({
  burnerInfo,
  handleSubmit,
  isFetching,
  burnerFeatureSets,
  currentFeatureSet,
}) {
  const classNames = useStyles();
  const { getTokenSilently, logout } = useAuth0();

  const findFeatureSet = (id) => {
    return burnerFeatureSets.find((featureSet) => featureSet.id === id);
  };

  const setFeatureSetInformation = (featureSet) => {
    setSelectedFeatureSet(featureSet);
    setFeatureSettings(
      generateCompareableSettings(featureSet, currentFeatureSet)
    );
  };

  const generateCompareableSettings = (currentSet, selectedSet) => {
    return union(
      Object.keys(formatFeatureSetSettings(currentSet)),
      Object.keys(formatFeatureSetSettings(selectedSet))
    );
  };

  const selectFeatureSet = (e) => {
    setFeatureSetInformation(findFeatureSet(e.target.value));
  };

  const formatFeatureSetSettings = (featureSet = {}) => {
    let filtered = Object.entries(featureSet).filter(
      ([key, _]) => key !== 'id' && key !== 'name'
    );
    return Object.fromEntries(filtered);
  };
  const [selectedFeatureSet, setSelectedFeatureSet] = useState(
    currentFeatureSet
  );
  const [selectedFeatureSettings, setFeatureSettings] = useState(
    generateCompareableSettings(currentFeatureSet, {})
  );

  const getSettingDiffClassName = (value, comparison) => {
    if ((value && comparison) || (!value && !comparison)) return '';

    return value ? classNames.settingAdded : classNames.settingRemoved;
  };

  return (
    <Grid item sm={12} md={6}>
      <Typography className="heading" gutterBottom variant="h5">
        Replace Feature Sets
      </Typography>
      <Divider />

      <Box m={1}>
        <Grid container spacing={1}>
          <Grid item className="grid-item">
            <Select
              className={classNames.featureSetSelect}
              value={selectedFeatureSet.id}
              onChange={(e) => selectFeatureSet(e)}
            >
              {burnerFeatureSets.map((featureSet) => (
                <MenuItem key={featureSet.id} value={featureSet.id}>
                  {featureSet.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item className="grid-item">
            <h4>current feature set: {`${currentFeatureSet.name}` || 'N/A'}</h4>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Settings</TableCell>
                  <TableCell>Values</TableCell>
                  <TableCell>Current Values</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedFeatureSettings.map((setting, i) => (
                  <TableRow key={`feature-setting-${i}`}>
                    <TableCell scope="row">{setting}</TableCell>
                    <TableCell
                      scope="row"
                      className={getSettingDiffClassName(
                        selectedFeatureSet[setting],
                        currentFeatureSet[setting]
                      )}
                    >
                      {selectedFeatureSet[setting] ? 'On' : 'Off'}
                    </TableCell>
                    <TableCell scope="row">
                      {currentFeatureSet[setting] ? 'On' : 'Off'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>

      <Button
        className="button"
        color="primary"
        disabled={isFetching}
        onClick={() =>
          from(getTokenSilently()).subscribe({
            next: (token) =>
              handleSubmit({
                featureSet: selectedFeatureSet,
                burnerInfo,
                token,
              }),
            error: () => logout({ returnTo: window.location.origin }),
          })
        }
        size="medium"
        type="button"
        variant="outlined"
      >
        Replace Feature Set
      </Button>
    </Grid>
  );
}

ReplaceFeatureSets.propTypes = propTypes;
ReplaceFeatureSets.defaultProps = {
  burnerFeatureSets: [],
  currentFeatureSet: {
    id: '',
    name: '',
  },
};

export default ReplaceFeatureSets;

import React from 'react';
import PropTypes from 'prop-types';
import { from } from 'rxjs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import DeleteUserDialog from 'components/pages/view-user-info/account-controls/DeleteUserDialog';

const propTypes = {
  deleteUserDialogIsOpen: PropTypes.bool.isRequired,
  deleteUserRequest: PropTypes.func.isRequired,
  deletionConfirmationRequest: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
};

function DeleteUser({
  deleteUserDialogIsOpen,
  deleteUserRequest,
  deletionConfirmationRequest,
  handleOpen,
  isFetching,
  userInfo,
}) {
  const { getTokenSilently, logout } = useAuth0();
  const { id, phoneNumber } = userInfo;

  return (
    <Grid item sm={12} md={4} className="item">
      <Typography className="heading" gutterBottom variant="h5">
        Delete User
      </Typography>
      <Divider />
      <Typography className="text" variant="body2">
        <strong>Be CAREFUL! This deletes a user&apos;s account!</strong>
      </Typography>
      <Button
        className="button"
        disabled={isFetching || !phoneNumber}
        color="primary"
        onClick={() =>
          from(getTokenSilently()).subscribe({
            next: (token) =>
              deletionConfirmationRequest({
                phoneNumber,
                token,
                userId: id,
              }),
            error: () => logout({ returnTo: window.location.origin }),
          })
        }
        size="medium"
        variant="outlined"
      >
        Send Confirmation Text
      </Button>
      <Button
        className="button"
        color="error"
        size="medium"
        onClick={() => handleOpen(true)}
        variant="outlined"
      >
        DELETE USER
      </Button>
      <DeleteUserDialog
        handleOpen={handleOpen}
        handleSubmit={deleteUserRequest}
        isFetching={isFetching}
        isOpen={deleteUserDialogIsOpen}
        userInfo={userInfo}
      />
    </Grid>
  );
}

DeleteUser.propTypes = propTypes;

export default DeleteUser;

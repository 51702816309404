import React from 'react';
import PropTypes from 'prop-types';
import TelephonyExtrasInput from 'components/pages/telephony-extras/TelephonyExtrasInput';
import MessagingService from 'components/MessagingService';
import PhoneNumberDetails from 'components/pages/telephony-extras/PhoneNumberDetails';
import SendMessages from 'components/pages/telephony-extras/SendMessage';
import View from 'components/View';
import Unauthorized from 'components/Unauthorized';
import { useAuth0 } from 'utils/react-auth0-wrapper';
import { routeScope } from 'utils/general';

const propTypes = {
  data: PropTypes.shape({
    createdAt: PropTypes.string,
    externalId: PropTypes.string,
    features: PropTypes.string,
    id: PropTypes.string,
    providerName: PropTypes.string,
    providerSpecificDetails: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lookupTelephonyPhoneNumberRequest: PropTypes.func.isRequired,
  message: PropTypes.shape({
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    text: PropTypes.string,
  }).isRequired,
  sendTelephonyMessageRequest: PropTypes.func.isRequired,
};

function TelephonyExtras({
  data,
  handleCloseSnackbar,
  isFetching,
  lookupTelephonyPhoneNumberRequest,
  message,
  sendTelephonyMessageRequest,
}) {
  const { hasPermission } = useAuth0();

  return hasPermission(routeScope('telephonyExtras')) ? (
    <View>
      <TelephonyExtrasInput
        handleSubmit={lookupTelephonyPhoneNumberRequest}
        isFetching={isFetching}
      />
      <PhoneNumberDetails data={data} />
      <SendMessages
        data={data}
        handleSubmit={sendTelephonyMessageRequest}
        isFetching={isFetching}
      />
      <MessagingService closeSnackbar={handleCloseSnackbar} message={message} />
    </View>
  ) : (
    <Unauthorized closeSnackbar={handleCloseSnackbar} />
  );
}

TelephonyExtras.propTypes = propTypes;

export default TelephonyExtras;

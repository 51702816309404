import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import amplitude from 'assets/amplitude.png';
import braze from 'assets/braze.png';

const propTypes = {
  userInfo: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function Links({ userInfo }) {
  const { id } = userInfo;

  return (
    <Grid alignItems="center" container direction="row" justify="space-around">
      <Grid item>
        <a
          href={`https://dashboard-03.braze.com/users/user_search/user-search/5b748dccd5b68231c82fab65?query=${id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="braze logo" height="40px" src={braze} />
        </a>
      </Grid>
      <Grid item>
        <a
          href={`https://analytics.amplitude.com/org/2066/project/${process.env.REACT_APP_AMPLITUDE_APP_ID}/search/${id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="braze logo" height="70px" src={amplitude} />
        </a>
      </Grid>
      <Grid item></Grid>
    </Grid>
  );
}

Links.propTypes = propTypes;

export default Links;
